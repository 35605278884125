import { Checkbox, CheckboxProps, css } from '@mui/material'
import { styled } from '@mui/system'

// todo need fix type for theme
interface CustomColorProps {
  theme?: any
  terms?: boolean
}

const termsCss = ({ terms, theme }: CustomColorProps) =>
  terms &&
  css`
    color: ${theme.palette.colors.border.default};
    padding-top: 0;
    padding-bottom: 0;
  `

interface CustomCheckboxProps {
  terms?: boolean
}

type Props = CustomColorProps & CustomCheckboxProps & CheckboxProps

const StyledCheckBox = styled(Checkbox)<Props>`
  ${termsCss}
`

export default StyledCheckBox
