import React from 'react'

import { Box, Divider } from '@mui/material'
import CustomModal from 'components/modal/customModal'
import Text from 'components/ui/Text'
import { SubmitButton } from 'globalStyles'
import { NewsArticle } from 'graphql/news/types'
import { getDate } from 'utils/date'

interface Props {
  isOpen?: boolean
  news?: NewsArticle | null
  onClose?: () => void
}

function NewsModal({ isOpen, onClose, news }: Props) {
  return (
    <CustomModal
      handleClose={onClose}
      open={isOpen}
      sx={{ maxWidth: '570px', width: '90%', maxHeight: '90%' }}
    >
      <Box overflow="auto" textAlign="left" width={1}>
        <Text body1 secondary sx={{ mb: 1 }}>
          {getDate(news?.createdAt)}
        </Text>
        <Text body0 sx={{ mb: 1 }}>
          {news?.title}
        </Text>
        <Box>
          <Text body4 secondary>
            {news?.content}
          </Text>
        </Box>
      </Box>
      <Divider flexItem sx={{ my: 3 }} />
      <SubmitButton sx={{ mb: '10px', mt: 0 }} onClick={onClose}>
        Got it!
      </SubmitButton>
    </CustomModal>
  )
}

export default NewsModal
