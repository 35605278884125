import React from 'react'

import { TermsTableOfContent } from 'constants/Legal/terms'

import map from 'lodash/map'

import { Container, Content, StyledButton } from './styles'

type Props = {
  content?: TermsTableOfContent
  onChapterClick?: (chapterId: string) => void
}
function TableOfContents({ content, onChapterClick }: Props) {
  if (!content) return null

  return (
    <Container>
      <Content>
        {map(content, (chapter, id) => (
          <StyledButton
            key={id}
            pl={chapter.id.startsWith('quest_') ? 2 : 0}
            onClick={() => onChapterClick?.(chapter.id)}
          >
            {chapter.title}
          </StyledButton>
        ))}
      </Content>
    </Container>
  )
}

export default TableOfContents
