import React, { useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import CancelIcon from '@mui/icons-material/Cancel'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ScheduleIcon from '@mui/icons-material/Schedule'
import StarIcon from '@mui/icons-material/Star'
import { Box, Divider, IconButton, Tab, Tabs } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactComponent as Checklist } from 'assets/icons/checklist.svg'
import { PhaseProgress } from 'components/blocks/phaseProgress'
import { NewsList } from 'components/newsList'
import { PublishedQuestList } from 'components/publishedQuestsList'
import Text from 'components/ui/Text'
import {
  QUEST_ACTIVITIES_COUNT,
  QUEST_ACTIVITIES_POINTS,
} from 'constants/quest'
import { QUEST_DAILY_STATS } from 'graphql/quests/queries'
import { DateTime } from 'luxon'

import {
  BorderLinearProgress,
  Content,
  DailyStatsContainer,
  Header,
} from './styles'

import { useResponsiveLayout } from '../../../hooks'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

type Props = {
  onCloseCommunityFeed?: () => void
}

function CommunityFeed({ onCloseCommunityFeed }: Props) {
  const { isMobile } = useResponsiveLayout()
  const [tab, setTab] = useState(0)

  const { data, loading } = useQuery(QUEST_DAILY_STATS)

  const completedDailyQuestCount = useMemo(
    () => data?.questDailyStats?.completedQuests || 0,
    [data],
  )
  const publishedDailyQuestCount = useMemo(
    () => data?.questDailyStats?.publishedQuests || 0,
    [data],
  )
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const completedQuestPercentage = useMemo(() => {
    if (!completedDailyQuestCount || !publishedDailyQuestCount) {
      return 0
    }
    return Math.round(
      (completedDailyQuestCount / publishedDailyQuestCount) * 100,
    )
  }, [completedDailyQuestCount, publishedDailyQuestCount])

  const timeLeft = useMemo(
    () =>
      Math.floor(
        DateTime.now().endOf('day').diff(DateTime.now(), 'hours').hours,
      ),
    [],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={'100dvh'}
      overflow="hidden"
      pb={isMobile ? 6 : 0}
    >
      <Header>
        <Box
          alignItems="baseline"
          display="flex"
          justifyContent="space-between"
          pt={2.5}
          px={2.5}
        >
          <Box>
            <Text sx={{ pb: 0.75 }} title3>
              Community feed
            </Text>
            <Text body3 secondary>
              Read articles, complete quests and get reward points
            </Text>
          </Box>
          <IconButton
            aria-label="delete"
            size="small"
            sx={{ padding: 0 }}
            onClick={onCloseCommunityFeed}
          >
            <CancelIcon color="secondary" fontSize="inherit" />
          </IconButton>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            indicatorColor="primary"
            sx={{ textTransform: 'none' }}
            value={tab}
            onChange={handleChange}
          >
            <Tab
              label="Quests"
              sx={{ textTransform: 'none', fontSize: '14px' }}
              {...a11yProps(0)}
            />
            <Tab
              label="Announcements"
              sx={{ textTransform: 'none', fontSize: '14px' }}
              {...a11yProps(1)}
            />
            <Tab
              label="Phase progress"
              sx={{ textTransform: 'none', fontSize: '14px' }}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
      </Header>
      <Box display="flex" flexDirection="column" height="100%">
        {tab === 0 && (
          <>
            <DailyStatsContainer
              flexDirection={isMobile ? 'column' : 'row'}
              gap={2}
              px={2.5}
              py={2}
            >
              {loading ? (
                <CircularProgress size={20} sx={{ margin: '20px auto' }} />
              ) : (
                <>
                  <Box>
                    <Text body3>Daily quests progress</Text>
                    <Box display="flex" gap={1} mb={1} mt={1.5}>
                      <Checklist />
                      <Box alignItems="center" display="flex" gap={0.25}>
                        <Text sx={{ fontSize: '16px' }}>
                          {completedQuestPercentage}%
                        </Text>
                        <Text body3 secondary>
                          {`(${completedDailyQuestCount} / ${publishedDailyQuestCount})`}
                        </Text>
                      </Box>
                    </Box>
                    <BorderLinearProgress
                      value={completedQuestPercentage}
                      variant="determinate"
                    />
                  </Box>
                  {!isMobile && <Divider flexItem orientation="vertical" />}

                  <Box display="flex" flexDirection="column" gap={0.75}>
                    <Box
                      alignItems="center"
                      display="flex"
                      gap={1}
                      justifyContent="space-between"
                    >
                      <Box alignItems="center" display="flex" gap={1}>
                        <FavoriteIcon
                          color="error"
                          sx={{ width: '12px', height: '13px' }}
                        />
                        <Text body3>Activities verified:</Text>
                      </Box>

                      <Text body3 secondary>{`${
                        completedDailyQuestCount * QUEST_ACTIVITIES_COUNT
                      } / ${
                        publishedDailyQuestCount * QUEST_ACTIVITIES_COUNT
                      }`}</Text>
                    </Box>
                    <Box
                      alignItems="center"
                      display="flex"
                      gap={1}
                      justifyContent="space-between"
                    >
                      <Box alignItems="center" display="flex" gap={1}>
                        <StarIcon
                          color="warning"
                          sx={{ width: '12px', height: '13px' }}
                        />
                        <Text body3>Points earned:</Text>
                      </Box>

                      <Text body3 secondary>{`${
                        completedDailyQuestCount * QUEST_ACTIVITIES_POINTS
                      } / ${
                        publishedDailyQuestCount * QUEST_ACTIVITIES_POINTS
                      }`}</Text>
                    </Box>
                    <Box
                      alignItems="center"
                      display="flex"
                      gap={1}
                      justifyContent="space-between"
                    >
                      <Box alignItems="center" display="flex" gap={1}>
                        <ScheduleIcon
                          color="secondary"
                          sx={{ width: '12px', height: '13px' }}
                        />
                        <Text body3>Left:</Text>
                      </Box>

                      <Text body3 secondary>
                        {timeLeft} hrs
                      </Text>
                    </Box>
                  </Box>
                </>
              )}
            </DailyStatsContainer>
            <Content p={2.5} py={1.5}>
              <PublishedQuestList />
            </Content>
          </>
        )}

        {tab === 1 && (
          <Content px={2.5} py={1.5}>
            <NewsList />
          </Content>
        )}

        {tab === 2 && (
          <Content px={2.5} py={1.5}>
            <PhaseProgress />
          </Content>
        )}
      </Box>
    </Box>
  )
}

export default React.memo(CommunityFeed)
