import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Button, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { Alert } from 'components/alert'
import Text from 'components/ui/Text'
import { UPTIME_THRESHOLD } from 'constants/params'
import { NodeStatus } from 'graphql/nodes/types'
import { useResponsiveLayout } from 'hooks'
import { DateTime } from 'luxon'
import { Routes } from 'router/routes'
import { getRemainingTime } from 'utils/date'

type Props = {
  earlyUnstaked?: boolean
  unstakesAt?: string
  totalUptime?: number
  status?: NodeStatus
}

function NodePenaltiesBanner({
  earlyUnstaked,
  unstakesAt,
  totalUptime = 0,
  status,
}: Props) {
  const materialTheme = useTheme()
  const navigate = useNavigate()
  const { isTablet, isMobile } = useResponsiveLayout()

  const reason = useMemo(() => {
    if (totalUptime >= UPTIME_THRESHOLD && earlyUnstaked) {
      return (
        <Box>
          <Text as="span" black body2 sx={{ fontWeight: '600' }}>
            You got a penalty and your rewards have been reduced:{' '}
          </Text>
          <Text as="span" black body2>
            Node was unstaked before the end of terms
          </Text>
        </Box>
      )
    }
    if (totalUptime < UPTIME_THRESHOLD && status === NodeStatus.ENABLED) {
      return (
        <Box>
          <Text as="span" black body2 sx={{ fontWeight: '600' }}>
            Your node is not selected for validation while availability is below{' '}
            {UPTIME_THRESHOLD}%.
          </Text>
        </Box>
      )
    }
    if (totalUptime < UPTIME_THRESHOLD && status !== NodeStatus.ENABLED) {
      return (
        <Box>
          <Text as="span" black body2 sx={{ fontWeight: '600' }}>
            You got a penalty and receive no rewards for your node:{' '}
          </Text>
          <Text as="span" black body2>
            Node Uptime Below {UPTIME_THRESHOLD}%.
          </Text>
        </Box>
      )
    }
    return ''
  }, [totalUptime, earlyUnstaked, status])

  const handleNavigateOnRules = useCallback(
    () => navigate(Routes.TERMS_PAGE),
    [navigate],
  )
  return (
    <Alert
      color="warning"
      icon={false}
      severity="warning"
      sx={{
        background: '#FFB406',
        px: 2,
        borderRadius: '8px',
        alignItems: 'center',
        display: 'flex',
        '.MuiAlert-message': {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isTablet || isMobile ? 'column' : 'row',
          alignItems: isTablet || isMobile ? 'start' : 'center',
          gap: 2,
        },
      }}
    >
      <Box
        alignItems={isTablet || isMobile ? 'start' : 'center'}
        display="flex"
        flexDirection={isTablet || isMobile ? 'column' : 'row'}
        gap={2}
      >
        <WarningAmberIcon sx={{ fontSize: '20px' }} />
        <Box>
          {reason}
          {earlyUnstaked &&
            unstakesAt &&
            DateTime.fromISO(unstakesAt) > DateTime.now() && (
              <Box>
                <Text as="span" black body3 sx={{ fontWeight: '600' }}>
                  Cooling-Off Period:{' '}
                </Text>

                <Text as="span" black body3>
                  Funds will be returned to your wallet{' '}
                  {getRemainingTime(unstakesAt)}
                </Text>
              </Box>
            )}
        </Box>
      </Box>
      <Button
        size="small"
        sx={{
          textTransform: 'none',
          fontWeight: 700,
          fontSize: '16px',
          padding: 0,
          // todo remove after updating types for theme
          // @ts-ignore
          color: materialTheme?.palette?.colors.font.black,
        }}
        variant="text"
        onClick={handleNavigateOnRules}
      >
        Terms <ChevronRightIcon />
      </Button>
    </Alert>
  )
}

export default NodePenaltiesBanner
