import React, { useContext } from 'react'

import { Box } from '@mui/material'
import MorpheusFormIconDM from 'assets/icons/morpheus_form_icon_dark.svg'
import MorpheusFormIconLM from 'assets/icons/morpheus_form_icon_light.svg'
import Text from 'components/ui/Text'
import { useAppContext } from 'context/AppContext'
import { ThemeContext } from 'context/themeMode'
import { UserBalanceContext } from 'context/userBalanceContext'
import { ethers } from 'ethers'
import { truncateAddress } from 'utils/truncateAddress'

function BalanceInfo() {
  const { me } = useAppContext()
  const { balance } = useContext(UserBalanceContext)
  const { mode } = useContext(ThemeContext)

  const isDarkMode = mode === 'dark'
  const MorpheusFormIcon = isDarkMode ? MorpheusFormIconDM : MorpheusFormIconLM

  if (!me) return null

  return (
    <Box>
      <Box display="flex" gap={1}>
        <img alt="MNW" src={MorpheusFormIcon} width={20} />

        <Text body2> MNW: {ethers.utils.formatEther(balance)}</Text>
      </Box>

      <Text body3 secondary>
        {truncateAddress(me.wallet as string)}
      </Text>
    </Box>
  )
}

export default BalanceInfo
