import React, { useCallback, useState } from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'
import TerminalIcon from '@mui/icons-material/Terminal'
import { Box, IconButton } from '@mui/material'

import { BlockCode, BlockHeader, BlockWrapper } from './styles'

interface Props {
  code: string
}

function CodeBlock({ code }: Props) {
  const [copied, setCopied] = useState(false)

  const handleCopy = useCallback((code: string) => {
    navigator.clipboard.writeText(code)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }, [])

  return (
    <BlockWrapper>
      <BlockHeader sx={{ displayPrint: 'none' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TerminalIcon sx={{ mr: 1 }} />
          Terminal
        </Box>

        <IconButton size="small" onClick={() => handleCopy(code)}>
          {copied ? (
            <DoneIcon fontSize="small" />
          ) : (
            <ContentCopyIcon fontSize="small" />
          )}
        </IconButton>
      </BlockHeader>
      <BlockCode component="pre">
        <Box component="code">$ {code}</Box>
      </BlockCode>
    </BlockWrapper>
  )
}

export default CodeBlock
