import React, { useCallback, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'

import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ReactComponent as ArrowDownIconDark } from 'assets/icons/arrow_down_icon_dark.svg'
import { ReactComponent as ArrowDownIconLight } from 'assets/icons/arrow_down_icon_light.svg'
import { ReactComponent as HeaderLogoDark } from 'assets/icons/header_logo_dark.svg'
import { ReactComponent as HeaderLogoLight } from 'assets/icons/header_logo_light.svg'
import TermsLayout, {
  TERMS_TABLE_OF_CONTENT_BY_ID,
} from 'constants/Legal/terms'
import { ThemeContext } from 'context/themeMode'
import { createBrowserHistory } from 'history'
import { useResponsiveLayout } from 'hooks'
import TableOfContents from 'pages/termsPage/TableOfContents'
import { Routes } from 'router/routes'

import { Container, Content, StyledButton } from './styles'

function TermsPage() {
  const mode = useContext(ThemeContext)
  const history = createBrowserHistory()
  const { isMobile } = useResponsiveLayout()

  const isDarkMode = mode.mode === 'dark'
  const Icon = isDarkMode ? ArrowDownIconDark : ArrowDownIconLight
  const HeaderLogo = isDarkMode ? HeaderLogoDark : HeaderLogoLight

  const handleBackClick = useCallback(() => {
    history.back()
  }, [history])

  const handleChapterClick = useCallback((chapterId: string) => {
    scroller.scrollTo(chapterId, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    })
  }, [])

  const showBackButton = useMemo(() => window.history.length > 1, [])

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        gap="48px"
        maxWidth="1080px"
        sx={{ padding: '20px 32px' }}
      >
        <Typography component="div" sx={{ flexGrow: 1 }}>
          <Link to={Routes.ROOT}>
            <HeaderLogo />
          </Link>
        </Typography>

        {showBackButton && (
          <StyledButton as={'button'} onClick={handleBackClick}>
            <Icon />
            Back
          </StyledButton>
        )}

        <Content>
          <TermsLayout />
          {!isMobile && (
            <TableOfContents
              content={TERMS_TABLE_OF_CONTENT_BY_ID}
              onChapterClick={handleChapterClick}
            />
          )}
        </Content>

        {showBackButton && (
          <StyledButton as={'button'} onClick={handleBackClick}>
            <Icon />
            Back
          </StyledButton>
        )}
      </Box>
    </Container>
  )
}

export default TermsPage
