import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 440px;

  z-index: 9998;
`

export const Overflow = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.colors.bg.overflow}1a;
  z-index: 0;
  backdrop-filter: blur(10px);
  transition: opacity 250ms;
`
