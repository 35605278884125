import { createContext, useContext } from 'react'

import { REWARDS_PERCENTAGE, TOKENS_PER_NODE } from 'constants/params'
import { Phase } from 'types/phase'

export const CurrentPhaseContext = createContext({
  currentPhase: {
    yieldPercentage: REWARDS_PERCENTAGE,
    tokenPerNode: TOKENS_PER_NODE,
  },
} as { currentPhase: Phase })
export function useCurrentPhaseContext() {
  return useContext(CurrentPhaseContext)
}
