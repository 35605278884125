import buttons from './buttons'

import palette from '../palette'

const theme = () => ({
  transitionTime: {
    long: '400ms',
    default: '250ms',
    modal: '150ms',
  },

  colors: {
    bg: {
      default: palette.white,
      secondary: palette.whiteSecondary,
      header: palette.whiteHeader,
      blue: palette.bgBlue,
      template: palette.templateBg,
      dropdown: palette.white,
      positive: palette.lightPositive,
      warning: palette.lightWarning,
      error: palette.lightError,
      overflow: palette.darkElectricBlue,
    },

    font: {
      default: palette.black,
      primary: palette.primary,
      secondary: palette.lightBlue,
      positive: palette.positive,
      error: palette.error,
      warning: palette.warning,
      template: palette.fontLightTemplate,
      muted: palette.muted,
      black: palette.black,
    },
    border: {
      default: palette.blue,
      bright: palette.borderLight,
    },
  },
  buttons: buttons(),
})

export default theme
