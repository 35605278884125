import React, { useContext, useEffect } from 'react'

import { useAppContext } from 'context/AppContext'
import { UserBalanceContext } from 'context/userBalanceContext'
import useWeb3 from 'hooks/useWeb3'
import { getTokenBalance } from 'utils/contractConfig'

interface Props {
  children: React.ReactNode
}

function UserBalance({ children }: Props) {
  const { me } = useAppContext()

  const { library, active } = useWeb3()
  const { balance, setBalance, updating, setUpdating } =
    useContext(UserBalanceContext)

  useEffect(() => {
    const getBalance = async () => {
      if (me?.wallet && active) {
        try {
          const bigNumber = await getTokenBalance(library, me.wallet)

          setBalance(bigNumber)
        } catch (e) {
          if (e instanceof Error) {
            // eslint-disable-next-line no-console
            console.log(e)
          }
        } finally {
          setUpdating(false)
        }
      }
    }

    const interval = setInterval(() => {
      getBalance()
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [me?.wallet, library, updating, setBalance, setUpdating, balance, active])

  return <>{children}</>
}

export default UserBalance
