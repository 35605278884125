import { Button } from '@mui/material'
import { styled } from '@mui/system'

export const WalletButton = styled(Button)(({ theme }) => ({
  padding: '0 15px',
  width: '370px',
  height: '56px',
  fontWeight: 'bold',
  lineHeight: '24px',
  fontSize: '16px',
  textTransform: 'none',
  backgroundColor: '#0D0D26',
  display: 'flex',
  justifyContent: 'start',
  background: theme.palette.mode === 'light' ? '#F7F9FF' : '#0D0D26',
  color: theme.palette.mode === 'light' ? '#000021' : '#fff',
}))
