import { Link } from 'react-router-dom'

import { styled } from '@mui/system'

export const RootLink = styled(Link)`
  color: ${({ theme }) => theme.palette.colors.font.default};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3em;
`
