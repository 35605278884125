import React, { useContext, useMemo } from 'react'

import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactComponent as NotHaveNodesDM } from 'assets/icons/dont_have_nodes_dark.svg'
import { ReactComponent as NotHaveNodesLM } from 'assets/icons/dont_have_nodes_light.svg'
import InstructionsLink from 'components/InstructionsLink'
import Text from 'components/ui/Text'
import { TOKENS_PER_NODE } from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { ThemeContext } from 'context/themeMode'
import { UserBalanceContext } from 'context/userBalanceContext'
import { DateTime } from 'luxon'
import { convertTokensToEther } from 'utils/numbers'

import { SubmitButton } from './styles'

type Props = {
  onClickHandler: () => void
}

function NodeIntroduce({ onClickHandler }: Props) {
  const { me } = useAppContext()
  const mode = useContext(ThemeContext)
  const { balance, updating } = useContext(UserBalanceContext)

  const isCoolingPeriod = useMemo(() => {
    if (!me?.freezingPeriodEndAt) return false
    const currentTime = DateTime.now()
    const userDate = DateTime.fromISO(me.freezingPeriodEndAt)
    return currentTime < userDate
  }, [me])

  const coolingPeriodRelativeTime = useMemo(() => {
    if (!me?.freezingPeriodEndAt) return null
    const now = DateTime.now()
    return DateTime.fromISO(me.freezingPeriodEndAt).toRelative({
      base: now,
      locale: 'en',
    })
  }, [me])

  const isDarkMode = mode.mode === 'dark'
  const NotHaveNodes = isDarkMode ? NotHaveNodesDM : NotHaveNodesLM

  const minWaitListTokens = useMemo(
    () => convertTokensToEther(TOKENS_PER_NODE),
    [],
  )

  if (me && me.stakedNode) return null

  if (updating) return <CircularProgress />

  if (isCoolingPeriod)
    return (
      <Box alignItems="center" display="flex" flexDirection="column">
        <NotHaveNodes />
        <Text sx={{ mt: 4 }} title2>
          Node Setup Unavailable
        </Text>
        <Text body2 secondary sx={{ maxWidth: '500px' }} textAlign="center">
          You cannot set up a node right now because you are in a Cooling-Off
          period that ends {coolingPeriodRelativeTime}. Please try again after
          this period ends.
        </Text>
      </Box>
    )

  if (balance.lt(minWaitListTokens))
    return (
      <Box alignItems="center" display="flex" flexDirection="column">
        <NotHaveNodes />
        <Text sx={{ mt: 4 }} title2>
          You don&apos;t have enough MNWs
        </Text>
        <Text body2 secondary sx={{ maxWidth: '500px' }} textAlign="center">
          Make sure to keep at least {TOKENS_PER_NODE} MNW on the Polygon
        </Text>
      </Box>
    )

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <NotHaveNodes />
      <Text sx={{ mt: 4 }} title2>
        You don&apos;t have any nodes
      </Text>
      <Text body2 secondary sx={{ maxWidth: '500px' }} textAlign="center">
        To continue, you need to set the active node
      </Text>
      <SubmitButton onClick={onClickHandler}>Setup Active Node</SubmitButton>

      <InstructionsLink />
    </Box>
  )
}

export default NodeIntroduce
