import React, { Dispatch, SetStateAction, useContext } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Drawer, IconButton } from '@mui/material'
import QuestActivityForm from 'components/blocks/questAcivityForm'
import { Mode, ThemeContext } from 'context/themeMode'
import { Quest } from 'graphql/quests/types'
import { useBannerPlaceholder } from 'hooks'

import { Container, Overflow } from './styles'

type QuestModalState = {
  isOpen: boolean
  entity: Quest | null
}

interface Props {
  isOpen?: boolean
  quest?: Quest | null
  onClose?: () => void
  onUpdateQuest?: Dispatch<SetStateAction<QuestModalState>>
  onUpdatePublishedQuestList?: Dispatch<SetStateAction<Quest[]>>
}
function QuestRightPanel({
  isOpen,
  quest,
  onClose,
  onUpdateQuest,
  onUpdatePublishedQuestList,
}: Props) {
  const bannerPlaceholder = useBannerPlaceholder()
  const theme = useContext(ThemeContext)

  if (!quest) return null
  return (
    <>
      <Overflow
        sx={{
          opacity: isOpen ? 1 : 0,
          pointerEvents: isOpen ? 'auto' : 'none',
        }}
        onClick={onClose}
      />

      <Drawer
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '440px',
            background: theme.mode === Mode.DARK ? '#000021' : '#ffffff',
          },
        }}
        anchor="right"
        open={isOpen}
        sx={{
          position: 'absolute',
          flexShrink: 0,
        }}
        variant="persistent"
      >
        <Container>
          {bannerPlaceholder}
          <Box
            sx={{
              position: 'relative',
              flexShrink: 0,
              width: '100%',
            }}
          >
            <Box
              display="flex"
              sx={{
                position: 'absolute',
                justifyContent: 'flex-end',
                top: 0,
                zIndex: 1,
              }}
              width="100%"
            >
              <IconButton
                sx={{ '&:hover': { background: 'none' }, padding: '20px' }}
                onClick={onClose}
              >
                <CloseIcon color="secondary" />
              </IconButton>
            </Box>

            <QuestActivityForm
              quest={quest}
              onUpdatePublishedQuestList={onUpdatePublishedQuestList}
              onUpdateQuest={onUpdateQuest}
            />
          </Box>
        </Container>
      </Drawer>
    </>
  )
}

export default QuestRightPanel
