import { ethers } from 'ethers'
import { DateTime } from 'luxon'
import numeral from 'numeral'

export function formatNumber(
  value: number | string = 0,
  format: string = '0,0',
): string {
  return numeral(value).format(format)
}

export function nodeToMnwConvert(value: number | string, mnw: number): number {
  const node: number = Number(value)
  return Number((mnw * node).toFixed(3))
}

export function convertTokensToEther(tokensPerNode: number) {
  return ethers.utils.parseEther(
    tokensPerNode ? tokensPerNode?.toString() : '0',
  )
}

interface UptimeParams {
  createdAt?: string
  closesAt?: string
  totalUptime?: number
}

export function calculateMaxUptime(params: UptimeParams): number {
  const { createdAt, closesAt, totalUptime } = params

  if (!createdAt || !closesAt) return 0

  const currentTime = DateTime.now()

  const createdAtDate = DateTime.fromISO(createdAt)
  const closesAtDate = DateTime.fromISO(closesAt)

  const elapsedTime = currentTime.diff(createdAtDate).as('milliseconds')
  const totalDuration = closesAtDate.diff(createdAtDate).as('milliseconds')
  const remainingTime = closesAtDate.diff(currentTime).as('milliseconds')

  return (
    ((elapsedTime * ((totalUptime || 0) / 100) + remainingTime) /
      totalDuration) *
    100
  )
}
