import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const ChartContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.colors.bg.header};
  border-radius: 8px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  div.google-visualization-tooltip {
    border: none;
    background-color: transparent;
    box-shadow: none;
    pointer-events: none;
  }
`
