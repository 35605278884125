export enum Routes {
  ROOT = '/',
  SIGN_UP = '/sign-up',
  SIGN_IN = '/sign-in',
  LOGIN_CODE = '/login-code',
  CONFIRM_LOGIN = '/confirm-login',
  CONFIRM_TERMS = '/confirm-terms',
  INSTRUCTION_PAGE = '/instruction-page',
  ALL_NODES_PAGE = '/all-nodes',
  DETAIL_NODE = '/detail-node/:address',
  REGISTER_NODE_PAGE = '/register-node-page',
  SETTINGS = '/settings',
  LEADERBOARD = '/leaderboard',
  TERMS_PAGE = '/terms',
  MONTHLY_REPORT = '/monthly_report',
  TWITTER_CALLBACK = '/twitter/callback',
  TWITTER_AUTH_LINK = `https://x.com/i/oauth2/authorize`,
}
