import { gql } from '@apollo/client'

import { NODE_FIELDS } from './fragments/node'

export const MY_NODES = gql`
  query MyNodes(
    $filter: NodesFilterInput
    $search: String
    $page: Int
    $take: Int
  ) {
    myNodes(filter: $filter, search: $search, page: $page, take: $take) {
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
      data {
        ...NodeFields
      }
    }
  }
  ${NODE_FIELDS}
`

export const NODE_BY_ADDRESS = gql`
  query NodeByAddress($address: String!) {
    nodeByAddress(address: $address) {
      ...NodeFields
    }
  }
  ${NODE_FIELDS}
`

export const GET_TOKENS_FROM_NODE_SYSTEM = gql`
  query GetTokensFromNodeSystem {
    getTokensFromNodeSystem {
      allTrustMnwTokens
      validatorNodesTokens
    }
  }
`

export const NODE_UPTIME_DATA = gql`
  query NodeUptime($nodeId: String!) {
    nodeUptime(nodeId: $nodeId) {
      items {
        key
        value
      }
      total
      totalTime
    }
  }
`

export const NODE_GLOBAL_STATS = gql`
  query NodeGlobalStats {
    nodeGlobalStats {
      allTimeNodes
      currentStakeLimit
      totalMnwStaked
      totalRewardsPaid
    }
  }
`

export const EARLY_UNSTAKE_PENALTY = gql`
  query EarlyUnstakePenalty($nodeId: String!) {
    earlyUnstakePenalty(nodeId: $nodeId) {
      reward
      penalty
    }
  }
`
