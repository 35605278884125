import { useCallback, useMemo } from 'react'

import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { WEB3 } from 'config'
import { connectors, Provider } from 'constants/connector'
import { setProvider } from 'services/provider'
import { getChainDetails } from 'utils/chains'
import Web3 from 'web3'

export default function useWeb3() {
  const { active, account, activate, deactivate, error, chainId, library } =
    useWeb3React()

  const web3 = useMemo(() => new Web3(library?.provider), [library])

  const connect = useCallback(
    (provider: Provider) => {
      if (active) return

      const connector = connectors[provider]

      if (!connector) return

      setProvider(provider)

      // eslint-disable-next-line consistent-return
      return activate(connector)
    },
    [active, activate],
  )

  const switchChain = useCallback(async () => {
    if (!window.ethereum) return

    if (chainId === WEB3.CHAIN_ID) return

    const chain = web3?.utils.toHex(WEB3.CHAIN_ID)

    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chain }],
      })
    } catch (err: any) {
      if (err.code === 4902) {
        const chainDetails = getChainDetails(WEB3.CHAIN_ID)

        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: chainDetails?.name,
              chainId: chain,
              nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
              rpcUrls: [chainDetails?.rpc],
              blockExplorerUrls: [chainDetails?.explorer],
            },
          ],
        })
      }
    }
  }, [chainId, web3])

  const signMessage = useCallback(
    async (nonce: string) => {
      if (!account) return

      // eslint-disable-next-line consistent-return
      return web3.eth.personal.sign(
        web3.utils.fromUtf8(`Login to TrustMNW with nonce: ${nonce}`),
        account,
        '',
      )
    },
    [web3, account],
  )

  const isUnsupportedChain = error instanceof UnsupportedChainIdError

  return {
    active,
    account,
    connect,
    deactivate,
    switchChain,
    signMessage,
    isUnsupportedChain,
    library,
  }
}
