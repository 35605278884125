import { gql } from '@apollo/client'

export const APP_VERSIONS = gql`
  query AppVersions {
    appVersions {
      cliVersion
      cliVersionUpdatedAt
    }
  }
`
