import { nanoid } from 'nanoid'

type Event = string
type Callback = (...args: any[]) => any

interface Subscription {
  callback: Callback
}

interface Token {
  clear: () => void
}

const actions = {
  modals: {
    showHomeScreenGuide: 'modals/showHomeScreenGuide',
  },
}

function EventBus() {
  const subscriptions = new Map<Event, Map<string, Subscription>>()

  return {
    on(event: Event, callback: Callback) {
      if (!subscriptions.has(event)) {
        subscriptions.set(event, new Map<string, Subscription>())
      }

      const subscriptionId = nanoid()

      subscriptions.get(event)?.set(subscriptionId, {
        callback,
      })

      return {
        clear: () => {
          const eventSubscriptions = subscriptions.get(event)
          eventSubscriptions?.delete(subscriptionId)
          if (eventSubscriptions?.size === 0) {
            subscriptions.delete(event)
          }
        },
      } as Token
    },
    trigger(event: Event, ...args: any[]) {
      subscriptions.get(event)?.forEach(subscription => {
        subscription.callback(...args)
      })
    },
    actions,
  }
}

export default EventBus()
