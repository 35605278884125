import { Mode } from 'context/themeMode'

export const CURR_THEME = 'theme'

export const getTheme = () => window.localStorage.getItem(CURR_THEME) as Mode

export const setTheme = (theme: Mode.DARK | Mode.LIGHT) =>
  window.localStorage.setItem(CURR_THEME, theme)

export const removeTheme = () => window.localStorage.removeItem(CURR_THEME)
