import React from 'react'

import { ReactComponent as TestQuerySuccessIcon } from 'assets/icons/test_query_success_icon.svg'
import Text from 'components/ui/Text'
import { SubmitButton } from 'globalStyles'

type Props = {
  onConfirm?: () => void
}

function TestQuerySuccess({ onConfirm }: Props) {
  return (
    <>
      <TestQuerySuccessIcon />
      <Text sx={{ maxWidth: '530px', textAlign: 'center', mt: 4 }} title2>
        Congratulations, the test request was successful
      </Text>
      <Text body2 secondary sx={{ textAlign: 'center' }}>
        Settings are almost complete, just one step to go
      </Text>
      <SubmitButton style={{ maxWidth: '320px' }} onClick={onConfirm}>
        Next
      </SubmitButton>
    </>
  )
}

export default TestQuerySuccess
