import React, { useCallback, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import detectEthereumProvider from '@metamask/detect-provider'
import { ReactComponent as MetaMaskIcon } from 'assets/icons/metamask_icon.svg'
import { AlertType } from 'components/alert/types'
import { Provider } from 'constants/connector'
import { METAMASK_WALLET_IN_APP_LINK } from 'constants/wallet'
import { useAppContext } from 'context/AppContext'
import { CONNECT_WALLET, GENERATE_NONCE } from 'graphql/validators/mutation'
import { useDevice, useWeb3 } from 'hooks'
import { getErrorMessage } from 'utils/error'

import { WalletButton as StyledButton } from './styles'

function WalletButton() {
  const { me, showAlert } = useAppContext()
  const { active, connect, signMessage, account } = useWeb3()

  const { isAndroid, isIOS, isWalletInAppBrowser } = useDevice()

  const [loading, setLoading] = useState(false)

  const [generateNonce] = useMutation(GENERATE_NONCE)
  const [connectWallet] = useMutation(CONNECT_WALLET)

  const shouldUseInAppBrowser = useMemo(
    () => (isAndroid || isIOS) && !isWalletInAppBrowser,
    [isAndroid, isIOS, isWalletInAppBrowser],
  )

  const handleUnlock = useCallback(
    async (provider: Provider) => {
      try {
        setLoading(true)

        if (shouldUseInAppBrowser) {
          const url = new URL(window.location.href)
          window.location.href = `${METAMASK_WALLET_IN_APP_LINK}${url}`
          return
        }
        const connectedProvider = await detectEthereumProvider()

        if (!connectedProvider?.isMetaMask) {
          showAlert?.({
            isOpen: true,
            title:
              'Please ensure you have the latest version of Metamask installed in your browser',
            color: AlertType.INFO,
          })
        } else {
          await connect(provider)
        }
      } catch (error) {
        showAlert?.({
          isOpen: true,
          title: getErrorMessage(error),
          color: AlertType.ERROR,
        })
      } finally {
        setLoading(false)
      }
    },
    [connect, shouldUseInAppBrowser, showAlert],
  )

  const handleConnect = useCallback(async () => {
    try {
      if (me?.wallet) {
        return
      }

      const { data } = await generateNonce()

      const nonce = data?.generateNonce

      if (!nonce) throw new Error('Cannot generate nonce')

      const message = await signMessage(nonce)

      await connectWallet({
        variables: { wallet: account, signature: message },
      })
    } catch (error) {
      showAlert?.({
        isOpen: true,
        title: getErrorMessage(error),
        color: AlertType.ERROR,
      })
    }
  }, [account, connectWallet, generateNonce, me, showAlert, signMessage])

  const handleWalletButtonClick = useCallback(async () => {
    if (active) {
      await handleConnect()
      return
    }

    await handleUnlock(Provider.Injected)
  }, [active, handleConnect, handleUnlock])

  return (
    <StyledButton
      disabled={loading}
      style={{ width: 'auto' }}
      onClick={handleWalletButtonClick}
    >
      <MetaMaskIcon style={{ marginRight: '10px' }} />{' '}
      {active ? 'Connect' : 'Unlock'} MetaMask
    </StyledButton>
  )
}

export default WalletButton
