import React, { ChangeEvent, useMemo } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Pagination, SelectChangeEvent, useTheme } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import StyledSelect from 'components/ui/StyledSelect'
import Text from 'components/ui/Text'
import { ROWS_PER_PAGE_DEFAULT, ROWS_PER_PAGE_OPTIONS } from 'constants/params'

import { PaginationWrapper } from './styles'

type Props = {
  count?: number
  currentPage?: number
  pageCount?: number
  rowsPerPage?: number
  onChangePage?: (event: ChangeEvent<unknown>, value: number) => void
  onRowsPerPageChange?: (event: SelectChangeEvent<unknown>) => void
}

function TablePagination({
  currentPage = 1,
  count = 0,
  pageCount,
  rowsPerPage = ROWS_PER_PAGE_DEFAULT,
  onChangePage,
  onRowsPerPageChange,
}: Props) {
  const materialTheme = useTheme()

  const startItem = useMemo(
    () => (currentPage - 1) * rowsPerPage + 1,
    [currentPage, rowsPerPage],
  )

  const endItem = useMemo(
    () => Math.min(currentPage * rowsPerPage, count),
    [count, currentPage, rowsPerPage],
  )

  return (
    <PaginationWrapper mt={2}>
      <Box display="flex">
        <Text body4>
          {startItem} - {endItem} of {count}
        </Text>
      </Box>

      <Pagination
        count={pageCount}
        page={currentPage}
        shape="rounded"
        siblingCount={0}
        onChange={onChangePage}
      />
      <Box alignItems="baseline" display="flex" gap={0.5}>
        <Text body4>Rows per page:</Text>
        <StyledSelect
          IconComponent={ExpandMoreIcon}
          MenuProps={{
            PaperProps: {
              sx: {
                // @ts-ignore
                bgcolor: materialTheme?.palette.colors.bg.dropdown,
                backgroundImage: 'none',
              },
            },
          }}
          disableUnderline
          pagination
          value={rowsPerPage}
          variant="standard"
          onChange={onRowsPerPageChange}
        >
          {ROWS_PER_PAGE_OPTIONS.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </StyledSelect>
      </Box>
    </PaginationWrapper>
  )
}

export default TablePagination
