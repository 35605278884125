import React from 'react'

import { Popup } from 'components/popup'
import { TOKENS_PER_NODE } from 'constants/params'
import { StakingPeriod, StakingTermsLabel } from 'graphql/stakingTerms/types'

import { UlNodePopup } from './styles'

type Props = {
  period?: StakingPeriod
  projectedRewards?: number
}

function NodeInfoPopup({ period, projectedRewards = 0 }: Props) {
  return (
    <Popup
      title={
        <UlNodePopup>
          <li>Each node requires the staking of {TOKENS_PER_NODE} MNW.</li>
          <li>There is 99% uptime required for the node.</li>
          <li>
            These validators will be rewarded during the holding period with{' '}
            {projectedRewards?.toFixed(2)} MNW for a period of{' '}
            {period && StakingTermsLabel[period]}.
          </li>
        </UlNodePopup>
      }
    />
  )
}

export default NodeInfoPopup
