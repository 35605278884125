import { Link } from 'react-router-dom'

import { Typography } from '@mui/material'
import { styled } from '@mui/system'

export const RootLink = styled(Link)`
  color: ${({ theme }) => theme.palette.colors.font.default};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3em;
`

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.colors.font.default};
  font-size: 16px;
  font-weight: 500;
  padding: 12px 0;
`
