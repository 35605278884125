import React, { useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Snackbar } from '@mui/material'
import { ReactComponent as SeeAllLinkIconDM } from 'assets/icons/see_more_link_icon_dark.svg'
import { ReactComponent as SeeAllLinkIconLM } from 'assets/icons/see_more_link_icon_light.svg'
import { Alert } from 'components/alert'
import { ThemeContext } from 'context/themeMode'
import { CardSubtitle, CardTitle, NodeCard } from 'globalStyles'
import { MY_NODES } from 'graphql/nodes/queries'
import { Node } from 'graphql/nodes/types'
import { Routes } from 'router/routes'
import { getDate } from 'utils/date'
import { getNodesStatus } from 'utils/getNodesStatus'
import { truncateAddress } from 'utils/truncateAddress'

import {
  AddressContent,
  HeaderButtonsWrapper,
  HeaderSeeAllLink,
  HeaderTitle,
  NodeListWrapper,
} from './styles'

const params = {
  page: 1,
  take: 3,
  order: 'DESC',
}
function NodesList() {
  const mode = useContext(ThemeContext)
  const [textAlert, setTextAlert] = useState<string>('')
  const [isOpenAlert, setIsOpenAlert] = useState<boolean>(false)

  const isDarkMode = mode.mode === 'dark'
  const SeeAllLinkIcon = isDarkMode ? SeeAllLinkIconDM : SeeAllLinkIconLM

  const { data } = useQuery(MY_NODES, {
    variables: params,
  })

  const nodes = useMemo(() => data?.myNodes?.data || [], [data])

  const onCloseAlertHandler = () => setIsOpenAlert(false)

  const onCopyAddressHandler = (address: string) => {
    navigator.clipboard.writeText(address)
    setTextAlert('The address was copied.')
    setIsOpenAlert(true)
  }

  if (!nodes?.length) return null

  return (
    <>
      <Box width="100%">
        <HeaderButtonsWrapper mb={3} width="100%">
          <HeaderTitle>Node List</HeaderTitle>
          <HeaderSeeAllLink to={Routes.ALL_NODES_PAGE}>
            See all <SeeAllLinkIcon />
          </HeaderSeeAllLink>
        </HeaderButtonsWrapper>
        <NodeListWrapper>
          {nodes.map(({ id, status, createdAt, address }: Node, i: number) => (
            <Link
              key={id}
              style={{
                textDecoration: 'none',
              }}
              to={`/detail-node/${address}`}
            >
              <NodeCard
                key={id}
                style={i >= 2 ? { opacity: '0.3' } : {}}
                sx={{ p: 2, minWidth: 240 }}
              >
                <CardSubtitle>ID</CardSubtitle>
                <CardTitle pb={2} pt={1}>
                  {id}
                </CardTitle>
                <CardSubtitle>Address</CardSubtitle>
                <CardTitle pb={2} pt={1}>
                  <AddressContent onClick={e => e.preventDefault()}>
                    {truncateAddress(address)}
                    <ContentCopyIcon
                      style={{
                        cursor: 'pointer',
                        color: mode.mode === 'dark' ? '#fff' : '#000',
                        margin: '0 10px 0 10px',
                      }}
                      onClick={() => onCopyAddressHandler(address)}
                    />
                  </AddressContent>
                </CardTitle>
                <CardSubtitle>Timestamp</CardSubtitle>
                <CardTitle pb={2} pt={1}>
                  {getDate(createdAt)}
                </CardTitle>
                <CardSubtitle>Status</CardSubtitle>
                <CardTitle
                  pb={0}
                  style={{
                    color: getNodesStatus(status),
                  }}
                >
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </CardTitle>
              </NodeCard>
            </Link>
          ))}
        </NodeListWrapper>
      </Box>
      {textAlert.length ? (
        <Snackbar open={isOpenAlert}>
          <Alert
            severity="info"
            sx={{ width: '100%' }}
            onClose={onCloseAlertHandler}
          >
            {textAlert}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}
    </>
  )
}

export default NodesList
