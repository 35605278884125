import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { Box } from '@mui/material'
import MorpheusFormIconDM from 'assets/icons/morpheus_form_icon_dark.svg'
import MorpheusFormIconLM from 'assets/icons/morpheus_form_icon_light.svg'
import { ReactComponent as NodesFormIconDM } from 'assets/icons/nodes_form_icon_dark.svg'
import { ReactComponent as NodesFormIconLM } from 'assets/icons/nodes_form_icon_light.svg'
import { AlertType } from 'components/alert/types'
import { LoadingButton } from 'components/loadingButton'
import ArrowDown from 'components/ui/ArrowDown'
import StyledInput from 'components/ui/StyledInput'
import Text from 'components/ui/Text'
import { Provider } from 'constants/connector'
import { TOKENS_PER_NODE } from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { Mode, ThemeContext } from 'context/themeMode'
import { UserBalanceContext } from 'context/userBalanceContext'
import { useFormik } from 'formik'
import { UP_STAKE } from 'graphql/nodes/mutation'
import { MY_NODES, NODE_GLOBAL_STATS } from 'graphql/nodes/queries'
import useWeb3 from 'hooks/useWeb3'
import { getProvider } from 'services/provider'
import { sendApprove } from 'utils/contractConfig'
import { convertToBigNumber } from 'utils/convertToBigNumber'
import { disableLettersHandler } from 'utils/disableLetters'
import { getErrorMessage } from 'utils/error'
import { convertTokensToEther, nodeToMnwConvert } from 'utils/numbers'
import * as yup from 'yup'

enum FIELDS {
  NODES = 'nodes',
  MNV = 'mnv',
}
interface InitialValues {
  [FIELDS.NODES]: number
  [FIELDS.MNV]: number | string
}

type Props = {
  stakedNodes: number
  onClose: () => void
}
const INITIAL_AMOUNT = 1

function NodesUpstakeForm({ stakedNodes, onClose }: Props) {
  const { mode } = useContext(ThemeContext)
  const { connect, library } = useWeb3()
  const { me, refetchMe, showAlert } = useAppContext()
  const { setUpdating, balance } = useContext(UserBalanceContext)
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [upstake] = useMutation(UP_STAKE)

  const isDarkMode = mode === Mode.DARK
  const NodesFormIcon = isDarkMode ? NodesFormIconDM : NodesFormIconLM
  const MorpheusFormIcon = isDarkMode ? MorpheusFormIconDM : MorpheusFormIconLM
  const initialValues: InitialValues = {
    [FIELDS.NODES]: INITIAL_AMOUNT,
    [FIELDS.MNV]: nodeToMnwConvert(INITIAL_AMOUNT, TOKENS_PER_NODE),
  }

  const minRequiredTokens = useMemo(
    () => convertTokensToEther(TOKENS_PER_NODE),
    [],
  )

  const nodesAvailableForUpstake = useMemo(
    () => Math.floor(balance.div(minRequiredTokens).toNumber()),
    [balance, minRequiredTokens],
  )

  const validationSchema = useMemo(() => {
    return yup.object({
      [FIELDS.NODES]: yup
        .number()
        .required('Please enter node count')
        .min(1, 'You have to stake at least one node')
        .max(
          nodesAvailableForUpstake,
          'Unable to upstake due to insufficient balance',
        ),
      [FIELDS.MNV]: yup
        .number()
        .min(TOKENS_PER_NODE, `Minimum ${TOKENS_PER_NODE} MNW per node holder`),
    })
  }, [nodesAvailableForUpstake])

  const onSubmitHandler = async (values: InitialValues) => {
    if (me?.wallet) {
      try {
        const upstakeNodes = values[FIELDS.NODES]
        const fullAmount = upstakeNodes + stakedNodes
        setIsLoading(true)

        showAlert?.({
          isOpen: true,
          title: 'Please wait.. this process can take up to 20 minutes.',
          color: AlertType.INFO,
        })

        await sendApprove(
          library,
          convertToBigNumber(nodeToMnwConvert(fullAmount, TOKENS_PER_NODE)),
        )
        const { data } = await upstake({
          variables: { nodes: upstakeNodes },
          refetchQueries: [
            { query: NODE_GLOBAL_STATS },
            { query: MY_NODES, variables: { page: 1, take: 5 } },
          ],
        })

        const address = data?.upstake?.address

        if (!address) {
          showAlert?.({
            isOpen: true,
            title: 'Oops, something went wrong...',
            color: AlertType.ERROR,
          })
        } else {
          setUpdating(true)
          await refetchMe?.()
          setIsLoading(false)
          navigate(`/detail-node/${address}`)
        }
      } catch (error) {
        showAlert?.({
          isOpen: true,
          title: getErrorMessage(error),
          color: AlertType.ERROR,
        })
      } finally {
        setIsLoading(false)
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
  })

  const { setFieldValue, values, handleSubmit, errors, isValid } = formik

  const handleNodesCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (!value) {
      setFieldValue(FIELDS.MNV, '')
      setFieldValue(FIELDS.NODES, '')
      return
    }

    setFieldValue(FIELDS.MNV, nodeToMnwConvert(value, TOKENS_PER_NODE))
    setFieldValue(FIELDS.NODES, parseInt(value, 10))
  }

  useEffect(() => {
    const connectToWallet = async () => {
      const provider = getProvider()
      if (!provider) return
      connect(provider as Provider)
    }

    connectToWallet()
  }, [])

  return (
    <Box overflow="auto" width="100%">
      <Text sx={{ mt: -1, mb: 3, mr: 4 }} title1>
        Make an upstake
      </Text>
      <form
        style={{ width: '100%', height: '100%', overflow: 'scroll' }}
        onSubmit={handleSubmit}
      >
        <Box display="flex" flexDirection="column" position="relative">
          <StyledInput
            endAdornment={<NodesFormIcon />}
            name={FIELDS.NODES}
            sx={{ mt: 0 }}
            value={values[FIELDS.NODES]}
            onChange={handleNodesCount}
            onKeyPress={disableLettersHandler}
          />
          <ArrowDown />
          <StyledInput
            disabled
            endAdornment={<img alt="morpheusIcon" src={MorpheusFormIcon} />}
            name={FIELDS.MNV}
            sx={{ mt: '16px' }}
            value={values[FIELDS.MNV]}
            onKeyPress={disableLettersHandler}
          />
        </Box>
        <Text
          body2
          error
          sx={{
            textAlign: 'left',
            mt: 1,
          }}
        >
          {errors[FIELDS.NODES]}
        </Text>
        <Text
          body2
          sx={{
            textAlign: 'left',
            my: 1,
          }}
        >
          In the next step, we will ask you to approve the full amount of the
          new stake
        </Text>

        <Text
          body2
          sx={{
            textAlign: 'left',
            my: 1,
            fontWeight: 600,
          }}
        >
          PLEASE, DO NOT CHANGE THE SPENDING CAP!
        </Text>
        <Box>
          <LoadingButton
            disabled={isLoading || !isValid}
            error
            loading={isLoading}
            style={{ marginTop: '20px' }}
            text={'Upstake'}
            type="submit"
          />

          <LoadingButton
            secondary
            style={{ marginTop: '10px' }}
            text={'Cancel'}
            onClick={onClose}
          />
        </Box>
      </form>
    </Box>
  )
}

export default NodesUpstakeForm
