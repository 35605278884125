import { gql } from '@apollo/client'

import { LEADERBOARD_FIELDS } from './fragments/leaderboard'

// todo create fragment for meta
export const LEADERBOARD = gql`
  query Leaderboard(
    $search: String
    $order: String
    $page: Int
    $take: Int
    $from: DateTime
    $to: DateTime
    $phaseId: String
  ) {
    leaderboard(
      search: $search
      order: $order
      page: $page
      take: $take
      from: $from
      to: $to
      phaseId: $phaseId
    ) {
      meta {
        hasNextPage
        hasPreviousPage
        itemCount
        page
        pageCount
        take
      }
      data {
        ...LeaderboardFields
      }
    }
  }
  ${LEADERBOARD_FIELDS}
`
