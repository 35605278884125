import { StakingPeriod } from '../stakingTerms/types'

export interface Providers {
  name: string
}

export type NodeEarning = {
  apy: number
  earning: number
  perDay: number
}

export interface NodeData {
  id: string
  stakedAmount: number
  totalUptime: number
  transactionHash: string
  providers: Providers[]
  status: NodeStatus
  active: boolean
  lastActivity?: string
  createdAt: string
  unstakesAt: string
  closesAt: string
  period: StakingPeriod
  earnedRewards: NodeEarning
  projectedRewards: NodeEarning
  workers: number
  reward?: number
  earlyUnstaked: boolean
  penalty: number
  validator: {
    id: string
    nodeAppId: string
  }
}

export interface Params {
  page: number
  take: number
  role: string
  search: string
  order: string
}

export interface Node {
  address: string
  createdAt: string
  id: string
  transactionHash: string
  status: NodeStatus
}

export enum NodeStatus {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
  TERMINATED = 'Terminated',
}

export interface NodeInfo {
  platform: string
  type: string
  version: string
}
