import React, { useEffect, useState } from 'react'

import { Content, ReadMoreButton } from './styles'

interface Props {
  content?: string
  maxChars?: number
}

function ExpandableText({ content, maxChars = 100 }: Props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [showReadMore, setShowReadMore] = useState(false)
  const [visibleContent, setVisibleContent] = useState<string | null>(null)

  useEffect(() => {
    if (!content) return
    if (content.length > maxChars) {
      setShowReadMore(true)
      setVisibleContent(`${content.slice(0, maxChars)}... `)
    } else {
      setShowReadMore(false)
      setVisibleContent(content)
    }
  }, [content, maxChars])

  const handleToggleClick = () => {
    setIsExpanded(!isExpanded)
  }

  if (!content) return null

  return (
    <Content isExpanded={isExpanded}>
      {isExpanded ? content : visibleContent}
      {showReadMore && (
        <>
          {'   '}
          <ReadMoreButton onClick={handleToggleClick}>
            {isExpanded ? 'Show less' : 'Read more'}
          </ReadMoreButton>
        </>
      )}
    </Content>
  )
}
export default ExpandableText
