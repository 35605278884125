import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { LoadingButton } from 'components/loadingButton'
import Text from 'components/ui/Text'
import { TOKENS_PER_NODE } from 'constants/params'
import { UserBalanceContext } from 'context/userBalanceContext'
import { ethers } from 'ethers'
import { convertTokensToEther } from 'utils/numbers'

import { ButtonWrapper } from './styles'

type Props = {
  onClose: () => void
  onStep: (step: number) => void
}

function NodesUpstakeTerms({ onStep, onClose }: Props) {
  const { balance } = useContext(UserBalanceContext)

  const [userAgreed, setUserAgreed] = useState<boolean>(false)

  const handleAgreementChange: MouseEventHandler<HTMLLabelElement> =
    useCallback(event => {
      // @ts-ignore
      setUserAgreed(event.target.checked)
    }, [])

  const minRequiredTokens = useMemo(
    () => convertTokensToEther(TOKENS_PER_NODE),
    [],
  )

  const canUpstake = useMemo(
    () => balance.gte(minRequiredTokens),
    [balance, minRequiredTokens],
  )

  return (
    <Box>
      <Text sx={{ mt: -1, mb: 3, mr: 4 }} title1>
        Upstake
      </Text>

      <Box maxHeight="250px" overflow="scroll">
        <Text body4 sx={{ mb: 2 }}>
          Please consider the rules below before proceeding with the procedure
          of increasing the stake
        </Text>
        <Text sx={{ mb: 2 }}>
          ✅️ &nbsp; During upstaking, the system temporarily closes your active
          nodes and sends you staked MNW tokens back. After that, the system
          requests approval for the transfer of tokens in the amount of the
          number of new node(s) and your staked node(s) before. Temporary
          closing does not affect your rewards and uptime. After successful
          approval, newly staked nodes start operations the next day.
        </Text>

        <Text sx={{ mb: 2 }}>
          🟡 &nbsp; ️You must hold at least {TOKENS_PER_NODE}
          &nbsp; MNWs in Polygon network before installing.
          {!canUpstake
            ? `You currently miss ${ethers.utils.formatEther(
                minRequiredTokens.sub(balance),
              )} MNWs to fit this minimum requirement.`
            : ''}
        </Text>

        <Text fontWeight={600} sx={{ mb: 2 }}>
          Notes:
        </Text>

        <Text sx={{ mb: 2 }}>
          ⚠️ &nbsp; If you change your mind and would wish to stake back the
          same amount — this will not be possible. Only bigger stake will be
          allowed.
        </Text>
        <FormControlLabel
          control={<Checkbox style={{ paddingTop: 0, marginRight: 0 }} />}
          label={
            <Text body4 sx={{ mb: 1 }}>
              I fully understand and accept the risks of upstaking process.
            </Text>
          }
          style={{ alignItems: 'start' }}
          onClick={handleAgreementChange}
        />
      </Box>

      <ButtonWrapper>
        {!canUpstake && (
          <Text error px="40px" textAlign="center">
            You don&apos;t have enough MNW tokens in Polygon network to continue
            the process
          </Text>
        )}

        <LoadingButton
          disabled={!userAgreed || !canUpstake}
          error
          style={{ marginTop: '20px' }}
          text={'Unstake & Continue'}
          onClick={() => onStep(1)}
        />
        <LoadingButton
          secondary
          style={{ marginTop: '10px' }}
          text={'Cancel'}
          onClick={onClose}
        />
      </ButtonWrapper>
    </Box>
  )
}

export default NodesUpstakeTerms
