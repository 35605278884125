import YouTubeIcon from '@mui/icons-material/YouTube'
import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Title = styled(Box)`
  color: ${({ theme }) => theme.palette.colors.font.default};
  font-size: 24px;
  font-weight: 700;
  text-align: left;
`

export const Subtitle = styled(Box)`
  color: ${({ theme }) =>
    theme.palette.mode === 'light'
      ? 'rgba(122, 137, 166, 1)'
      : 'rgba(105, 122, 151, 1)'};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin: 24px 0 0;
  text-align: left;
`

export const HighlightText = styled('span')`
  color: #253566;
`

export const YoutubeCustomizedIcon = styled(YouTubeIcon)`
  fill: ${({ theme }) => theme.palette.colors.font.error};
`
