import React from 'react'

import { Loader } from 'components/loader'
import { SubmitButton } from 'globalStyles'

interface Props {
  text: string
  type?: 'button' | 'submit' | 'reset' | undefined
  loading?: boolean
  onClick?: () => void
  disabled?: boolean
  style?: React.CSSProperties
  secondary?: boolean
  error?: boolean
  success?: boolean
  neutral?: boolean
}

const LoadingButton: React.FC<Props> = ({
  type,
  loading,
  text,
  onClick,
  disabled,
  ...rest
}: Props) => (
  <SubmitButton
    disabled={disabled}
    endIcon={loading ? <Loader style={{ marginLeft: '20px' }} /> : ''}
    type={type}
    onClick={onClick}
    {...rest}
  >
    {text}
  </SubmitButton>
)

export default LoadingButton
