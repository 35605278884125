import { gql } from '@apollo/client'

import { VALIDATOR } from './fragments'

export const ME = gql`
  query Me {
    me {
      ...Validator
    }
  }
  ${VALIDATOR}
`

export const VALIDATOR_CONTRACT = gql`
  query validatorContract($validatorAddress: String!) {
    validatorContract(validatorAddress: $validatorAddress) {
      address
    }
  }
`

export const GET_USER_APPROVE = gql`
  query IsApproved {
    isApproved
  }
`

export const appConnectedQuery = gql`
  query AppConnected {
    appConnected
  }
`

export const ENABLED_NODE = gql`
  query EnabledNode {
    enabledNode
  }
`

export const GET_VALIDATOR_REVIEW_STATUS = gql`
  query GetValidatorReviewStatus {
    getValidatorReviewStatus
  }
`

export const HAS_NODES = gql`
  query HasNodes {
    hasNodes
  }
`

export const VALIDATION_DAILY_STATS = gql`
  query validationDailyStats($nodeId: String!, $from: DateTime, $to: DateTime) {
    validationDailyStats(nodeId: $nodeId, from: $from, to: $to) {
      key
      value
    }
  }
`
export const VALIDATOR_VALIDATIONS_META = gql`
  query validatorValidationsMeta {
    validatorValidationsMeta {
      totalValidations
      allTotalValidations
      activeNodes
      allActiveNodes
    }
  }
`
