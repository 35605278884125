import React from 'react'

import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { androidScreenShoot, iOSScreenShoot } from 'assets/bannerPwa'
import Text from 'components/ui/Text'
import { useDevice, useStandalone } from 'hooks'

type Props = {
  onClose?: () => void
}

function InstructionsStep({ onClose }: Props) {
  const { isAndroid, isMobileSafari } = useDevice()
  const isStandalone = useStandalone()

  if (isStandalone || (!isAndroid && !isMobileSafari)) return null

  return (
    <>
      <Text body0 sx={{ mb: 1 }}>
        {`How to add the app to your home screen on ${
          isMobileSafari ? 'iOS' : 'Android'
        }`}
      </Text>
      <Text body4 secondary>
        {isMobileSafari
          ? 'In your Safari browser menu, tap the Share icon and choose Add to Home Screen in the options. Then open the app on your home screen'
          : "In your browser menu (three dots), select 'Add to Home Screen.' Then open the app on your home screen"}
      </Text>
      <Box borderRadius="10px" my={4} overflow="hidden" width="100%">
        {isMobileSafari ? (
          <img alt={'ios screenshot'} src={iOSScreenShoot} width="100%" />
        ) : (
          <img
            alt={'android screenshot'}
            src={androidScreenShoot}
            width="100%"
          />
        )}
      </Box>
      <Button
        sx={{
          textTransform: 'none',
          width: 'fit-content',
          mt: 0,
          p: 0,
        }}
        variant="text"
        onClick={onClose}
      >
        <Text primary sx={{ fontSize: '16px' }} title3>
          Back
        </Text>
      </Button>
    </>
  )
}

export default InstructionsStep
