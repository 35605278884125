import { Box } from '@mui/material'
import { styled } from '@mui/system'

const ContentBox = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
`

export default ContentBox
