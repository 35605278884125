import { styled } from '@mui/system'

// todo update after creating new ui kit
// we have no such colors in theme
export const Icon = styled('span')(() => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  outline: '1px solid #D4D9DD',
  backgroundColor: '#EEF0F1',
  'input:hover ~ &': {
    backgroundColor: '#EDF0F5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: '#7A89A6',
    outline: '1px solid #7A89A6',
  },
}))

export const CheckedIcon = styled(Icon)({
  backgroundColor: '#475D8B',
  outline: '1px solid #475D8B',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#EEF0F1,#EEF0F1 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#475D8B',
  },
})
