import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { ReactComponent as LinkGlyph } from 'assets/icons/link_icon.svg'

export const Wrapper = styled(Box)`
  display: grid;
  gap: 20px;
  min-height: 450px;
  width: 100%;
`

export const NodeInfoContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.colors.bg.header};
  border-radius: 8px;
  padding: 16px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const ChartContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.colors.bg.header};
  border-radius: 8px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const RewardBlock = styled(Box)`
  background-color: ${({ theme }) => theme.palette.colors.bg.header};
  border-radius: 8px;
  padding: 16px;
  text-align: left;
`

export const Amount = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`

export const CopyIcon = styled(ContentCopyIcon)`
  width: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.colors.font.secondary};
  margin: 0 12px 0 18px;
`

export const LinkIcon = styled(LinkGlyph)`
  width: 12px;
  height: 100%;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.palette.colors.font.secondary};
  }
`

export const ChartWrapper = styled(Box)`
  height: 93px;
  width: 100%;
  position: relative;
  div.google-visualization-tooltip {
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
`

export const GridTitle = styled(Typography)`
  line-height: 11px;
  padding: 0 1px;
  font-size: 10px;
  font-weight: 300;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.colors.bg.header};
`

export const GridLimitsContainer = styled(Box)`
  top: 9px;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-left: 16px;
  height: 93px;
  background-color: transparent;
  z-index: 3;
`

export const ChartsContainer = styled(Box)`
  display: grid;
  gap: 20px;
`
