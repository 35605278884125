interface UpdateValidatorData {
  wallet: string
}

export interface UpdateValidator {
  updateValidatorId: string
  updateValidatorData: UpdateValidatorData
}

export enum VerificationStatuses {
  COMPLETED = 'completed',
  INIT = 'init',
  PENDING = 'pending',
  ON_HOLD = 'onHold',
  QUEUED = 'queued',
}
