import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { LoadingButton } from 'components/loadingButton'
import { CustomModal } from 'components/modal'
import Text from 'components/ui/Text'
import { getRemainingTime } from 'constants/date'
import { UPTIME_THRESHOLD } from 'constants/params'
import { EARLY_UNSTAKE_PENALTY } from 'graphql/nodes/queries'
import { NodeData } from 'graphql/nodes/types'
import { calculateMaxUptime } from 'utils/numbers'

import { ButtonWrapper } from './styles'

import 'katex/dist/katex.min.css'

type Props = {
  isOpen?: boolean
  nodeData?: NodeData | null
  totalUptimePercentage: number
  onClose?: () => void
  onConfirm?: () => void
}

function UnstakeTermsModal({
  isOpen = false,
  onClose,
  onConfirm,
  nodeData,
  totalUptimePercentage = 0,
}: Props) {
  const [userAgreed, setUserAgreed] = useState<boolean>(false)

  const [loadData, { loading, data }] = useLazyQuery(EARLY_UNSTAKE_PENALTY)

  const fetchEarlyUnstakePenalty = useCallback(
    (nodeId: string) => loadData({ variables: { nodeId } }),
    [loadData],
  )

  useEffect(() => {
    if (nodeData?.id) {
      fetchEarlyUnstakePenalty(nodeData.id).then()
    }
  }, [fetchEarlyUnstakePenalty, nodeData?.id])

  const handleAgreementChange: MouseEventHandler<HTMLLabelElement> =
    useCallback(event => {
      // @ts-ignore
      setUserAgreed(event.target.checked)
    }, [])

  const reward = useMemo(() => data?.earlyUnstakePenalty?.reward || 0, [data])

  const penalty = useMemo(() => data?.earlyUnstakePenalty?.penalty || 0, [data])

  const maxUptime = useMemo(
    () =>
      calculateMaxUptime({
        createdAt: nodeData?.createdAt,
        closesAt: nodeData?.closesAt,
        totalUptime: totalUptimePercentage,
      }),
    [nodeData, totalUptimePercentage],
  )

  return (
    <CustomModal
      handleClose={onClose}
      open={isOpen}
      sx={{
        padding: '56px 48px 40px',
        maxWidth: '650px',
        width: '90%',
        maxHeight: '90%',
      }}
    >
      {loading ? (
        <Box
          alignItems="center"
          display="flex"
          height={1}
          justifyContent="center"
          mt="30px"
          width="100%"
        >
          <CircularProgress size={20} sx={{ margin: '127px auto' }} />
        </Box>
      ) : (
        <Box overflow="hidden">
          <Text sx={{ mt: -1, mb: 3, mr: 4 }} title1>
            Penalties for Early Unstaking
          </Text>
          <Box maxHeight="270px" mb={2} overflow="scroll">
            {totalUptimePercentage >= UPTIME_THRESHOLD ? (
              <>
                <Text body4 sx={{ mb: 3 }}>
                  You want to unstake your node before the end of your
                  commitment period. As a result, the following penalties will
                  apply:
                </Text>
                <Text body4 sx={{ mb: 3 }}>
                  <ul style={{ paddingLeft: '15px' }}>
                    <li>
                      <strong>Penalty:</strong> A flat penalty of 10% on the net
                      rewards amount for unstaking before the end of the
                      commitment period.
                    </li>
                    <li>
                      <strong>Reward Adjustment:</strong> Rewards will be
                      adjusted based on the last completed term period to
                      unstaking. Any partial term does not count towards reward
                      calculations.
                    </li>
                    <li>
                      <strong>Cooling-Off Period:</strong> In the case of early
                      unstaking, equity and rewards will be held for 30-35 days
                      before being returned to the wallet of origin
                    </li>
                  </ul>
                </Text>
                <Text body4 sx={{ mb: 3 }}>
                  The remaining time until your planned unstake are{' '}
                  <Text as="span" body4 sx={{ fontWeight: 600 }}>
                    {getRemainingTime(nodeData?.closesAt)}
                  </Text>
                  .
                </Text>
                <Text body4 sx={{ mb: 3 }}>
                  Therefore, the penalty amount is{' '}
                  <Text as="span" body4 sx={{ fontWeight: 600 }}>
                    {penalty?.toFixed()} MNW
                  </Text>
                  .
                </Text>
                <Text body4 sx={{ mb: 3 }}>
                  Your adjusted reward amount will be{' '}
                  <Text as="span" body4 sx={{ fontWeight: 600 }}>
                    {reward?.toFixed(2)} MNW
                  </Text>
                  .
                </Text>
                <Text body4 sx={{ mb: 3 }}>
                  {`We kindly suggest waiting until the end of the staking period (${getRemainingTime(
                    nodeData?.closesAt,
                  )}) to avoid these penalties.`}
                </Text>
              </>
            ) : (
              <>
                <Text body4>
                  Your current uptime is {totalUptimePercentage?.toFixed(2)}%,
                  which is below the required 99%.
                </Text>
                <Text body4 sx={{ mb: 3 }}>
                  You can reach uptime time {maxUptime.toFixed(2)}% by the end
                  of the staking terms. Moreover, equity and rewards will be
                  held for 30-35 days before being returned to your wallet.
                </Text>
                <Text body4 sx={{ mb: 3 }}>
                  We strongly suggest that you remain staked until the end of
                  the period and ensure your uptime meets the required threshold
                  to receive rewards.
                </Text>
                <Text body4 sx={{ mb: 3 }}>
                  The remaining time until your planned unstake are{' '}
                  <Text as="span" body4 sx={{ fontWeight: 600 }}>
                    {getRemainingTime(nodeData?.closesAt)}
                  </Text>
                  .
                </Text>
              </>
            )}

            <Text body4 sx={{ mb: 3, textAlign: 'center' }}>
              Would you still like to unstake?
            </Text>
            <FormControlLabel
              control={<Checkbox style={{ paddingTop: 0, marginRight: 0 }} />}
              label={
                <Text body4 sx={{ mb: 1 }}>
                  I fully understand and accept that penalties will be applied
                  for early unstaking
                </Text>
              }
              onClick={handleAgreementChange}
            />
          </Box>

          <ButtonWrapper>
            <LoadingButton
              disabled={!userAgreed}
              error
              style={{ marginTop: '20px' }}
              text={'Unstake'}
              onClick={onConfirm}
            />

            <LoadingButton
              secondary
              style={{ marginTop: '10px' }}
              text={'Cancel'}
              onClick={onClose}
            />
          </ButtonWrapper>
        </Box>
      )}
    </CustomModal>
  )
}

export default React.memo(UnstakeTermsModal)
