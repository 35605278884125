import { INACTIVITY_NODE_PERIOD_MINUTES } from 'constants/params'
import { DateTime } from 'luxon'

export function isNodeActive(active: boolean, lastActivity?: string) {
  if (!lastActivity || !active) return false

  return (
    DateTime.now().diff(DateTime.fromISO(lastActivity)).as('minutes') <
    INACTIVITY_NODE_PERIOD_MINUTES
  )
}
