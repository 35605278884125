import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'
import Text from 'components/ui/Text'
import { NODE_GLOBAL_STATS } from 'graphql/nodes/queries'
import { useResponsiveLayout } from 'hooks'
import { formatTotal } from 'utils/formatTotalAmount'

import { Container, Wrapper } from './styles'

interface NodeGlobalStats {
  totalRewardsPaid: number
  totalMnwStaked: number
  currentStakeLimit: number
  allTimeNodes: number
}

function NodesTotalBox() {
  const { isLargeDesktop } = useResponsiveLayout()

  const { data, loading } = useQuery(NODE_GLOBAL_STATS)

  const nodeGlobalStats: NodeGlobalStats = useMemo(
    () => data?.nodeGlobalStats || {},
    [data],
  )

  if (loading)
    return <CircularProgress size={20} sx={{ margin: '75px auto' }} />

  return (
    <Wrapper
      sx={{
        gridTemplateColumns: !isLargeDesktop
          ? 'repeat(2, 1fr)'
          : 'repeat(4, 1fr)',
      }}
    >
      <Container px={2} py={2}>
        <Text body2>Total rewards paid</Text>
        <Text title2>{formatTotal(nodeGlobalStats?.totalRewardsPaid)}</Text>
      </Container>

      <Container px={2} py={2}>
        <Text body2>Total MNW staked</Text>
        <Text title2>{formatTotal(nodeGlobalStats?.totalMnwStaked)}</Text>
      </Container>

      <Container px={2} py={2}>
        <Text body2>Current stake limit</Text>
        <Text title2>{formatTotal(nodeGlobalStats?.currentStakeLimit)}</Text>
      </Container>

      <Container px={2} py={2}>
        <Text body2>All time nodes</Text>
        <Text title2>{formatTotal(nodeGlobalStats?.allTimeNodes)}</Text>
      </Container>
    </Wrapper>
  )
}

export default NodesTotalBox
