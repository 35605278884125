import { gql } from '@apollo/client'

import { PHASE_FIELDS } from './fragments/phase'

export const CURRENT_PHASE = gql`
  query CurrentPhase {
    currentPhase {
      ...PhaseFields
    }
  }
  ${PHASE_FIELDS}
`

export const PHASES = gql`
  query Phases($status: PhaseStatus) {
    phases(status: $status) {
      ...PhaseFields
    }
  }
  ${PHASE_FIELDS}
`
