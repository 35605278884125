import { Link } from 'react-router-dom'

import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const HeaderButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const HeaderTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  fontSize: '24px',
  fontWeight: '500',
}))

export const HeaderSeeAllLink = styled(Link)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  fontSize: '14px',
  fontWeight: '500',
  textDecoration: 'none',
}))

export const NodeListWrapper = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: '1fr',
  gridColumnGap: '15px',
  gridRowGap: '0px',
  overflowY: 'auto',
}))

export const AddressContent = styled(Box)(() => ({
  display: 'flex',
}))
