export const getNodesStatus = (status: string) => {
  const allStatuses = {
    disabled: 'red',
    ready: 'green',
    enabled: 'blue',
    terminated: 'yellow',
  }[status]

  return allStatuses || 'white'
}
