import { AlertColor } from '@mui/material'

export enum AlertType {
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface AlertMessage {
  isOpen: boolean
  title?: string
  text?: string
  color?: AlertColor
}
