import { Link } from 'react-router-dom'

import { Box, Button, Card, InputBase } from '@mui/material'
import { styled } from '@mui/system'

export const Title = styled(Box)(({ theme }) => ({
  fontSize: '32px',
  lineHeight: '40px',
  fontWeight: '700',
  color: theme.palette.mode === 'light' ? 'rgba(0, 0, 33, 1)' : 'white',
}))

export const LowerTitle = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '500',
  margin: '10px 0',
  textAlign: 'left',
  color: theme.palette.mode === 'light' ? 'rgba(0, 0, 33, 1)' : 'white',
}))

export const Subtitle = styled(Box)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? 'rgba(122, 137, 166, 1)'
      : 'rgba(105, 122, 151, 1)',
  fontSize: '14px',
  fontWeight: 'bold',
  marginTop: '10px',
  marginBottom: '12px',
  lineHeight: '24px',
  textAlign: 'left',
}))

// todo refactore for all buttons, create mui theme for buttons, add hover & active styles
interface ButtonProps {
  theme: any
  secondary?: boolean
  success?: boolean
  error?: boolean
  neutral?: boolean
}
export const SubmitButton = styled(Button)(
  ({ theme, secondary, success, error, neutral }: ButtonProps) => ({
    width: '100%',
    height: '56px',
    color: secondary
      ? theme.palette.buttons.color.secondary
      : theme.palette.buttons.color.primary,
    background: secondary
      ? theme.palette.buttons.backgroundColor.secondary
      : theme.palette.buttons.backgroundColor.primary,
    border: '1px solid',
    borderColor: secondary
      ? theme.palette.buttons.border.color.secondary
      : theme.palette.buttons.border.color.primary,
    borderRadius: '8px',
    fontWeight: 'bold',
    marginTop: '32px',
    lineHeight: '24px',
    fontSize: '16px',
    textTransform: 'none',
    '&.Mui-disabled': {
      borderColor: secondary
        ? theme.palette.buttons.disabled.border.color.secondary
        : theme.palette.buttons.disabled.border.color.primary,
      background: secondary
        ? theme.palette.buttons.disabled.backgroundColor.secondary
        : theme.palette.buttons.disabled.backgroundColor.primary,
      color: secondary
        ? theme.palette.buttons.disabled.color.secondary
        : theme.palette.buttons.disabled.color.primary,
    },
    '&.Mui-active': {
      borderColor: secondary
        ? theme.palette.buttons.active.border.color.secondary
        : theme.palette.buttons.active.border.color.primary,
      background: secondary
        ? theme.palette.buttons.active.backgroundColor.secondary
        : theme.palette.buttons.active.backgroundColor.primary,
      color: secondary
        ? theme.palette.buttons.active.color.secondary
        : theme.palette.buttons.active.color.primary,
    },
    '&.MuiButton-root:hover': {
      borderColor: secondary
        ? theme.palette.buttons.hover.border.color.secondary
        : theme.palette.buttons.hover.border.color.primary,
      background: secondary
        ? theme.palette.buttons.hover.backgroundColor.secondary
        : theme.palette.buttons.hover.backgroundColor.primary,
      color: secondary
        ? theme.palette.buttons.hover.color.secondary
        : theme.palette.buttons.hover.color.primary,
    },
    ...(success && {
      color: theme.palette.buttons.color.success,
      background: theme.palette.buttons.backgroundColor.success,
      borderColor: theme.palette.buttons.border.color.success,
      '&.Mui-disabled': {
        borderColor: theme.palette.buttons.disabled.border.color.success,
        background: theme.palette.buttons.disabled.backgroundColor.success,
        color: theme.palette.buttons.disabled.color.success,
      },
      '&.Mui-active': {
        borderColor: theme.palette.buttons.active.border.color.success,
        background: theme.palette.buttons.active.backgroundColor.success,
        color: theme.palette.buttons.active.color.success,
      },
      '&.MuiButton-root:hover': {
        borderColor: theme.palette.buttons.hover.border.color.success,
        background: theme.palette.buttons.hover.backgroundColor.success,
        color: theme.palette.buttons.hover.color.success,
      },
    }),
    ...(error && {
      color: theme.palette.buttons.color.error,
      background: theme.palette.buttons.backgroundColor.error,
      borderColor: theme.palette.buttons.border.color.error,
      '&.Mui-disabled': {
        borderColor: theme.palette.buttons.disabled.border.color.error,
        background: theme.palette.buttons.disabled.backgroundColor.error,
        color: theme.palette.buttons.disabled.color.error,
      },
      '&.Mui-active': {
        borderColor: theme.palette.buttons.active.border.color.error,
        background: theme.palette.buttons.active.backgroundColor.error,
        color: theme.palette.buttons.active.color.error,
      },
      '&.MuiButton-root:hover': {
        borderColor: theme.palette.buttons.hover.border.color.error,
        background: theme.palette.buttons.hover.backgroundColor.error,
        color: theme.palette.buttons.hover.color.error,
      },
    }),
    ...(neutral && {
      color: theme.palette.buttons.color.neutral,
      background: theme.palette.buttons.backgroundColor.neutral,
      borderColor: theme.palette.buttons.border.color.neutral,
      '&.Mui-disabled': {
        borderColor: theme.palette.buttons.disabled.border.color.neutral,
        background: theme.palette.buttons.disabled.backgroundColor.neutral,
        color: theme.palette.buttons.disabled.color.neutral,
      },
      '&.Mui-active': {
        borderColor: theme.palette.buttons.active.border.color.neutral,
        background: theme.palette.buttons.active.backgroundColor.neutral,
        color: theme.palette.buttons.active.color.neutral,
      },
      '&.MuiButton-root:hover': {
        borderColor: theme.palette.buttons.hover.border.color.neutral,
        background: theme.palette.buttons.hover.backgroundColor.neutral,
        color: theme.palette.buttons.hover.color.neutral,
      },
    }),
  }),
)

export const StyledLink = styled(Link)(() => ({
  color: '#253566',
  textDecoration: 'none',
}))

export const StyledNodeLink = styled('a')(() => ({
  color: '#253566',
  textDecoration: 'none',
}))

export const NodeCard = styled(Card)(({ theme }) => ({
  textAlign: 'left',
  width: '100%',
  maxWidth: '260px',
  background: theme.palette.mode === 'light' ? '#F7F9FF' : '#0D0C2D',
}))

export const CardTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#000021' : '#fff',
  fontSize: '16px',
  fontWeight: '500',
}))

export const CardSubtitle = styled(Box)(() => ({
  color: '#697A97',
  fontSize: '16px',
  fontWeight: '500',
}))

// todo need creat ui kit for project
export const StyledInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  height: '56px',
  borderRadius: '8px',
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(229, 237, 253, 1)' : '#253566'
  }`,
  background: theme.palette.mode === 'light' ? '' : '#0D0D26',
  marginTop: '40px',
  padding: '16px',
  color: theme.palette.mode === 'light' ? 'rgba(0, 0, 33, 1)' : 'white',

  '& svg *': {
    stroke: theme.palette.mode === 'light' ? 'rgba(229, 237, 253, 1)' : '',
  },
  '&.Mui-focused': {
    border: '2px solid rgba(77, 117, 184, 1)',

    '& .MuiInputBase-input::placeholder': {
      color: 'rgba(13, 13, 38, 1)',
    },
  },
  '& .MuiInputBase-input::placeholder': {
    color:
      theme.palette.mode === 'light' ? 'rgba(185, 203, 238, 1)' : '#253566',
    fontSize: '16px',
  },
}))
