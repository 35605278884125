import { css } from '@mui/material'
import { styled } from '@mui/system'

interface ExtendedProps {
  theme?: any
  completed?: boolean
  failed?: boolean
  pending?: boolean
}

const pendingCss = ({ pending, theme }: ExtendedProps) =>
  pending &&
  css`
    background-color: ${theme.palette.colors.bg.warning};
  `

const failedCss = ({ failed, theme }: ExtendedProps) =>
  failed &&
  css`
    background-color: ${theme.palette.colors.bg.error};
  `

const completedCss = ({ completed, theme }: ExtendedProps) =>
  completed &&
  css`
    background-color: ${theme.palette.colors.bg.positive};
  `

type Props = ExtendedProps

export const ActivityWrapper = styled('div')<Props>`
  display: flex;
  border-radius: 4px;
  gap: 16px;
  padding: 12px;
  height: 94px;
  background-color: ${({ theme }) => theme.palette.colors.bg.dropdown};
  cursor: pointer;

  ${completedCss}
  ${failedCss}
  ${pendingCss}
`
