import palette from '../palette'

const buttons = () => ({
  border: {
    radius: {
      default: 8,
    },
    color: {
      primary: palette.primary,
      secondary: palette.secondaryBlue,
      error: palette.error,
      success: palette.positive,
      neutral: palette.lightBlue,
    },
  },
  color: {
    primary: palette.white,
    secondary: palette.primary,
    error: palette.white,
    success: palette.white,
    neutral: palette.white,
  },
  backgroundColor: {
    primary: palette.primary,
    secondary: palette.white,
    error: palette.error,
    success: palette.positive,
    neutral: palette.lightBlue,
  },
  active: {
    border: {
      color: {
        primary: palette.active,
        secondary: palette.active,
        error: palette.error,
        success: palette.positive,
        neutral: palette.lightBlue,
      },
    },
    color: {
      primary: palette.white,
      secondary: palette.active,
      error: palette.white,
      success: palette.white,
      neutral: palette.white,
    },
    backgroundColor: {
      primary: palette.active,
      secondary: palette.white,
      error: palette.error,
      success: palette.positive,
      neutral: palette.lightBlue,
    },
  },
  hover: {
    border: {
      color: {
        primary: palette.hover,
        secondary: palette.hover,
        error: palette.error,
        success: palette.positive,
        neutral: palette.lightBlue,
      },
    },
    color: {
      primary: palette.white,
      secondary: palette.primary,
      error: palette.white,
      success: palette.white,
      neutral: palette.white,
    },
    backgroundColor: {
      primary: palette.hover,
      secondary: palette.white,
      error: palette.error,
      success: palette.positive,
      neutral: palette.lightBlue,
    },
  },
  disabled: {
    border: {
      color: {
        primary: palette.lightDisabled,
        secondary: palette.secondaryBlue,
        error: palette.lightDisabled,
        success: palette.lightDisabled,
        neutral: palette.lightDisabled,
      },
    },
    backgroundColor: {
      primary: palette.lightDisabled,
      secondary: palette.white,
      error: palette.lightDisabled,
      success: palette.lightDisabled,
      neutral: palette.lightDisabled,
    },
    color: {
      primary: palette.white,
      secondary: palette.lightDisabledText,
      error: palette.white,
      success: palette.white,
      neutral: palette.white,
    },
  },
})

export default buttons
