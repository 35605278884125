import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { ReactComponent as VisibilityGlyph } from 'assets/icons/visibility.svg'

export const NodeTableRow = styled(Box)`
  background-color: ${({ theme }) => theme.palette.colors.bg.header};
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 0.55fr 2.7fr 0.65fr 0.65fr 0.45fr;
  border-radius: 8px;
  padding: 16px 0;
  text-align: initial;
  color: ${({ theme }) => theme.palette.colors.font.default};
`

export const NodeDescription = styled(Box)`
  color: ${({ theme }) => theme.palette.colors.font.secondary};
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`

export const VisibilityIcon = styled(VisibilityGlyph)`
  cursor: pointer;
`
