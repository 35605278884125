import React from 'react'

import StarOutlineIcon from '@mui/icons-material/StarOutline'
import { Box, TableCell } from '@mui/material'
import { RandomAvatar } from 'components/RandomAvatar'
import Text from 'components/ui/Text'
import { Leaderboard } from 'types/leaderbord'

import { PointsContainer, ValidatorTableRow } from './styles'

type Props = {
  active?: boolean
  rank: number
  leaderboard: Leaderboard
}

function LeaderboardTableRow({ active, rank, leaderboard }: Props) {
  return (
    <ValidatorTableRow active={active} key={leaderboard.validatorId}>
      <TableCell>
        <Text body2>{rank}</Text>
      </TableCell>
      <TableCell
        sx={{ maxWidth: '100px', overflow: 'hidden', padding: '0 8px' }}
      >
        <Box
          alignItems="center"
          display="flex"
          maxWidth="100%"
          overflow="hidden"
          sx={{
            width: '100%',
            flexWrap: 'nowrap',
          }}
        >
          <div>
            <RandomAvatar name={leaderboard?.validatorName} />
          </div>

          <Text body2 ellipsis sx={{ ml: 1 }}>
            {leaderboard?.validatorName || <>&mdash;</>}
          </Text>
          {active && <Text>(me)</Text>}
        </Box>
      </TableCell>
      <TableCell>
        <PointsContainer gap={0.5} sx={{ textAlign: 'end' }}>
          <StarOutlineIcon />
          <Text body2>{leaderboard.totalPoints}</Text>
        </PointsContainer>
      </TableCell>
    </ValidatorTableRow>
  )
}

export default LeaderboardTableRow
