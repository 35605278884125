import React, { useContext } from 'react'

import { useQuery } from '@apollo/client'
import { Link } from '@mui/material'
import { ReactComponent as VerificationIconDM } from 'assets/icons/verification_icon_dark.svg'
import { ReactComponent as VerificationIconLM } from 'assets/icons/verification_icon_light.svg'
import { ThemeContext } from 'context/themeMode'
import { SubmitButton, Subtitle, Title } from 'globalStyles'
import { GET_VALIDATOR_REVIEW_STATUS } from 'graphql/validators/queries'
import { VerificationStatuses } from 'graphql/validators/types'

interface Props {
  onClick: () => void
}

function VerificationIntroduce({ onClick }: Props) {
  const { mode } = useContext(ThemeContext)

  const VerificationIcon =
    mode === 'dark' ? VerificationIconDM : VerificationIconLM

  const { data } = useQuery(GET_VALIDATOR_REVIEW_STATUS)

  // todo need update text and flow

  if (data?.getValidatorReviewStatus === VerificationStatuses.COMPLETED) {
    return (
      <>
        <VerificationIcon />
        <Title style={{ textAlign: 'center' }}>You have completed KYC!</Title>
        <Subtitle style={{ textAlign: 'center' }}>
          The staking period will start December 1, 2022. Please keep an eye on
          your inbox in late November for further emails from trustmnw.com with
          the next steps!
        </Subtitle>
        <Subtitle
          style={{ fontWeight: 'bold', marginTop: 16, textAlign: 'center' }}
        >
          Contact admin in the{' '}
          <Link
            href="https://t.me/themorpheusnetwork"
            rel="noreferrer"
            target="_blank"
          >
            Telegram
          </Link>{' '}
          to be approved next
        </Subtitle>
      </>
    )
  }

  return (
    <>
      <VerificationIcon />
      <Title style={{ textAlign: 'center' }}>
        To continue you need to be verified
      </Title>
      <Subtitle style={{ textAlign: 'center' }}>
        You will be redirected to our partner, Sum & Substance, for the KYC/AML
        Compliance Testing
      </Subtitle>
      <SubmitButton onClick={onClick}>Verification</SubmitButton>
    </>
  )
}

export default VerificationIntroduce
