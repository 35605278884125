import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Chart, GoogleChartWrapper } from 'react-google-charts'

import { Loader } from 'components/loader'
import Text from 'components/ui/Text'
import { LOW_UPTIME_LINE, WARNING_UPTIME_LINE } from 'constants/params'
import { ThemeContext } from 'context/themeMode'
import { UptimePoint } from 'types/point'
import { createUptimeChartOptions } from 'utils/createChartOptions'
import { generateUptimeChartData } from 'utils/uptimeChart'

import forEach from 'lodash/forEach'

import palette from 'Theme/palette'

import {
  Amount,
  ChartContainer,
  ChartWrapper,
  GridLimitsContainer,
  GridTitle,
} from '../styles'

const gridlinesColors = [palette.error, palette.warning]

interface Props {
  isOnboarding?: boolean
  loadingUptimeData?: boolean
  totalUptimePercentage?: number
  uptime?: string
  uptimeChartPoints?: UptimePoint[]
  uptimeChartWidth?: string | number
}

function Charts({
  isOnboarding,
  loadingUptimeData,
  totalUptimePercentage = 0,
  uptime,
  uptimeChartPoints,
  uptimeChartWidth,
}: Props) {
  const mode = useContext(ThemeContext)

  const uptimeChartOptions = createUptimeChartOptions(mode?.mode)

  const [uptimeContainerId, setUptimeContainerId] = useState<string>('')

  const uptimeChartData = useMemo(() => {
    if (uptimeChartPoints) {
      return generateUptimeChartData(uptimeChartPoints, mode.mode)
    }
    return []
  }, [uptimeChartPoints, mode])

  const handleChartWrapper = (chartWrapper: GoogleChartWrapper) => {
    const containerId = chartWrapper?.getContainerId()
    setUptimeContainerId(containerId)
  }

  const handleUptimeChart = useCallback((uptimeContainerId: string) => {
    const container = document.getElementById(uptimeContainerId)
    let gridlineIndex = 0

    forEach(container?.getElementsByTagName('rect'), rect => {
      if (rect.getAttribute('height') === '1') {
        rect.setAttribute('fill-opacity', '0.2')
        rect.setAttribute('fill', gridlinesColors[gridlineIndex])
        gridlineIndex = +1
      }
    })
  }, [])

  useEffect(() => {
    if (uptimeContainerId) {
      handleUptimeChart(uptimeContainerId)
    }
  }, [handleUptimeChart, uptimeContainerId])

  const totalPercentageColor = useMemo(() => {
    if (totalUptimePercentage >= WARNING_UPTIME_LINE) {
      return 'colors.font.positive'
    }
    if (
      totalUptimePercentage < WARNING_UPTIME_LINE &&
      totalUptimePercentage >= LOW_UPTIME_LINE
    ) {
      return 'colors.font.warning'
    }
    return 'colors.font.error'
  }, [totalUptimePercentage])

  return (
    <ChartContainer
      sx={{ minHeight: '215px' }}
      width={uptimeChartWidth || '100%'}
    >
      <Text body2 sx={{ mx: 2, my: 2 }}>
        Uptime
      </Text>
      {loadingUptimeData ? (
        <Loader style={{ width: '20px', height: '20px' }} />
      ) : (
        <>
          {isOnboarding ? (
            <Text sx={{ mx: 2 }}>Onboarding...</Text>
          ) : (
            <>
              <Amount color={totalPercentageColor} mx={2}>
                {totalUptimePercentage?.toFixed(2)}%
              </Amount>
              <Text sx={{ mb: 3, mt: 1, mx: 2 }}>{uptime}</Text>
            </>
          )}

          <ChartWrapper>
            {uptimeChartData?.length > 2 ? (
              <>
                <GridLimitsContainer>
                  <GridTitle color={'colors.font.warning'} mb="22px">
                    {WARNING_UPTIME_LINE}%
                  </GridTitle>
                  <GridTitle color={'colors.font.error'}>
                    {LOW_UPTIME_LINE}%
                  </GridTitle>
                </GridLimitsContainer>
                <Chart
                  chartType="AreaChart"
                  data={uptimeChartData}
                  getChartWrapper={handleChartWrapper}
                  height="100%"
                  options={uptimeChartOptions}
                  width="100%"
                />
              </>
            ) : (
              <Text sx={{ mx: 2 }}>Data will be available in a day</Text>
            )}
          </ChartWrapper>
        </>
      )}
    </ChartContainer>
  )
}

export default React.memo(Charts)
