import { useMemo } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'

export default function useResponsiveLayout() {
  const theme = useTheme()

  const breakpoints = useMemo(() => theme.breakpoints, [theme])

  const isMobile = useMediaQuery(breakpoints.down('sm')) // < 600px
  const isTablet = useMediaQuery(breakpoints.between('sm', 'md')) // 600px до 960px
  const isDesktop = useMediaQuery(breakpoints.between('md', 'lg')) // 960px до 1280px
  const isLargeDesktop = useMediaQuery(breakpoints.up('lg')) // ≥ 1280px

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
  }
}
