// todo update with theme
export const commonChartOptions = {
  curveType: 'none',
  fontFamily: 'Archivo',
  baselineColor: '#D0D0D9',
  vAxis: {
    gridlines: {
      color: 'transparent',
    },
    textStyle: { color: '#697A97', fontSize: '12px' },
  },
  hAxis: {
    gridlines: {
      color: 'transparent',
    },
    textStyle: { color: '#697A97', fontSize: '12px', padding: '28px' },
    format: 'dd MMM',
  },
  backgroundColor: 'transparent',

  legend: 'none',
  areaOpacity: 0.1,
  tooltip: { isHtml: true, trigger: 'visible' },
}
