import React from 'react'

import AddIcon from '@mui/icons-material/Add'
import { Box, Button, SxProps } from '@mui/material'
import { RandomAvatar } from 'components/RandomAvatar'
import Text from 'components/ui/Text'
import { useAppContext } from 'context/AppContext'

import { SelectUserNameWrapper } from './styles'

type Props = {
  wrapperStyles?: SxProps
}
function Avatar({ wrapperStyles }: Props) {
  const { me, setUsername } = useAppContext()

  if (!me) return null

  return me.name ? (
    <Box display="flex" gap={1.5} sx={{ ...wrapperStyles }}>
      <RandomAvatar name={me.name} />

      <Text ellipsis sx={{ maxWidth: '150px' }} title5>
        {me.name}
      </Text>
    </Box>
  ) : (
    <Button
      sx={{ height: '40px', padding: 0 }}
      variant="text"
      onClick={setUsername}
    >
      <SelectUserNameWrapper gap={0.5}>
        <AddIcon />

        <Text body2 primary textTransform="initial">
          Select username
        </Text>
      </SelectUserNameWrapper>
    </Button>
  )
}

export default Avatar
