import { gql } from '@apollo/client'

export const VALIDATOR_MONTHLY_REPORT = gql`
  query validatorMonthlyReport($date: String!, $validatorId: String!) {
    validatorMonthlyReport(date: $date, validatorId: $validatorId) {
      month
      rewardGraphUrl
      uptimeGraphUrl
      uptime
      uptimeTime
      currentEarning
      withdrawEarning
      nodeCreatedAt
      wallet
      totalStakedDays
      payDate
      totalRewardPaid
      diffRewardFromLastMonth
      totalMNWStaked
      diffTotalMNWStakedFromLastMonth
      validatorStakedNodesCount
    }
  }
`
