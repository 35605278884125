import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Divider, Drawer, IconButton } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import AntSwitch from 'components/AntSwitch'
import CommunityFeed from 'components/blocks/communityFeed/communityFeed'
import { Avatar, BalanceInfo, WalletButton } from 'components/header/Blocks'
import { useAppContext } from 'context/AppContext'
import { ThemeContext } from 'context/themeMode'
import {
  useBannerPlaceholder,
  useDevice,
  useNavigation,
  useStandalone,
  useWeb3,
} from 'hooks'

import { Container, MenuHolder, Overflow, UserNameHolder } from './styles'

function MobileMenu() {
  const { me } = useAppContext()
  const { active } = useWeb3()
  const { mode } = useContext(ThemeContext)
  const { navItems } = useNavigation()
  const bannerPlaceholder = useBannerPlaceholder()
  const isStandalone = useStandalone()
  const { isAndroid, isMobileSafari } = useDevice()

  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const [isCommunityFeedOpened, setIsCommunityFeedOpened] = useState(false)

  const handleNavItemClick = useCallback(
    (action?: () => void, shouldMenuClose?: boolean) => {
      if (shouldMenuClose) setIsMenuOpened(false)

      action?.()
    },
    [],
  )

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = isMenuOpened
      ? 'hidden'
      : ''

    const widgetContainer = document.getElementById('worklair-support-widget')
    const widgetTippy = document.getElementById('tippy-1')

    if (widgetContainer) {
      widgetContainer.style.display = isMenuOpened ? 'none' : 'flex'
    }

    if (widgetTippy) {
      widgetTippy.style.visibility = isMenuOpened ? 'hidden' : 'visible'
    }

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = ''

      if (widgetContainer) {
        widgetContainer.style.display = 'flex'
      }

      if (widgetTippy) {
        widgetTippy.style.visibility = 'visible'
      }
    }
  }, [isMenuOpened])

  const showHomeScreenGuideLink = useMemo(
    () => !isStandalone && (isMobileSafari || isAndroid),
    [isAndroid, isMobileSafari, isStandalone],
  )

  return (
    <>
      <IconButton onClick={() => setIsMenuOpened(prevState => !prevState)}>
        <MenuIcon />
      </IconButton>

      <Overflow
        sx={{
          opacity: isMenuOpened ? 1 : 0,
          pointerEvents: isMenuOpened ? 'auto' : 'none',
        }}
        onClick={() => setIsMenuOpened(false)}
      />

      <Drawer
        anchor="right"
        open={isMenuOpened}
        sx={{
          position: 'absolute',
          flexShrink: 0,
          zIndex: 1,
        }}
        variant="persistent"
      >
        <Container>
          {bannerPlaceholder}

          <UserNameHolder>
            <Box
              display="flex"
              sx={{
                justifyContent: 'flex-end',
              }}
              width="100%"
            >
              <IconButton
                sx={{ '&:hover': { background: 'none' }, padding: '16px' }}
                onClick={() => setIsMenuOpened(false)}
              >
                <CloseIcon color="secondary" />
              </IconButton>
            </Box>

            <Box sx={{ padding: '0 16px 16px' }} width="100%">
              <Avatar wrapperStyles={{ flexDirection: 'column' }} />
            </Box>
          </UserNameHolder>

          <MenuHolder>
            <Box display="flex" flexDirection="column" width="100%">
              <Box padding="16px">
                {(!me?.wallet || !active) && <WalletButton />}

                {me?.wallet && active && <BalanceInfo />}
              </Box>

              <Divider flexItem orientation="horizontal" sx={{ mb: '16px' }} />

              <MenuItem
                onClick={() =>
                  handleNavItemClick(
                    navItems.Leaderboard.action,
                    navItems.Leaderboard.shouldMenuClose,
                  )
                }
              >
                <ListItemIcon>{navItems.Leaderboard.icon}</ListItemIcon>
                {navItems.Leaderboard.caption}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  handleNavItemClick(
                    navItems.Instruction.action,
                    navItems.Instruction.shouldMenuClose,
                  )
                }
              >
                <ListItemIcon>{navItems.Instruction.icon}</ListItemIcon>
                {navItems.Instruction.caption}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  handleNavItemClick(
                    navItems.LightPaper.action,
                    navItems.LightPaper.shouldMenuClose,
                  )
                }
              >
                <ListItemIcon>{navItems.LightPaper.icon}</ListItemIcon>
                {navItems.LightPaper.caption}
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setIsMenuOpened(false)
                  setIsCommunityFeedOpened(true)
                }}
              >
                <ListItemIcon>{navItems.CommunityFeed.icon}</ListItemIcon>
                {navItems.CommunityFeed.caption}
              </MenuItem>
            </Box>

            <Box display="flex" flexDirection="column" width="100%">
              <MenuItem
                onClick={() =>
                  handleNavItemClick(
                    navItems.Settings.action,
                    navItems.Settings.shouldMenuClose,
                  )
                }
              >
                <ListItemIcon>{navItems.Settings.icon}</ListItemIcon>
                {navItems.Settings.caption}
              </MenuItem>
              {showHomeScreenGuideLink && (
                <MenuItem
                  onClick={() =>
                    handleNavItemClick(
                      navItems.UniversalApp.action,
                      navItems.UniversalApp.shouldMenuClose,
                    )
                  }
                >
                  <ListItemIcon>{navItems.UniversalApp.icon}</ListItemIcon>
                  {navItems.UniversalApp.caption}
                </MenuItem>
              )}

              <MenuItem
                onClick={() =>
                  handleNavItemClick(
                    navItems.ColorMode.action,
                    navItems.ColorMode.shouldMenuClose,
                  )
                }
              >
                <ListItemIcon>{navItems.ColorMode.icon}</ListItemIcon>
                {navItems.ColorMode.caption}

                <Box display="flex" justifyContent="flex-end" width="100%">
                  <AntSwitch checked={mode === 'dark'} />
                </Box>
              </MenuItem>

              <MenuItem
                onClick={() =>
                  handleNavItemClick(
                    navItems.Logout.action,
                    navItems.Logout.shouldMenuClose,
                  )
                }
              >
                <ListItemIcon>{navItems.Logout.icon}</ListItemIcon>
                {navItems.Logout.caption}
              </MenuItem>
            </Box>
          </MenuHolder>
        </Container>
      </Drawer>
      {isCommunityFeedOpened && (
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            zIndex: 5,
          }}
        >
          {bannerPlaceholder}

          <CommunityFeed
            onCloseCommunityFeed={() => setIsCommunityFeedOpened(false)}
          />
        </Box>
      )}
    </>
  )
}

export default MobileMenu
