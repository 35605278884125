import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const BackgroundImage = styled(Box)`
  flex: 1;
  display: flex;
  align-items: end;
  background-size: cover;
  background-position: center center;
  min-height: 360px;
  position: relative;
  overflow: hidden;
`

export const GradientWrapper = styled(Box)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(to top, rgba(14, 14, 40, 1), rgba(14, 14, 30, 0));
`

export const ActivitiesContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.colors.bg.header};
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.palette.colors.border.bright}`};
  overflow: hidden;
  width: 100%;
  max-width: 400px;
`

export const HeaderWrapper = styled(Box)`
  background-color: #000000;
`

export const ActivitiesWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 440px;
  width: 100%;
`
