import React from 'react'

import { ReactComponent as TestQueryErrorIcon } from 'assets/icons/test_query_error_icon.svg'
import InstructionsLink from 'components/InstructionsLink'
import Text from 'components/ui/Text'
import { SubmitButton } from 'globalStyles'

type Props = {
  onStartAgain?: () => void
}

function TestQueryError({ onStartAgain }: Props) {
  return (
    <>
      <TestQueryErrorIcon />
      <Text sx={{ mt: 4 }} title2>
        Oops, something went wrong
      </Text>
      <Text body2 secondary style={{ textAlign: 'center', maxWidth: '500px' }}>
        You first need to install CLI App and log in. Or you can try again later
        or contact our support
      </Text>
      <SubmitButton style={{ maxWidth: '320px' }} onClick={onStartAgain}>
        Go back
      </SubmitButton>
      <InstructionsLink />
    </>
  )
}

export default TestQueryError
