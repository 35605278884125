import MuiAlert from '@mui/material/Alert'
import { styled } from '@mui/system'

export const EnvironmentBannerWrapper = styled(MuiAlert)`
  border-radius: 0;
  color: ${({ theme }) => theme.palette.colors.font.default};
  background-color: ${({ theme }) => theme.palette.colors.font.error};
  font-size: 16px;
  .MuiAlert-icon {
    font-size: 20px;
    color: ${({ theme }) => theme.palette.colors.font.default};
  }
`
