import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useAppContext } from 'context/AppContext'
import useLogout from 'hooks/useLogout'
import useWeb3 from 'hooks/useWeb3'

export default function WrongAccount() {
  const { me } = useAppContext()
  const { account } = useWeb3()

  const logout = useLogout()

  if (!me?.wallet || !account) return null

  if (account.toLowerCase() === me.wallet.toLowerCase()) return null

  return (
    <Dialog open>
      <DialogTitle>Wrong account selected.</DialogTitle>

      <DialogContent>Please switch your account to {me?.wallet}</DialogContent>
      <DialogContent sx={{ pt: 0 }}>(currently using {account}) </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={logout}>
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  )
}
