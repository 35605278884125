import React from 'react'

import { Box, Divider } from '@mui/material'
import { AccountMenu } from 'components/accountMenu'
import { useAppContext } from 'context/AppContext'
import { useWeb3 } from 'hooks'

// todo will be update later
import { Avatar, BalanceInfo, WalletButton } from '../Blocks'

function DesktopMenu() {
  const { me } = useAppContext()
  const { active } = useWeb3()

  return (
    <>
      {(!me?.wallet || !active) && (
        <>
          <WalletButton />

          <Divider flexItem orientation="vertical" sx={{ mx: 3, my: 2.5 }} />
        </>
      )}

      {me?.wallet && active && (
        <>
          <BalanceInfo />

          <Divider flexItem orientation="vertical" sx={{ mx: 3, my: 2.5 }} />
        </>
      )}

      <Box alignItems="center" display="flex" height="inherit">
        <Avatar />

        <AccountMenu />
      </Box>
    </>
  )
}

export default DesktopMenu
