import { gql } from '@apollo/client'

export const VALIDATOR = gql`
  fragment Validator on Validator {
    id
    email
    wallet
    name
    isApproved
    stakedNode
    twitterUserId
    twitterUsername
    twitterProfileImageUrl
    totalPoints
    confirmedNewsletter
    confirmedTerms
    nodeAppId
    freezingPeriodEndAt
    nodeVersion
    nodePlatform
    nodeType
  }
`
