import { useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'
import { Provider } from 'constants/connector'
import { useAppContext } from 'context/AppContext'
import useWeb3 from 'hooks/useWeb3'
import { getProvider } from 'services/provider'

export default function RestoreNavigation() {
  const { me } = useAppContext()
  const { account, connect } = useWeb3()

  const [providerRestoring, setProviderRestoring] = useState<boolean>(true)

  useEffect(() => {
    if (!me?.wallet || !account) return

    if (account.toLowerCase() !== me.wallet.toLowerCase()) {
      // eslint-disable-next-line no-console
      console.log('wrong account, should signout')
    }
  }, [me, account])

  useEffect(() => {
    async function reconnect() {
      const provider = getProvider()

      if (!provider) {
        setProviderRestoring(false)
        return
      }

      await connect(provider as Provider)

      setProviderRestoring(false)
    }

    reconnect().then()
  }, [me, providerRestoring])

  useEffect(() => {
    if (me?.wallet || me?.email)
      Sentry.setUser({ account: me?.wallet, email: me?.email })
  }, [me])

  return null
}
