import { Typography } from '@mui/material'
import { styled } from '@mui/system'

export const LogoWrapper = styled(Typography)`
  font-family: 'Roboto Flex', serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0;
`
