import { CONTRACTS } from 'config'
import MasterContractAbi from 'constants/masterContractAbi'
import TokenContractAbi from 'constants/tokenContractAbi'
import ValidatorContractAbi from 'constants/validatorContractAbi'
import { BigNumber, ethers, Signer, Transaction } from 'ethers'

interface Library {
  getSigner: () => Signer
  getTransaction: () => Transaction
}

// Create contract
export function createMasterContract(library: Library) {
  return new ethers.Contract(
    CONTRACTS.MASTER_ADDRESS,
    MasterContractAbi,
    library?.getSigner(),
  )
}

export function createTokenContract(library: Library) {
  return new ethers.Contract(
    CONTRACTS.TOKEN_ADDRESS,
    TokenContractAbi,
    library?.getSigner(),
  )
}

export function createValidatorContract(
  library: Library,
  validatorAddress: string,
) {
  return new ethers.Contract(
    validatorAddress,
    ValidatorContractAbi,
    library?.getSigner(),
  )
}

// Requests
export async function sendApprove(library: Library, amount: BigNumber) {
  const methods = createTokenContract(library)
  const tx = await methods.approve(CONTRACTS.MASTER_ADDRESS, amount)

  return tx.wait()
}

export async function callValidatorContract(library: Library, wallet: string) {
  const methods = await createMasterContract(library)
  return methods.validatorContract(wallet)
}

export async function getValidatorMethods(library: Library, address: string) {
  const methods = await createValidatorContract(library, address)
  return methods.callStatic
}

export async function unStakeNode(library: Library, address: string) {
  const methods = await createValidatorContract(library, address)
  return methods.close()
}

export async function getEarningsNode(library: Library, address: string) {
  const methods = await createValidatorContract(library, address)
  return methods.earnings()
}
export async function withdrawEarningsNode(library: Library, address: string) {
  const methods = await createValidatorContract(library, address)
  return methods.withdrawEarnings()
}

export async function getTokenBalance(
  library: Library,
  address: string,
): Promise<BigNumber> {
  const methods = await createTokenContract(library)
  const tx = await methods.callStatic
  return tx.balanceOf(address)
}
