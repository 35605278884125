import React, { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { Box, Button, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import MorpheusFormIcon from 'assets/icons/morpheus_form_icon_dark.svg'
import { VALIDATOR_MONTHLY_REPORT } from 'graphql/validatorMonthlyReport/queries'

import { Container, StakedAmount } from './styles'

interface ValidatorMonthlyReport {
  month: string
  rewardGraphUrl: string
  uptimeGraphUrl: string
  uptime: string
  uptimeTime: string
  currentEarning: string
  firstName: string
  withdrawEarning: string
  totalStakedNodesCount: number
  totalRewardPaid: number
  diffRewardFromLastMonth: number
  totalMNWStaked: number
  diffTotalMNWStakedFromLastMonth: number
  nodeCreatedAt: string
  wallet: string
  totalStakedDays: number
  payDate: string
  validatorStakedNodesCount: string
}
function MonthlyReportPage() {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const validatorId: string = urlParams.get('validator') as string
  const date: string = urlParams.get('date') as string

  const [isCopied, setCopied] = useState(false)

  const { data, loading } = useQuery(VALIDATOR_MONTHLY_REPORT, {
    variables: { validatorId, date },
    skip: !validatorId || !date,
  })

  const validatorMonthlyReport: ValidatorMonthlyReport = useMemo(
    () => data?.validatorMonthlyReport || {},
    [data],
  )

  const handleCopy = useCallback(() => {
    const currentUrl = window.location.href

    navigator.clipboard.writeText(currentUrl).then(() => {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    })
  }, [])

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      m={0}
      minHeight="100vh"
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Container
          alignItems="center"
          display="flex"
          justifyContent="center"
          width="100%"
        >
          {!data ? (
            <Box p={4} sx={{ borderRadius: '8px' }}>
              Link is invalid. Please check the entered address for accuracy.
            </Box>
          ) : (
            <Box width="100%">
              <Typography
                fontSize="20px"
                lineHeight="22px"
                pb="32px"
                pt="18px"
                px="30px"
                textAlign="center"
              >
                This is your monthly report of {validatorMonthlyReport?.month}
                <br /> on earnings and activity on our platform.
              </Typography>

              <Box
                display="flex"
                flexWrap="wrap"
                gap={2}
                justifyContent="center"
                width="100%"
              >
                <Box
                  pb={3}
                  px={2}
                  sx={{
                    backgroundColor: '#E4ECF2',
                    borderRadius: '8px',
                    width: '252px',
                  }}
                >
                  <Typography fontSize="16px" lineHeight="20px" pb={0} pt={2}>
                    Nodes Staked
                  </Typography>
                  <Typography
                    fontSize="24px"
                    fontWeight={700}
                    lineHeight="32px"
                    pb={1}
                    pt={2}
                  >
                    {validatorMonthlyReport?.validatorStakedNodesCount} node(s)
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight={300}
                    lineHeight="20px"
                    py={1}
                  >
                    Staked on <br />
                    {validatorMonthlyReport?.nodeCreatedAt}
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight={300}
                    lineHeight="20px"
                    pt={1}
                  >
                    Address:
                  </Typography>
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    lineHeight="24px"
                    pb={1}
                  >
                    {validatorMonthlyReport?.wallet}
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight={300}
                    lineHeight="20px"
                    pb={1}
                    pt={2}
                  >
                    Total value staked:
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <img alt="MNW" src={MorpheusFormIcon} width={24} />
                    <StakedAmount
                      fontSize="24px"
                      fontWeight={700}
                      lineHeight="32px"
                      ml="7px"
                    >
                      {validatorMonthlyReport?.totalMNWStaked}
                    </StakedAmount>
                  </Box>
                </Box>
                <Box
                  pb={3}
                  sx={{
                    backgroundColor: '#E4ECF2',
                    borderRadius: '8px',
                    width: '252px',
                  }}
                >
                  <Typography fontSize="16px" lineHeight="20px" pt={2} px={2}>
                    Average Uptime
                  </Typography>
                  <Typography
                    fontSize="24px"
                    fontWeight={700}
                    lineHeight="32px"
                    pt={2}
                    px={2}
                  >
                    {validatorMonthlyReport?.uptime}%
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight={300}
                    lineHeight="20px"
                    px={2}
                    py={1}
                  >
                    Total node working hours <br />
                    {validatorMonthlyReport?.uptimeTime || 0}
                  </Typography>
                  <img
                    alt="uptime_graph"
                    src={validatorMonthlyReport?.uptimeGraphUrl}
                    width="252px"
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                gap={2}
                justifyContent="center"
                mt={2}
                width="100%"
              >
                <Box
                  pb={3}
                  sx={{
                    backgroundColor: '#E4ECF2',
                    borderRadius: '8px',
                    width: '252px',
                  }}
                >
                  <Typography fontSize="16px" lineHeight="20px" p={2}>
                    Total rewards earned
                  </Typography>
                  <Box mb="18px" mx={2} sx={{ display: 'flex' }}>
                    <img alt="MNW" src={MorpheusFormIcon} width={24} />
                    <Typography
                      fontSize="24px"
                      fontWeight={700}
                      lineHeight="32px"
                      ml="7px"
                    >
                      {validatorMonthlyReport?.totalRewardPaid}
                    </Typography>
                  </Box>
                  <img
                    alt="reward_graph"
                    src={validatorMonthlyReport?.rewardGraphUrl}
                    width="252px"
                  />
                </Box>
                <Box
                  p={2}
                  sx={{
                    backgroundColor: '#E4ECF2',
                    borderRadius: '8px',
                    width: '252px',
                  }}
                >
                  <Typography fontSize="16px" lineHeight="20px" pb={2}>
                    Current Earnings
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <img alt="MNW" src={MorpheusFormIcon} width={24} />
                    <Typography
                      fontSize="24px"
                      fontWeight={700}
                      lineHeight="32px"
                      ml="7px"
                    >
                      {validatorMonthlyReport?.currentEarning}
                    </Typography>
                  </Box>
                  <Typography fontSize="16px" lineHeight="20px" py={2}>
                    Earnings to withdraw
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <img alt="MNW" src={MorpheusFormIcon} width={24} />
                    <Typography
                      fontSize="24px"
                      fontWeight={700}
                      lineHeight="32px"
                      ml="7px"
                    >
                      {validatorMonthlyReport?.withdrawEarning}
                    </Typography>
                  </Box>
                  <Typography
                    fontSize="14px"
                    fontWeight={300}
                    lineHeight="20px"
                    py={2}
                  >
                    Payout date: {validatorMonthlyReport?.payDate}
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight={300}
                    lineHeight="20px"
                    pb={2}
                  >
                    Total days staked: {validatorMonthlyReport?.totalStakedDays}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" py="30px">
                <Button
                  sx={{ textTransform: 'none', fontSize: '20px' }}
                  onClick={handleCopy}
                >
                  {isCopied ? 'Copied!' : 'Copy link'}
                </Button>
              </Box>
            </Box>
          )}
        </Container>
      )}
    </Box>
  )
}

export default MonthlyReportPage
