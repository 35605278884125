import React, { useCallback, useContext, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import CircleIcon from '@mui/icons-material/Circle'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Typography } from '@mui/material'
import MorpheusFormIcon from 'assets/icons/morpheus_form_icon_light.svg'
import { WEB3 } from 'config'
import getPlatform from 'constants/platform'
import { ThemeContext } from 'context/themeMode'
import { NodeInfo, NodeStatus } from 'graphql/nodes/types'
import { dateFormatWithSeconds } from 'utils/date'
import { truncateAddress } from 'utils/truncateAddress'

import { NodeDescription, NodeTableRow, VisibilityIcon } from './styles'

import { LinkIcon } from '../../detailNodeColumns/styles'

interface Props {
  node: Node
  onCopyAddress: (address: string) => void
}

interface Node {
  address: string
  createdAt: string
  updatedAt: string
  id: string
  transactionHash: string
  stakedAmount: number
  status: NodeStatus
  nodeInfo: NodeInfo
  workers: number
  reward: number
}

function NodeRow({ node, onCopyAddress }: Props) {
  const mode = useContext(ThemeContext)

  const navigate = useNavigate()
  const { address } = useParams()

  const handleCopyAddress = useCallback(
    (e: React.SyntheticEvent<EventTarget>) => {
      e.stopPropagation()
      onCopyAddress(node?.address)
    },
    [node, onCopyAddress],
  )

  const appVersion = useMemo(
    () =>
      node?.nodeInfo?.platform &&
      node?.nodeInfo?.version &&
      node?.nodeInfo?.type
        ? `${getPlatform(node.nodeInfo.platform)} ${node.nodeInfo.version} (${
            node.nodeInfo.type === 'desktop' ? 'Desktop' : 'CLI'
          })`
        : '--',
    [node],
  )

  return (
    <NodeTableRow
      gap={1}
      key={node?.id}
      style={{
        textDecoration: 'none',
      }}
      onClick={() => navigate(`/detail-node/${node?.address}`)}
    >
      <Box>
        <CircleIcon
          fill="red"
          fontSize="small"
          sx={{
            ml: 2,
            fill: node?.status === NodeStatus.ENABLED ? '#26B568' : '#697A97',
            width: '12px',
          }}
        />
        {node?.status === NodeStatus.ENABLED}
      </Box>
      <Box>
        <Box
          mb={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          ID: {truncateAddress(node?.address)}{' '}
          <ContentCopyIcon
            style={{
              cursor: 'pointer',
              fill: mode.mode === 'dark' ? '#697A97' : '#7A89A6',
              width: '14px',
              margin: '0 14px 0 12px',
            }}
            onClick={handleCopyAddress}
          />
          <a
            href={`${WEB3.POLYGONSCAN_URL}/tx/${node?.transactionHash}`}
            rel="noreferrer"
            target="_blank"
            onClick={(e: React.SyntheticEvent<EventTarget>) =>
              e.stopPropagation()
            }
          >
            <LinkIcon />
          </a>
        </Box>
        <NodeDescription>
          Created: {dateFormatWithSeconds(node?.createdAt)}
        </NodeDescription>
        <NodeDescription>
          Last updated: {dateFormatWithSeconds(node?.updatedAt)}
        </NodeDescription>
        <NodeDescription>Validator app version: {appVersion}</NodeDescription>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {node?.workers}
      </Box>
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img alt="MNW" src={MorpheusFormIcon} width={16} />{' '}
        <Typography ml={1}>{node?.reward?.toFixed()}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {node?.address !== address && (
          <VisibilityIcon
            onClick={() => navigate(`/detail-node/${node?.address}`)}
          />
        )}
      </Box>
    </NodeTableRow>
  )
}

export default NodeRow
