import { Button } from '@mui/material'
import { styled } from '@mui/system'

export const SubmitButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '56px',
  color: 'white',
  background: 'rgba(77, 117, 184, 1)',
  borderRadius: '8px',
  fontWeight: 'bold',
  marginTop: '24px',
  lineHeight: '24px',
  fontSize: '16px',
  textTransform: 'none',
  '&.Mui-disabled': {
    background:
      theme.palette.mode === 'light' ? 'rgba(237, 240, 245, 1)' : '#0C2042',
    color:
      theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 1)' : '#1B3A6F',
  },
}))
