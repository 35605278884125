import { commonChartOptions } from 'constants/chartOptions'
import { getDateForCharts } from 'constants/date'
import { getFormattedNumber } from 'constants/number'
import { Mode } from 'context/themeMode'
import { ValidationsPerDay } from 'graphql/validationsPerDayByNodeId/types'

import { dateFormatParser } from './uptimeChart'

export enum TokenChartDataLabels {
  AllTrustMnwTokens = 'All TrustMnw Tokens',
  ValidatorNodesTokens = 'Your Validator Node Tokens',
}

export interface TooltipInterface {
  role: string
  type: string
  p: {
    html: boolean
  }
}

export const createTokenChartOptions = (theme: 'light' | 'dark') => {
  const options = {
    is3D: true,
    backgroundColor: theme === 'light' ? '#F7F9FF' : '#0D0C2D',
    width: 710,
    height: 180,
    colors: ['#1dd170', '#4d75b8'],
    fontFamily: 'Archivo',
    titleTextStyle: {
      color: theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white',
    },
    chartArea: {
      width: '90%',
      height: '90%',
    },
    legend: {
      textStyle: {
        color: theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white',
        fontSize: 13,
      },
    },
    tooltip: { isHtml: true, trigger: 'visible' },
  }

  return options
}

export const generateTooltipBlock = (
  title: string,
  value: number,
  theme: 'light' | 'dark',
) =>
  `
		<div style="background-color: ${
      theme === 'light' ? '#fff' : '#262546'
    }; padding: 10px; margin: 0; position: absolute; min-width: 150px; border-radius: 8px;">
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-size: 14px;">${title}</p>
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-weight: bold">${value}</p>
		</div>
		`
// todo add colors to variables
export const createValidationsChartOptions = () => ({
  ...commonChartOptions,
  baselineColor: 'transparent',
  colors: ['#4D75B8'],
  chartArea: { width: '100%', height: 130 },
})
export const createUptimeChartOptions = (mode: Mode) => ({
  curveType: 'function',
  fontFamily: 'Archivo',
  baselineColor: 'transparent',
  chartArea: {
    width: '100%',
    height: 80,
  },
  hAxis: {
    textPosition: 'none',
    gridlines: { count: 0 },
  },
  vAxis: {
    minValue: 0,
    maxValue: 100,
    ticks: [50, 90],
    gridlines: {
      color: mode === Mode.DARK ? '#6e6452' : '#eed3ab',
    },
    format: "#.#'%'",
    textPosition: 'none',
  },
  colors: ['#26B568', '#FFB406', '#E93940'],
  backgroundColor: 'transparent',
  legend: 'none',
  areaOpacity: 0.1,
  tooltip: { isHtml: true, trigger: 'visible' },
  // enableInteractivity: false,
})

// todo improve
export const generateUptimeTooltipBlock = (
  point: any,
  theme: 'light' | 'dark',
) =>
  `
		<div style="background-color: ${
      theme === 'light' ? '#fff' : '#262546'
    }; padding: 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-size: 10px; padding: 5px; margin: 0;">${dateFormatParser(
    point?.key,
  )}</p>
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-weight: bold; padding: 5px; margin: 0;">${point?.value}%</p>
		</div>
		`

export const generateValidationsTooltipBlock = (
  point: ValidationsPerDay,
  theme?: 'light' | 'dark',
) => {
  return `
		<div style="background-color: ${
      theme === 'light' ? '#fff' : '#262546'
    }; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-weight: bold; padding: 0 5px 10px; margin: 0;">${getDateForCharts(
    point?.day,
  )}</p>
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-weight: bold; padding: 0 5px; margin: 0;">Amount: ${getFormattedNumber(
    point.amount,
  )}</p>
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-weight: bold; padding: 0 5px; margin: 0;">Rewards: ${getFormattedNumber(
    point.reward,
  )}</p>
		</div>
		`
}
