import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Toolbar } from '@mui/material'
import { IS_STAGING } from 'config'
import {
  STAGING_BANNER_HEIGHT,
  UNSUPPORTED_CHAIN_BANNER_HEIGHT,
} from 'constants/params'
import { Routes } from 'router/routes'

import useWeb3 from './useWeb3'

export default function useBannerPlaceholder() {
  const location = useLocation()
  const { isUnsupportedChain } = useWeb3()

  return useMemo(
    () => (
      <>
        {isUnsupportedChain && (
          <Toolbar
            sx={{ height: UNSUPPORTED_CHAIN_BANNER_HEIGHT }}
            variant="dense"
          />
        )}

        {IS_STAGING && location.pathname !== Routes.MONTHLY_REPORT && (
          <Toolbar sx={{ height: STAGING_BANNER_HEIGHT }} variant="dense" />
        )}
      </>
    ),
    [isUnsupportedChain, location],
  )
}
