import { gql } from '@apollo/client'

import { NODE_EARNING_FIELDS } from './nodeEarningType'
import { NODE_INFO_FIELDS } from './nodeInfoType'

export const NODE_FIELDS = gql`
  fragment NodeFields on Node {
    id
    address
    active
    status
    stakedAmount
    createdAt
    lastActivity
    totalUptime
    transactionHash
    closesAt
    updatedAt
    period
    workers
    unstakesAt
    earlyUnstaked
    reward
    penalty
    validatorId

    earnedRewards {
      ...NodeEarningTypeFields
    }
    projectedRewards {
      ...NodeEarningTypeFields
    }
    nodeInfo {
      ...NodeInfoTypeFields
    }
    providers {
      name
    }
    validator {
      id
      nodeAppId
    }
  }

  ${NODE_EARNING_FIELDS}
  ${NODE_INFO_FIELDS}
`
