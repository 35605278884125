import React from 'react'

import style from './workingNodeLoader.module.css'

const WorkingNodeLoader = () => (
  <div className={style.loader_container}>
    <div className={style.loader}>
      <div className={style.loaderBar} />
    </div>
  </div>
)

export default WorkingNodeLoader
