import { useCallback, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ALL_PUBLISHED_NEWS } from 'graphql/news/queries'
import { NewsArticle } from 'graphql/news/types'

export default function useNewsList({ take = 5, order = 'DESC' }) {
  const [newsList, setNewsList] = useState<NewsArticle[]>([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)

  const [loadData, { error, loading }] = useLazyQuery(ALL_PUBLISHED_NEWS)

  const fetchData = useCallback(async () => {
    try {
      setNewsList([])
      setCount(0)
      setPage(1)

      const result = await loadData({
        variables: {
          take,
          order,
          page: 1,
        },
      })
      if (!result.data) return

      const rows = result.data?.allPublishedNews?.data || []
      const count = result.data?.allPublishedNews?.meta?.itemCount

      setNewsList([...rows])
      setCount(count)
    } catch (error) {
      // todo add error handler
    }
  }, [loadData, order, take])

  useEffect(() => {
    fetchData().then()
  }, [fetchData])

  const loadMore = useCallback(async () => {
    try {
      if (newsList.length >= count) return
      setPage(page + 1)

      const result = await loadData({
        variables: {
          order,
          take,
          page: page + 1,
        },
      })
      if (!result.data) return
      const rows = result.data.allPublishedNews?.data || []
      setNewsList(prevList => [...prevList, ...rows])
    } catch (error) {
      // todo add error handler
    }
  }, [newsList, count, page, loadData, order, take])

  return { loadMore, loading, error, count, newsList }
}
