import React from 'react'

import { Radio, RadioProps } from '@mui/material'

import { CheckedIcon, Icon } from './styles'

function StyledRadio(props: RadioProps) {
  return (
    <Radio
      checkedIcon={<CheckedIcon />}
      color="default"
      disableRipple
      icon={<Icon />}
      sx={{ px: 0.75, py: 0 }}
      {...props}
    />
  )
}

export default StyledRadio
