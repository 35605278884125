import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '32px',
  fontWeight: '700',
  lineHeight: '125%',
  color: theme.palette.mode === 'light' ? '#000021' : '#ffffff',
}))

export const Caption = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '16px',
  fontWeight: '300',
  lineHeight: '150%',
  color: theme.palette.mode === 'light' ? '#7a89a6' : '#697a97',
}))

export const Paragraph = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '16px',
  fontWeight: '300',
  lineHeight: '150%',
  color: theme.palette.mode === 'light' ? '#000021' : '#ffffff',
  opacity: '0.9',

  a: {
    textDecoration: 'none',
    fontWeight: '500',
    color: '#4d75b8',
  },

  ul: {
    marginBlock: '0',
    listStyle: 'disc',
    paddingInlineStart: '20px',

    ul: {
      paddingInlineStart: '32px',
    },
  },

  li: {
    marginTop: '16px',
  },

  b: {
    fontWeight: '700',
  },
}))

export const Attention = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '150%',
  color: theme.palette.mode === 'light' ? '#000021' : '#ffffff',
}))

export const Subtitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '24px',
  fontWeight: '700',
  lineHeight: '133%',
  color: theme.palette.mode === 'light' ? '#000021' : '#ffffff',
}))

export const Footnote = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '14px',
  fontWeight: '300',
  lineHeight: '143%',
  color: theme.palette.mode === 'light' ? '#7a89a6' : '#697a97',
}))
