import React, { useCallback } from 'react'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StarIcon from '@mui/icons-material/Star'
import { Box } from '@mui/material'
import Text from 'components/ui/Text'
import { QuestActivity, VerificationQuestStatus } from 'types/quest'

import { ActivityWrapper } from './styles'

type Props = {
  activity: QuestActivity
  verificationQuestStatus: VerificationQuestStatus | null
  onTwitterIntentLink?: () => void
}
function TwitterActivity({
  activity,
  verificationQuestStatus,
  onTwitterIntentLink,
}: Props) {
  const renderVerificationStatus = useCallback(
    (status: VerificationQuestStatus) => {
      switch (status) {
        case VerificationQuestStatus.Pending:
          return (
            <Box alignItems="center" display="flex" gap={1}>
              <AccessTimeIcon color="warning" sx={{ fontSize: '12px' }} />
              <Text body3 warning>
                Verification in progress
              </Text>
            </Box>
          )
        case VerificationQuestStatus.Failed:
          return (
            <Box alignItems="center" display="flex" gap={1}>
              <CancelIcon color="error" sx={{ fontSize: '12px' }} />
              <Text body3 error>
                Verification failed
              </Text>
            </Box>
          )
        case VerificationQuestStatus.Completed:
          return (
            <Box alignItems="center" display="flex" gap={1}>
              <CheckCircleIcon color="success" sx={{ fontSize: '12px' }} />
              <Text body3 success>
                Activity completed
              </Text>
            </Box>
          )
        default:
          return null
      }
    },
    [],
  )

  return (
    <ActivityWrapper
      completed={
        verificationQuestStatus === VerificationQuestStatus.Completed.valueOf()
      }
      failed={
        verificationQuestStatus === VerificationQuestStatus.Failed.valueOf()
      }
      key={activity.title}
      pending={
        verificationQuestStatus === VerificationQuestStatus.Pending.valueOf()
      }
      onClick={onTwitterIntentLink}
    >
      {activity.icon}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
      >
        <Text mb={0.25} title4>
          {activity.title}
        </Text>
        {/* <Text secondary subheader1>
          {activity?.description}
        </Text> */}
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mt={2}
          width="100%"
        >
          <Box alignItems="center" display="flex" gap={0.5}>
            <Text body3>Points:</Text>
            <StarIcon color="warning" sx={{ width: '12px', height: '12px' }} />
            <Text body3>{activity.pointsCount}</Text>
          </Box>
          {verificationQuestStatus &&
            renderVerificationStatus(verificationQuestStatus)}
        </Box>
      </Box>
    </ActivityWrapper>
  )
}

export default TwitterActivity
