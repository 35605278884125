import React, { useCallback, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { NewsModal } from 'components/blocks/modals'
import Text from 'components/ui/Text'
import { NewsArticle } from 'graphql/news/types'
import useNewsList from 'hooks/useNewsList'
import { getDate } from 'utils/date'

import { NewsCard } from './styles'

function NewsList() {
  const { newsList, loadMore, count, loading, error } = useNewsList({
    take: 5,
    order: 'DESC',
  })

  const [newsModal, setNewsModal] = useState<{
    isOpen: boolean
    entity: NewsArticle | null
  }>({ isOpen: false, entity: null })

  const handleOpenNewsModal = useCallback((newsArticle: NewsArticle) => {
    setNewsModal({ isOpen: true, entity: newsArticle })
  }, [])
  const handleCloseNewsModal = useCallback(() => {
    setNewsModal({ isOpen: false, entity: null })
  }, [])

  const loaded = !loading && !error
  const noPublishedNews = loaded && !newsList?.length

  const renderFooter = useCallback(() => {
    if (count === newsList?.length) return null

    return (
      <Box display="flex" justifyContent="center" py={4}>
        <CircularProgress size={25} />
      </Box>
    )
  }, [count, newsList])

  const renderItemContent = useCallback(
    (index: number, newsArticle: NewsArticle) => (
      <NewsCard
        display="flex"
        flexDirection="column"
        key={newsArticle.id}
        mt={1.5}
        p={2}
        onClick={() => handleOpenNewsModal(newsArticle)}
      >
        <Text body3 mb={1}>
          {getDate(newsArticle.createdAt)}
        </Text>
        <Text body2 ellipsis mb={1}>
          {newsArticle.title}
        </Text>
        <Text body4 ellipsis mb={1}>
          {newsArticle.content}
        </Text>
      </NewsCard>
    ),
    [handleOpenNewsModal],
  )

  return (
    <>
      {noPublishedNews && (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <Text>No available announcements</Text>
        </Box>
      )}
      <Virtuoso
        components={{
          Footer: renderFooter,
        }}
        data={newsList}
        endReached={loadMore}
        itemContent={renderItemContent}
        style={{ height: '100%' }}
        totalCount={count}
      />
      <NewsModal
        isOpen={newsModal.isOpen}
        news={newsModal.entity}
        onClose={handleCloseNewsModal}
      />
    </>
  )
}

export default NewsList
