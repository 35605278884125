import React, { useContext } from 'react'

import { Box } from '@mui/material'
import LogoDM from 'assets/icons/main-logo_icon_dark.svg'
import LogoLM from 'assets/icons/main-logo_icon_light.svg'
import { ThemeContext } from 'context/themeMode'

function LogoLoader() {
  const mode = useContext(ThemeContext)

  const isDarkMode = mode.mode === 'dark'
  const Logo = isDarkMode ? LogoDM : LogoLM

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <img alt={'logo'} src={Logo} />
    </Box>
  )
}

export default LogoLoader
