import { gql } from '@apollo/client'
import { PHASE_FIELDS } from 'graphql/phase/fragments/phase'

export const LEADERBOARD_FIELDS = gql`
  fragment LeaderboardFields on Leaderboard {
    phase {
      ...PhaseFields
    }
    totalPoints
    validatorName
    validatorId
  }
  ${PHASE_FIELDS}
`
