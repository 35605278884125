import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const NewsCard = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 8px;
  text-align: left;
  margin-top: 12px;
  cursor: pointer;

  p {
    color: white;
  }
`
