import React, { useContext, useMemo } from 'react'
import { Chart } from 'react-google-charts'
import { useResizeDetector } from 'react-resize-detector'

import { useQuery } from '@apollo/client'
import { Box } from '@mui/system'
import { Loader } from 'components/loader'
import Text from 'components/ui/Text'
import { ThemeContext } from 'context/themeMode'
import { VALIDATIONS_PER_DAY_BY_NODE_ID } from 'graphql/validationsPerDayByNodeId/queries'
import { createValidationsChartOptions } from 'utils/createChartOptions'
import { generateValidationsChartData } from 'utils/validationsChart'

import { ChartContainer } from './styles'

interface Props {
  nodeId?: string
}

function ValidationsByNodeChart({ nodeId }: Props) {
  const { mode } = useContext(ThemeContext)

  const validationsChartOptions = createValidationsChartOptions()

  const { ref: resizeRef, width: resizeWidth } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  const { data, loading, error } = useQuery(VALIDATIONS_PER_DAY_BY_NODE_ID, {
    variables: { nodeId },
    skip: !nodeId,
  })

  const totalValidations = useMemo(
    () =>
      data?.validationsPerDayByNodeIdGraphData?.total
        ? data?.validationsPerDayByNodeIdGraphData?.total?.toLocaleString()
        : 0,
    [data],
  )

  const validationChartData = useMemo(() => {
    if (data?.validationsPerDayByNodeIdGraphData.items?.length) {
      return generateValidationsChartData(
        data.validationsPerDayByNodeIdGraphData.items,
        mode,
      )
    }
    return []
  }, [mode, data])

  const noChartData =
    data?.validationsPerDayByNodeIdGraphData?.items?.length < 2

  if (!nodeId) return null

  return (
    <ChartContainer height="308px" p={2} ref={resizeRef}>
      <Box display="flex" flexDirection="column" gap={0.5} m={2} mb={0}>
        <Text body2>Validations made</Text>
        <Text secondary subheader1>
          Number of all validations made througout staking term
        </Text>
        {!loading && !error && (
          <Text sx={{ mt: 1.5 }} title2>
            {totalValidations}
          </Text>
        )}
      </Box>

      {loading && !error && (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <Loader
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        </Box>
      )}
      {!loading && error && (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          mx={2}
          overflow="hidden"
          width="100%"
        >
          <Text>Something went wrong</Text>
        </Box>
      )}

      {!loading && !error && (
        <>
          {noChartData ? (
            <Box
              alignItems="center"
              display="flex"
              height="100%"
              mx={2}
              width="100%"
            >
              <Text>Data will be available in a day</Text>
            </Box>
          ) : (
            <Box height="100%" width={resizeWidth || '100%'}>
              <Chart
                chartType="AreaChart"
                data={validationChartData}
                height="100%"
                options={validationsChartOptions}
                width="100%"
              />
            </Box>
          )}
        </>
      )}
    </ChartContainer>
  )
}

export default React.memo(ValidationsByNodeChart)
