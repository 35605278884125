import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'

import { ReactComponent as WaveDM } from 'assets/icons/wave_top.svg'
import { ReactComponent as WaveLM } from 'assets/icons/wave_top_lm.svg'
import { ThemeContext } from 'context/themeMode'

import { ContentBox, MainContainer, WaveStyled } from './styles'

function Wave2Layout() {
  const mode = useContext(ThemeContext)
  const isDarkMode = mode.mode === 'dark'
  const WaveComponent = isDarkMode ? WaveDM : WaveLM

  return (
    <MainContainer>
      <WaveStyled as={WaveComponent} />
      <ContentBox px={4}>
        <Outlet />
      </ContentBox>
    </MainContainer>
  )
}

export default Wave2Layout
