interface ChainDetails {
  name: string
  rpc: string
  explorer: string
}

export function getChainDetails(chainId: number): ChainDetails | null {
  switch (chainId) {
    case 137:
      return {
        name: 'Polygon Mainnet',
        rpc: 'https://polygon-rpc.com/',
        explorer: 'https://polygonscan.com/',
      }
    case 80002:
      return {
        name: 'Amoy',
        rpc: 'https://rpc-amoy.polygon.technology/',
        explorer: 'https://amoy.polygonscan.com/',
      }
    default:
      return null
  }
}
