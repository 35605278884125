import React, { useMemo } from 'react'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Box } from '@mui/material'
import Text from 'components/ui/Text'
import { getQuestStatus } from 'utils/quest'

interface Props {
  expirationDate: string
}

function ExpirationMessage({ expirationDate }: Props) {
  const { text, expired } = useMemo(
    () => getQuestStatus(expirationDate),
    [expirationDate],
  )

  return (
    <Box alignItems="center" display="flex" gap={0.75}>
      {!expired && (
        <AccessTimeIcon
          color="primary"
          sx={{ width: '12px', height: '12px' }}
        />
      )}
      <Text body3 error={expired} primary={!expired}>
        {text}
      </Text>
    </Box>
  )
}

export default ExpirationMessage
