import { isAndroid, isIOS, isMobile } from 'react-device-detect'

function useStandalone() {
  if (!isMobile) return false

  if (isIOS) {
    // @ts-ignore
    return 'standalone' in window.navigator && !!window.navigator.standalone
  }

  if (isAndroid)
    return (
      'matchMedia' in window &&
      window.matchMedia('(display-mode: standalone)').matches
    )

  return false
}

export default useStandalone
