import React, { useContext, useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { Box } from '@mui/system'
import Text from 'components/ui/Text'
import { ThemeContext } from 'context/themeMode'
import { Point } from 'types/point'
import {
  createBridgedChartOptions,
  generateBridgetChartData,
} from 'utils/bridgetChart'

interface Props {
  chartData?: Point[]
  diff?: Point[]
  width?: string | number
}
function BridgedTokenChart({ chartData = [], diff = [], width }: Props) {
  const theme = useContext(ThemeContext)

  const chartOptions = createBridgedChartOptions()

  const statsChartData = useMemo(
    () =>
      chartData?.length && diff?.length
        ? generateBridgetChartData(chartData, diff, theme.mode)
        : [],
    [chartData, diff, theme],
  )

  if (chartData.length <= 1)
    return (
      <Box alignItems="center" display="flex" flex={1} p={2}>
        <Text secondary>No available data</Text>
      </Box>
    )

  return (
    <Box height="220px" width={width || '100%'}>
      <Chart
        chartType="AreaChart"
        data={statsChartData}
        height="100%"
        options={chartOptions}
        width="100%"
      />
    </Box>
  )
}

export default BridgedTokenChart
