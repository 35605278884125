import { Link } from 'react-router-dom'

import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const HeaderButtonsWrapper = styled(Box)(() => ({
  paddingTop: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const HeaderTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  fontSize: '24px',
  fontWeight: '500',
}))

export const HeaderSeeAllLink = styled(Link)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  fontSize: '14px',
  fontWeight: '500',
  textDecoration: 'none',
}))

export const NodeTableWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 0.5fr;
  color: #444;
  background-color: ${({ theme }) => theme.palette.colors.bg.default};
  grid-row-gap: 16px;
  min-width: 600px;
  overflow-x: auto;
`

export const NodeTableHeader = styled(Box)`
  background-color: ${({ theme }) => theme.palette.colors.bg.default};
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 0.55fr 2.7fr 0.65fr 0.65fr 0.45fr;
  padding-bottom: 8px;
  text-align: initial;
  color: ${({ theme }) => theme.palette.colors.font.secondary};
  line-height: 24px;
`
