import { createContext } from 'react'

import { BigNumber, ethers } from 'ethers'

interface UserBalance {
  balance: BigNumber
  setBalance: (balance: BigNumber) => void
  updating: boolean
  setUpdating: (updating: boolean) => void
}

export const UserBalanceContext = createContext<UserBalance>({
  balance: ethers.utils.parseEther('0'),
  setBalance: () => {},
  updating: true,
  setUpdating: () => {},
})
