import React from 'react'

import Text from 'components/ui/Text'

import { LogoWrapper } from './styles'

function LogoName() {
  return (
    <LogoWrapper>
      <Text as="span" sx={{ fontWeight: '600' }}>
        Morpheus.
      </Text>
      <Text as="span" sx={{ fontWeight: '200' }}>
        Network
      </Text>
    </LogoWrapper>
  )
}

export default LogoName
