import React from 'react'

export enum Mode {
  DARK = 'dark',
  LIGHT = 'light',
}

export const ThemeContext = React.createContext({
  toggleColorMode() {},
  mode: Mode.DARK,
})
