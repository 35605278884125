import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { LogoLoader } from 'components/logoLoader'
import { AuthorizedContext } from 'context/authorizedContext'
import { useAuth } from 'hooks/useAuth'
import { Routes } from 'router/routes'
import { setHash } from 'services/hash'

const ConfirmLoginPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setAuthorized } = useContext(AuthorizedContext)

  const urlParams = new URLSearchParams(location.search)
  const token: string = urlParams.get('token') as string
  const { data } = useAuth(token)

  useEffect(() => {
    if (data) {
      setHash(token)
      navigate(Routes.ROOT)
      setAuthorized(true)
    }
  }, [data])

  return <LogoLoader />
}

export default ConfirmLoginPage
