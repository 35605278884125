import { gql } from '@apollo/client'

export const VALIDATIONS_PER_DAY = gql`
  fragment ValidationsPerDayFields on ValidationsPerDay {
    amount
    day
    reward
  }
`

export const VALIDATIONS_PER_DAY_BY_NODE_ID_GRAPH_DATA = gql`
  fragment ValidationsPerDayByNodeIdGraphDataFields on ValidationsPerDayByNodeIdGraphData {
    total
    items {
      ...ValidationsPerDayFields
    }
  }
  ${VALIDATIONS_PER_DAY}
`
