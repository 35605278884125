import React from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { NodeData } from 'graphql/nodes/types'
import { useResponsiveLayout } from 'hooks'
import { UptimePoint } from 'types/point'

import Charts from './Charts'
import NodeInfo from './NodeInfo'
import Reward from './Reward'
import { ChartsContainer, Wrapper } from './styles'

interface Props {
  address: string
  isOnboarding?: boolean
  loadingUptimeData?: boolean
  nodeData: NodeData
  totalUptimePercentage?: number
  uptime?: string
  uptimeChartPoints?: UptimePoint[]
  onRefetchNode: (variables?: any) => Promise<any>
}

function DetailNodeColumns({
  address,
  isOnboarding,
  loadingUptimeData,
  nodeData,
  totalUptimePercentage = 0,
  uptime,
  uptimeChartPoints,
  onRefetchNode,
}: Props) {
  const { isLargeDesktop } = useResponsiveLayout()

  const { ref: resizeRef, width: resizeWidth } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  return (
    <Wrapper gridTemplateColumns={!isLargeDesktop ? '1fr' : '1fr 2.06fr'}>
      <NodeInfo
        address={address}
        nodeData={nodeData}
        totalUptimePercentage={totalUptimePercentage}
        onRefetchNode={onRefetchNode}
      />
      <ChartsContainer ref={resizeRef}>
        <Charts
          isOnboarding={isOnboarding}
          loadingUptimeData={loadingUptimeData}
          totalUptimePercentage={totalUptimePercentage}
          uptime={uptime}
          uptimeChartPoints={uptimeChartPoints}
          // note: resolve problem with resize google-chart
          uptimeChartWidth={resizeWidth}
        />
        <Reward
          closeAt={nodeData?.closesAt}
          createdAt={nodeData?.createdAt}
          earnedRewards={nodeData?.earnedRewards}
          penalty={nodeData?.penalty}
          period={nodeData?.period}
          projectedRewards={nodeData?.projectedRewards}
          reward={nodeData?.reward}
          stakedAmount={nodeData.stakedAmount}
          status={nodeData?.status}
        />
      </ChartsContainer>
    </Wrapper>
  )
}

export default DetailNodeColumns
