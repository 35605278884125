import { Tab } from '@mui/material'
import { styled } from '@mui/system'

interface PlatformTabProps {
  selected?: boolean
}
export const PlatformTab = styled(Tab)<PlatformTabProps>`
  padding-bottom: 0;
  padding-top: 0;
  svg {
   rect, path {
     fill: ${({ selected, theme }) =>
       selected
         ? theme.palette.primary.main
         : theme.palette.colors.font.default}
      
    }
    }
  }
   
`
