import { TableContainer } from '@mui/material'
import { styled } from '@mui/system'

export const LeaderboardTableContainer = styled(TableContainer)`
  overflow-x: auto;
  table {
    border-collapse: separate;
    border-spacing: 0 8px;
  }
  th {
    top: 8px;
    padding-top: 4px;
    color: ${({ theme }) => theme.palette.colors.font.secondary};
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.palette.colors.border.bright}`};
    white-space: nowrap;
    background-color: ${({ theme }) => theme.palette.colors.bg.default};
  }
  td {
    border: none;
  }
  tbody {
    tr td:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    tr td:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &::after {
    content: '';
    display: block;
    height: 8px;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.colors.bg.default};
    position: absolute;
    top: 0;
  }
`

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`
