import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ScheduleIcon from '@mui/icons-material/Schedule'
import StarIcon from '@mui/icons-material/Star'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactComponent as Checklist } from 'assets/icons/checklist.svg'
import Text from 'components/ui/Text'
import {
  QUEST_ACTIVITIES_COUNT,
  QUEST_ACTIVITIES_POINTS,
} from 'constants/quest'
import { useCurrentPhaseContext } from 'context/phaseContext'
import { QUEST_BY_PHASE_ID_STATS } from 'graphql/quests/queries'
import { phaseDaysLeft } from 'utils/phase'

import { BorderLinearProgress } from './styles'

function PhaseProgress() {
  const { currentPhase } = useCurrentPhaseContext()

  const { data, loading } = useQuery(QUEST_BY_PHASE_ID_STATS, {
    variables: {
      phaseId: currentPhase?.id!,
    },
    skip: !currentPhase?.id,
  })

  const completedDailyQuestCount = useMemo(
    () => data?.questByPhaseIdStats?.completedQuests || 0,
    [data],
  )
  const publishedDailyQuestCount = useMemo(
    () => data?.questByPhaseIdStats?.publishedQuests || 0,
    [data],
  )

  const completedQuestsPercentage = useMemo(() => {
    if (!completedDailyQuestCount || !publishedDailyQuestCount) {
      return 0
    }
    return Math.round(
      (completedDailyQuestCount / publishedDailyQuestCount) * 100,
    )
  }, [completedDailyQuestCount, publishedDailyQuestCount])

  const daysLeft = useMemo(
    () => phaseDaysLeft(currentPhase?.endDate),
    [currentPhase],
  )

  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <CircularProgress size={20} />
      </Box>
    )
  }

  if (!currentPhase?.id)
    return (
      <Box my={2}>
        <Text secondary sx={{ textAlign: 'left' }}>
          <em>There is no active phase at the moment</em>
        </Text>
      </Box>
    )
  return (
    <Box>
      <Text body2 sx={{ fontWeight: 700 }}>
        Phase Progress
      </Text>
      <Box display="flex" gap={1} mt={1.5}>
        <Checklist />
        <Box alignItems="center" display="flex" gap={0.25}>
          <Text sx={{ fontSize: '16px' }}>{completedQuestsPercentage}%</Text>
          <Text body3 secondary>
            {`(${completedDailyQuestCount} / ${publishedDailyQuestCount})`}
          </Text>
        </Box>
      </Box>
      <BorderLinearProgress
        sx={{ my: 3 }}
        value={completedQuestsPercentage}
        variant="determinate"
      />
      <Box display="flex" flexDirection="column" gap={1.5}>
        <Box
          alignItems="center"
          display="flex"
          gap={1}
          justifyContent="space-between"
        >
          <Box alignItems="center" display="flex" gap={1}>
            <FavoriteIcon
              color="error"
              sx={{ width: '16px', height: '16px' }}
            />
            <Text title4>Activities verified:</Text>
          </Box>

          <Text body4 secondary>{`${
            completedDailyQuestCount * QUEST_ACTIVITIES_COUNT
          } / ${publishedDailyQuestCount * QUEST_ACTIVITIES_COUNT}`}</Text>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          gap={1}
          justifyContent="space-between"
        >
          <Box alignItems="center" display="flex" gap={1}>
            <StarIcon color="warning" sx={{ width: '16px', height: '16px' }} />
            <Text title4>Points earned:</Text>
          </Box>

          <Text body4 secondary>{`${
            completedDailyQuestCount * QUEST_ACTIVITIES_POINTS
          } / ${publishedDailyQuestCount * QUEST_ACTIVITIES_POINTS}`}</Text>
        </Box>

        <Box
          alignItems="center"
          display="flex"
          gap={1}
          justifyContent="space-between"
        >
          <Box alignItems="center" display="flex" gap={1}>
            <ScheduleIcon
              color="secondary"
              sx={{ width: '16px', height: '16px' }}
            />
            <Text title4>Days left:</Text>
          </Box>

          <Text body4 secondary>
            {daysLeft}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default PhaseProgress
