import { createTheme } from '@mui/material'

import palette from 'Theme/palette'
import themes from 'Theme/themes'

const theme = (mode: 'light' | 'dark') => {
  const { light, dark } = themes
  return createTheme({
    palette: {
      mode,
      primary: {
        main: palette.primary,
      },
      secondary: {
        main: palette.lightBlue,
      },
      success: {
        main: palette.positive,
      },
      warning: {
        main: palette.warning,
      },
      error: {
        main: palette.error,
      },
      ...(mode === 'light' ? light() : dark()),
    },
    typography: {
      fontFamily: ['Archivo', 'sans-serif'].join(','),
    },
  })
}

export default theme
