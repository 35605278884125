import React from 'react'

import s from './loader.module.css'

interface Props {
  style?: any
}
// todo fix loader style dark theme!!!
const Loader: React.FC<Props> = ({ style }: Props) => (
  <div className={s.custom_loader_spinner} style={style}>
    Loading...
  </div>
)

export default Loader
