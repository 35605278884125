import React from 'react'
import ReactDOM from 'react-dom/client'

import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Web3ReactProvider } from '@web3-react/core'
import BrowserRouter from 'components/CustomBrowserRoute/CustomBrowserRoute'
import createApolloClient from 'graphql/client'
import history from 'utils/historyConfig'
import { getLibrary } from 'utils/web3Config'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './index.css'

Sentry.init({
  dsn: 'https://a32e97517e594dce81976603928c8882@o4504360929329152.ingest.sentry.io/4504360931033088',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'Cannot read properties of null',
    'null has no properties',
    'null is not an object',
    'call revert exception',
    'missing revert data in call exception',
    'n is null',
    'api.worklair.io',
  ],
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <BrowserRouter history={history}>
    <ApolloProvider client={createApolloClient()}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </ApolloProvider>
  </BrowserRouter>,
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
