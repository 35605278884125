import React from 'react'

import LensIcon from '@mui/icons-material/Lens'
import Text from 'components/ui/Text'
import { getFormattedNumber } from 'constants/number'

import { ChartPieTooltip } from './styles'

interface ItemData {
  dataIndex: number
}

interface SeriesData {
  color: string
  label: string
  value: number
}

interface ChartSeries {
  data: SeriesData[]
}

interface CustomItemTooltipContentProps {
  itemData: ItemData
  series: ChartSeries
}
function CustomMuiPieChartTooltip({
  itemData,
  series,
}: CustomItemTooltipContentProps) {
  const item = series?.data?.[itemData?.dataIndex]

  if (!item) return null

  return (
    <ChartPieTooltip>
      {item?.color && <LensIcon sx={{ fontSize: '8px', fill: item.color }} />}

      <Text sx={{ fontWeight: 600 }}>{item.label}: </Text>
      <Text>{getFormattedNumber(item.value)}</Text>
    </ChartPieTooltip>
  )
}

export default CustomMuiPieChartTooltip
