import React, { ReactNode, useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import InstallMobileOutlinedIcon from '@mui/icons-material/InstallMobileOutlined'
import LightModeIcon from '@mui/icons-material/LightMode'
import Logout from '@mui/icons-material/Logout'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import { ThemeContext } from 'context/themeMode'
import useLogout from 'hooks/useLogout'
import { Routes } from 'router/routes'
import EventBus from 'services/eventBus'

enum NavItemId {
  Leaderboard = 'Leaderboard',
  Instruction = 'Instruction',
  LightPaper = 'LightPaper',
  CommunityFeed = 'CommunityFeed',
  Settings = 'Settings',
  UniversalApp = 'UniversalApp',
  ColorMode = 'ColorMode',
  Logout = 'Logout',
}

type NavItem = {
  action?: () => void
  caption: string
  icon: ReactNode
  id: NavItemId
  shouldMenuClose?: boolean
}

export default function useNavigation() {
  const navigate = useNavigate()
  const { mode, toggleColorMode } = useContext(ThemeContext)
  const logout = useLogout()

  const handleGoTo = useCallback((link: string) => navigate(link), [navigate])

  const handleOpenHomeScreenGuide = useCallback(() => {
    EventBus.trigger(EventBus.actions.modals.showHomeScreenGuide, null)
  }, [])

  const navItems: Record<NavItemId, NavItem> = useMemo(
    () => ({
      [NavItemId.Leaderboard]: {
        id: NavItemId.Leaderboard,
        caption: 'Leaderboard',
        icon: <GroupOutlinedIcon color="secondary" />,
        shouldMenuClose: true,
        action: () => handleGoTo(Routes.LEADERBOARD),
      },

      [NavItemId.Instruction]: {
        id: NavItemId.Instruction,
        caption: 'Instructions',
        icon: <MenuBookIcon color="secondary" fontSize="small" />,
        shouldMenuClose: true,
        action: () => handleGoTo(Routes.INSTRUCTION_PAGE),
      },

      [NavItemId.LightPaper]: {
        id: NavItemId.LightPaper,
        caption: 'Lightpaper',
        icon: <UploadFileOutlinedIcon color="secondary" fontSize="small" />,
        action: () => {
          const link = document.createElement('a')
          link.href = '/files/TrustSupply_Masternodes_Lightpaper.pdf'
          link.download = 'TrustSupply_Masternodes_Lightpaper.pdf'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        },
      },

      [NavItemId.Settings]: {
        id: NavItemId.Settings,
        caption: 'Settings',
        icon: <SettingsOutlinedIcon color="secondary" fontSize="small" />,
        shouldMenuClose: true,
        action: () => handleGoTo(Routes.SETTINGS),
      },
      [NavItemId.UniversalApp]: {
        id: NavItemId.UniversalApp,
        caption: 'Universal App',
        icon: <InstallMobileOutlinedIcon color="secondary" fontSize="small" />,
        shouldMenuClose: true,
        action: handleOpenHomeScreenGuide,
      },

      [NavItemId.CommunityFeed]: {
        id: NavItemId.CommunityFeed,
        caption: 'Community feed',
        icon: <RssFeedIcon color="secondary" fontSize="small" />,
        shouldMenuClose: true,
      },

      [NavItemId.ColorMode]: {
        id: NavItemId.ColorMode,
        caption: mode === 'dark' ? 'Dark theme' : 'Light theme',
        icon:
          mode === 'dark' ? (
            <DarkModeOutlinedIcon color="secondary" fontSize="small" />
          ) : (
            <LightModeIcon color="warning" fontSize="small" />
          ),
        action: toggleColorMode,
      },

      [NavItemId.Logout]: {
        id: NavItemId.Logout,
        caption: 'Logout',
        icon: <Logout color="secondary" fontSize="small" />,
        shouldMenuClose: true,
        action: logout,
      },
    }),
    [handleGoTo, handleOpenHomeScreenGuide, logout, mode, toggleColorMode],
  )

  return { navItems }
}
