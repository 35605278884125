import { Box, css, TableRowProps } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/system'

interface ExtendedProps {
  theme?: any
  active?: boolean
}

const activeCss = ({ active, theme }: ExtendedProps) =>
  active &&
  css`
    background-color: ${theme.palette.colors.bg.blue};
  `

type Props = ExtendedProps & TableRowProps

export const ValidatorTableRow = styled(TableRow)<Props>`
  background-color: ${({ theme }) => theme.palette.colors.bg.secondary};
  border-radius: 8px;
  text-align: initial;
  color: ${({ theme }) => theme.palette.colors.font.default};

  td {
    border: none;
  }

  ${activeCss}
`

export const PointsContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: end;
  p {
    color: ${({ theme }) => theme.palette.buttons.hover.border.color.primary};
  }

  svg {
    fill: ${({ theme }) => theme.palette.buttons.hover.border.color.primary};
    height: 12px;
    width: 12px;
  }
`
