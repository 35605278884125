import React, { Dispatch, SetStateAction } from 'react'

import CustomModal from 'components/modal/customModal'
import { Quest } from 'graphql/quests/types'
import { useResponsiveLayout } from 'hooks'

import QuestRightPanel from '../../../questRightPanel'
import QuestActivityForm from '../../questAcivityForm'

type QuestModalState = {
  isOpen: boolean
  entity: Quest | null
}

interface Props {
  isOpen?: boolean
  quest?: Quest | null
  onClose?: () => void
  onUpdateQuest?: Dispatch<SetStateAction<QuestModalState>>
  onUpdatePublishedQuestList?: Dispatch<SetStateAction<Quest[]>>
}

function QuestModal({
  isOpen,
  quest,
  onClose,
  onUpdateQuest,
  onUpdatePublishedQuestList,
}: Props) {
  const { isMobile } = useResponsiveLayout()
  if (!quest) return null

  if (isMobile) {
    return (
      <QuestRightPanel
        isOpen={isOpen}
        quest={quest}
        onClose={onClose}
        onUpdatePublishedQuestList={onUpdatePublishedQuestList}
        onUpdateQuest={onUpdateQuest}
      />
    )
  }
  return (
    <CustomModal
      handleClose={onClose}
      open={isOpen}
      sx={{ width: '80%', overflow: 'hidden', padding: 0 }}
    >
      <QuestActivityForm
        quest={quest}
        onUpdatePublishedQuestList={onUpdatePublishedQuestList}
        onUpdateQuest={onUpdateQuest}
      />
    </CustomModal>
  )
}

export default QuestModal
