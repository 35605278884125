import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const TitlePopupWrapper = styled(Box)(({ theme }) => ({
  padding: '20px',
  color: theme.palette.mode === 'dark' ? '#697A97' : '#7A89A6',
  background:
    theme.palette.mode === 'dark' ? '#000021' : 'rgba(126, 124, 156, 0.1)',
  borderColor: `8px solid ${
    theme.palette.mode === 'dark' ? '#000021' : 'rgba(126, 124, 156, 0.1)'
  }`,
}))
