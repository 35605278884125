import React from 'react'
import { Link } from 'react-router-dom'

import { Box } from '@mui/material'
import { ReactComponent as HeaderLogo } from 'assets/icons/main_logo.svg'
import DesktopMenu from 'components/header/DesktopMenu'
import MobileMenu from 'components/header/MobileMenu'
import LogoName from 'components/LogoName'
import { useResponsiveLayout } from 'hooks'
import { Routes } from 'router/routes'

function Header() {
  const { isLargeDesktop } = useResponsiveLayout()

  return (
    <>
      <Box alignItems="center" display="flex" gap={1.5} sx={{ flexGrow: 1 }}>
        <Link to={Routes.ROOT}>
          <HeaderLogo />
        </Link>
        <LogoName />
      </Box>

      {isLargeDesktop ? <DesktopMenu /> : <MobileMenu />}
    </>
  )
}

export default Header
