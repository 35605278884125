import React from 'react'
import { useLocation } from 'react-router-dom'

import { STAGING_BANNER_HEIGHT } from 'constants/params'
import { useResponsiveLayout } from 'hooks'
import { Routes } from 'router/routes'

import { EnvironmentBannerWrapper } from './styles'

function EnvironmentBanner() {
  const location = useLocation()
  const { isMobile } = useResponsiveLayout()

  if (location.pathname === Routes.MONTHLY_REPORT) return null

  return (
    <EnvironmentBannerWrapper
      severity="error"
      sx={{ height: STAGING_BANNER_HEIGHT, px: isMobile ? 2 : 4 }}
    >
      STAGING ENVIRONMENT
    </EnvironmentBannerWrapper>
  )
}

export default EnvironmentBanner
