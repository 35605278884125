import React, { useCallback, useState } from 'react'

import { CustomModal } from 'components/modal'

import NodesUpstakeForm from './nodesUpstakeForm'
import NodesUpstakeTerms from './nodesUpstakeTerms'

type Props = {
  stakedNodes: number
  isOpen?: boolean
  onClose?: () => void
}

function UpstakeModal({ isOpen = false, stakedNodes, onClose }: Props) {
  const [step, setStep] = useState<number>(0)

  const handleClose = useCallback(() => {
    setStep(1)
    onClose?.()
  }, [onClose])

  const handleStep = useCallback((nextStep: number) => {
    setStep(nextStep)
  }, [])

  return (
    <CustomModal
      handleClose={handleClose}
      open={isOpen}
      sx={{
        padding: '56px 48px 40px',
        maxWidth: '650px',
        width: '90%',
        maxHeight: '90%',
      }}
    >
      {step === 0 && (
        <NodesUpstakeTerms onClose={handleClose} onStep={handleStep} />
      )}
      {step === 1 && (
        <NodesUpstakeForm stakedNodes={stakedNodes} onClose={handleClose} />
      )}
    </CustomModal>
  )
}

export default UpstakeModal
