import React, { useCallback, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { AppBar, CssBaseline, Drawer, Toolbar } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar'
import { Box, styled } from '@mui/system'
import { CommunityFeed } from 'components/blocks/communityFeed'
import EnvironmentBanner from 'components/EnvironmentBanner'
import Footer from 'components/Footer'
import { Header } from 'components/header'
import UnsupportedChainBanner from 'components/UnsupportedChainBanner'
import { IS_STAGING } from 'config'
import {
  COMMUNITY_FEED_WIDTH,
  HEADER_HEIGHT,
  STAGING_BANNER_HEIGHT,
  UNSUPPORTED_CHAIN_BANNER_HEIGHT,
} from 'constants/params'
import { useBannerPlaceholder, useResponsiveLayout } from 'hooks'
import useWeb3 from 'hooks/useWeb3'
import { Routes } from 'router/routes'

import { ContentBox, MainContainer, StickyButton } from './styles'

interface CustomAppBarProps extends MuiAppBarProps {
  top?: number
  drawerWidth?: number
  theme?: any
  open?: boolean
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<CustomAppBarProps>(
  ({ theme, open, drawerWidth = COMMUNITY_FEED_WIDTH, top = 0 }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${COMMUNITY_FEED_WIDTH}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: `${drawerWidth}px`,
    }),
    top,
    left: 0,
    boxShadow: 'none',
    flexGrow: 1,
    backgroundColor: theme.palette.colors.bg.default,
    backgroundImage: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
)

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  theme?: any
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.colors.bg.default,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -COMMUNITY_FEED_WIDTH,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    width: '100%',
  }),
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: 'relative',
  // padding: '0 32px 32px',
}))

function MainLayout() {
  const location = useLocation()
  const { isUnsupportedChain, switchChain } = useWeb3()
  const { isLargeDesktop } = useResponsiveLayout()
  const bannerPlaceholder = useBannerPlaceholder()

  const [openDrawer, setOpenDrawer] = useState(isLargeDesktop)

  const handleDrawerOpen = useCallback(() => {
    setOpenDrawer(true)
  }, [])

  const handleDrawerClose = useCallback(() => {
    setOpenDrawer(false)
  }, [])

  const isMainMenuVisible = useMemo(
    () =>
      location?.pathname !== Routes.TERMS_PAGE &&
      location?.pathname !== Routes.MONTHLY_REPORT,
    [location],
  )

  const topHeaderAppBar = useMemo(() => {
    let top = 0

    if (isUnsupportedChain) {
      top += UNSUPPORTED_CHAIN_BANNER_HEIGHT
    }

    if (IS_STAGING) {
      top += STAGING_BANNER_HEIGHT
    }

    return top
  }, [isUnsupportedChain])

  return (
    <MainContainer>
      {isLargeDesktop && (
        <StickyButton
          endIcon={<KeyboardArrowUpIcon />}
          size="small"
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '16px',
          }}
          variant="contained"
          onClick={handleDrawerOpen}
        >
          Community feed
        </StickyButton>
      )}

      <>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
        >
          {IS_STAGING && location.pathname !== Routes.MONTHLY_REPORT && (
            <EnvironmentBanner />
          )}

          {isUnsupportedChain && (
            <UnsupportedChainBanner onSwitchChain={switchChain} />
          )}
        </AppBar>

        {bannerPlaceholder}
      </>
      <Box display="flex" flexGrow="1">
        <Main open={openDrawer}>
          {isMainMenuVisible && (
            <>
              <StyledAppBar
                drawerWidth={COMMUNITY_FEED_WIDTH}
                open={openDrawer}
                top={topHeaderAppBar}
              >
                <Toolbar
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    sx={{
                      maxWidth: '1080px',
                      height: HEADER_HEIGHT,
                    }}
                    width="100%"
                  >
                    <Header />
                  </Box>
                </Toolbar>
              </StyledAppBar>
              <Toolbar sx={{ height: HEADER_HEIGHT }} variant="dense" />
            </>
          )}
          <ContentBox>
            <Outlet />
          </ContentBox>
          <Footer />
        </Main>

        <Drawer
          anchor="right"
          open={openDrawer}
          sx={{
            width: COMMUNITY_FEED_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: COMMUNITY_FEED_WIDTH,
            },
          }}
          variant="persistent"
        >
          {bannerPlaceholder}

          {isLargeDesktop && (
            <CommunityFeed onCloseCommunityFeed={handleDrawerClose} />
          )}
        </Drawer>
      </Box>
    </MainContainer>
  )
}

export default MainLayout
