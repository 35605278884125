import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const BlockWrapper = styled(Box)`
  margin: 16px 0;
  border-radius: 6px;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.colors.font.default};
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.colors.border.default};
`

export const BlockHeader = styled(Box)`
  padding: 8px 12px 8px 16px;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.palette.colors.border.default};
  background-color: ${({ theme }) => theme.palette.colors.bg.secondary};
  color: ${({ theme }) => theme.palette.colors.font.secondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    fill: ${({ theme }) => theme.palette.colors.font.secondary};
  }
`
export const BlockCode = styled(Box)`
  border-radius: 0 0 6px 6px;
  margin: 0;
  padding: 20px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: initial;
`
