import { ReactNode } from 'react'

export enum VerificationQuestStatus {
  Pending = 'Pending',
  Failed = 'Failed',
  Completed = 'Completed',
}

export interface QuestActivity {
  icon?: ReactNode
  title: string
  description?: string
  pointsCount: number
  intentLink: string
}
