import { DateTime } from 'luxon'

interface QuestStatus {
  expired: boolean
  text: string
}

export const getQuestStatus = (expirationDate: string): QuestStatus => {
  const now = DateTime.now()
  const expiresAt = DateTime.fromISO(expirationDate)

  const formattedTime = expiresAt.toRelative({ base: now, locale: 'en' })

  if (expiresAt > now) {
    return { expired: false, text: `Expires ${formattedTime}` }
  }
  return { expired: true, text: `Expired - ${formattedTime}` }
}
