import { WEB3 } from 'config'
import { ethers } from 'ethers'

export function getLibrary(provider: any) {
  const library = new ethers.providers.Web3Provider(provider)

  library.pollingInterval = WEB3.POLLING_INTERVAL

  return library
}
