import { DateTime } from 'luxon'

export function formatDate(date: DateTime): String {
  return date.toLocaleString(DateTime.DATE_FULL, {
    locale: 'en',
  })
}

export function getDate(date?: string): String {
  return date ? formatDate(DateTime.fromISO(date)) : '--'
}

export function dateFormatWithSeconds(date: string): String {
  return date ? DateTime.fromISO(date).toFormat('MMM dd, yyyy hh:mm:ss') : '--'
}

export const getRemainingTime = (date?: string) => {
  if (!date) return ' '
  const now = DateTime.now()
  const expiresAt = DateTime.fromISO(date)

  return expiresAt.toRelative({ base: now, locale: 'en' })
}

export function date(date: string): String {
  return date ? DateTime.fromISO(date).toFormat('MMM dd, yyyy hh:mm:ss') : '--'
}
