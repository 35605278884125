import Menu from '@mui/material/Menu'
import { styled } from '@mui/system'

export const MenuContent = styled(Menu)`
  .MuiMenu-list {
    width: 200px;
    background-color: ${({ theme }) => theme.palette.colors.bg.dropdown};
    border-radius: 8px;
    .MuiMenuItem-root {
      padding: 10px 16px;
    }
  }
`
