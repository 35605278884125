import { InputBase } from '@mui/material'
import { styled } from '@mui/system'

// todo need update style by theme
const StyledInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  height: '56px',
  borderRadius: '8px',
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(229, 237, 253, 1)' : '#253566'
  }`,
  background: theme.palette.mode === 'light' ? '' : '#0D0D26',
  marginTop: '40px',
  padding: '16px',
  color: theme.palette.mode === 'light' ? 'rgba(0, 0, 33, 1)' : 'white',

  '& svg *': {
    stroke: theme.palette.mode === 'light' ? 'rgba(229, 237, 253, 1)' : '',
  },
  '&.Mui-focused': {
    border: '2px solid rgba(77, 117, 184, 1)',

    '& .MuiInputBase-input::placeholder': {
      color: 'rgba(13, 13, 38, 1)',
    },
  },
  '& .MuiInputBase-input::placeholder': {
    color:
      theme.palette.mode === 'light' ? 'rgba(185, 203, 238, 1)' : '#253566',
    fontSize: '16px',
  },
}))

export default StyledInput
