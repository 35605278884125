import { ValidationsPerDay } from 'graphql/validationsPerDayByNodeId/types'

import map from 'lodash/map'

import { generateValidationsTooltipBlock } from './createChartOptions'

export function generateValidationsChartData(
  points: ValidationsPerDay[],
  theme: 'light' | 'dark',
) {
  return [
    ['Date', 'Amount', { role: 'tooltip', type: 'string', p: { html: true } }],
    ...map(points, point => [
      new Date(point?.day),
      point?.amount,
      generateValidationsTooltipBlock(point, theme),
    ]),
  ]
}
