import { css, SelectProps } from '@mui/material'
import Select from '@mui/material/Select'
import { styled } from '@mui/system'

// todo need fix type for theme
interface CustomSelectProps {
  theme?: any
  pagination?: boolean
  form?: boolean
}

type Props = SelectProps & CustomSelectProps
const paginationCss = ({ pagination, theme }: CustomSelectProps) =>
  pagination &&
  css`
    color: ${theme.palette.colors.font.default};
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;

    div:first-of-type {
      min-height: auto;
    }

    svg {
      fill: ${theme.palette.colors.font.default};
    }
  `

const formCss = ({ form, theme }: CustomSelectProps) =>
  form &&
  css`
    color: ${theme.palette.colors.font.default};
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    height: 56px;
    border-radius: 8px;
    border: ${theme.palette.mode === 'light'
      ? '1px solid rgba(229, 237, 253, 1)'
      : '1px solid #253566'};
    background: ${theme.palette.mode === 'light' ? '' : '#0D0D26'};

    fieldset {
      border: none;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: ${theme.palette.colors.font.default};
    }
  `

export const StyledSelect = styled(Select)<Props>`
  background-color: transparent;
  color: ${({ theme }) => theme.palette.colors.font.secondary};
  svg {
    font-size: 18px;
    fill: ${({ theme }) => theme.palette.colors.font.secondary};
  }
  div:first-of-type:focus {
    background-color: transparent;
  }

  ${paginationCss}
  ${formCss}
`

export default StyledSelect
