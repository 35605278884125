const abbrev = ['K', 'M', 'B']

function rnd(n: number, precision: number) {
  const prec = 10 ** precision
  return Math.round(n * prec) / prec
}

export function formatTotal(totalAmount: number) {
  if (!totalAmount) return 0
  let base = Math.floor(Math.log(Math.abs(totalAmount)) / Math.log(1000))
  const suffix = abbrev[Math.min(abbrev.length - 1, base - 1)]
  base = abbrev.indexOf(suffix) + 1
  return suffix ? rnd(totalAmount / 1000 ** base, 2) + suffix : `${totalAmount}`
}
