import { DateTime } from 'luxon'

export function getDateForCharts(date?: string) {
  return date ? DateTime.fromISO(date).toFormat('LLL dd') : '__'
}

export const getRemainingTime = (closeAt?: string) => {
  if (!closeAt) return 0
  const now = DateTime.now()
  const stakingEnd = DateTime.fromISO(closeAt)

  return stakingEnd.toRelative({ base: now, locale: 'en' })
}
