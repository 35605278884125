import { gql } from '@apollo/client'

import { NODE_FIELDS } from './fragments/node'

export const EARLY_UNSTAKE = gql`
  mutation EarlyUnstake($nodeId: String!) {
    earlyUnstake(nodeId: $nodeId) {
      ok
    }
  }
`

export const UP_STAKE = gql`
  mutation Upstake($nodes: Float!) {
    upstake(nodes: $nodes) {
      ...NodeFields
    }
  }
  ${NODE_FIELDS}
`

export const REGISTER_VALIDATOR_NODE = gql`
  mutation RegisterValidatorNode($createNodeData: CreateNodeFields!) {
    registerValidatorNode(createNodeData: $createNodeData) {
      address
    }
  }
`
