import React, { useContext } from 'react'

import { ReactComponent as ArrowDownIconDM } from 'assets/icons/arrow_down_icon_dark.svg'
import { ReactComponent as ArrowDownIconLM } from 'assets/icons/arrow_down_icon_light.svg'
import { Mode, ThemeContext } from 'context/themeMode'

import { ArrowDownIconWrapper } from './styles'

function ArrowDown() {
  const { mode } = useContext(ThemeContext)

  const ArrowDownIcon = mode === Mode.DARK ? ArrowDownIconDM : ArrowDownIconLM

  return (
    <ArrowDownIconWrapper>
      <ArrowDownIcon />
    </ArrowDownIconWrapper>
  )
}

export default ArrowDown
