import { gql } from '@apollo/client'

export const ALL_PUBLISHED_NEWS = gql`
  query AllPublishedNews(
    $search: String
    $order: String
    $page: Int
    $take: Int
  ) {
    allPublishedNews(search: $search, order: $order, page: $page, take: $take) {
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
      data {
        id
        title
        content
        createdAt
        createdById
        lastModifiedById
        published
        publishedAt
        updatedAt
      }
    }
  }
`
