import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const NodesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
}))

export const PaginationWrapper = styled(Box)(() => ({
  padding: '20px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
}))

export const PaginationTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  fontSize: '14px',
  fontWeight: '300',
  textDecoration: 'none',
}))

export const AddressContent = styled(Box)(() => ({
  display: 'flex',
  pointerEvents: 'auto',
}))
