import { Tab } from '@mui/material'
import { styled } from '@mui/system'

export const StyledTab = styled(Tab)`
  text-transform: none;
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  min-width: initial;
  min-height: 40px;
`

export default StyledTab
