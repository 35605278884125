import React, { useMemo } from 'react'
import { useRoutes } from 'react-router-dom'

import { AllNodesPage } from 'pages/allNodesPage'
import { DetailNodePage } from 'pages/detailNodePage'
import { InstructionPage } from 'pages/instructionPage'
import MainLayout from 'pages/layouts/main'
import Wave2Layout from 'pages/layouts/wave2Layout'
import { LeaderboardPage } from 'pages/leaderboardPage'
import { MonthlyReportPage } from 'pages/montlyReportPage'
import { NotFoundPage } from 'pages/notFoundPage'
import { RegisterNodePage } from 'pages/registerNodePage'
import { SettingsPage } from 'pages/settingsPage'
import { TermsAndConditions } from 'pages/termsAndConditions'
import { TermsPage } from 'pages/termsPage'
import { TwitterCallbackPage } from 'pages/twitterCallbackPage'

import { Routes } from './routes'

type Props = {
  confirmedTerms?: boolean
}
// todo hide monthly report
export default function AuthorizedRouter({ confirmedTerms }: Props) {
  const routes = useMemo(() => {
    if (confirmedTerms) {
      return [
        { path: Routes.MONTHLY_REPORT, element: <MonthlyReportPage /> },
        { path: Routes.TERMS_PAGE, element: <TermsPage /> },
        {
          element: <MainLayout />,
          children: [
            {
              index: true,
              element: <RegisterNodePage />,
            },
            {
              path: Routes.ALL_NODES_PAGE,
              element: <AllNodesPage />,
            },
            {
              path: Routes.DETAIL_NODE,
              element: <DetailNodePage />,
            },
            {
              path: Routes.REGISTER_NODE_PAGE,
              element: <RegisterNodePage />,
            },
            { path: Routes.MONTHLY_REPORT, element: <MonthlyReportPage /> },
            {
              path: Routes.SETTINGS,
              element: <SettingsPage />,
            },
            {
              path: Routes.LEADERBOARD,
              element: <LeaderboardPage />,
            },
            { path: Routes.TERMS_PAGE, element: <TermsPage /> },
            {
              path: Routes.INSTRUCTION_PAGE,
              element: <InstructionPage />,
            },
          ],
        },
        {
          element: <Wave2Layout />,
          children: [
            {
              path: Routes.TWITTER_CALLBACK,
              element: <TwitterCallbackPage />,
            },
            {
              path: '*',
              element: <NotFoundPage />,
            },
          ],
        },
      ]
    }
    return [
      { path: Routes.TERMS_PAGE, element: <TermsPage /> },
      {
        element: <Wave2Layout />,
        children: [
          {
            index: true,
            element: <TermsAndConditions />,
          },
          {
            path: Routes.CONFIRM_TERMS,
            element: <TermsAndConditions />,
          },
          {
            path: '*',
            element: <NotFoundPage />,
          },
        ],
      },
    ]
  }, [confirmedTerms])

  return useRoutes(routes)
}
