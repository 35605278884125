import { gql } from '@apollo/client'

export const GET_STAKING_TERMS = gql`
  query GetStakingTerms {
    getStakingTerms {
      key
      value
    }
  }
`
