import { commonChartOptions } from 'constants/chartOptions'
import { getDateForCharts } from 'constants/date'
import { Point } from 'types/point'

import find from 'lodash/find'
import map from 'lodash/map'

export const createTokenStakedBarChartOptions = (xTicks: Array<Date>) => ({
  ...commonChartOptions,
  isStacked: true,
  chartArea: { width: '100%', height: 180 },
  hAxis: {
    ...commonChartOptions?.hAxis,
    ticks: xTicks,
  },
})

// todo implement theme
export const generateTooltipBlock = (
  date: string,
  staked: number,
  unstaked: number,
  theme: 'light' | 'dark',
) =>
  `
		<div style="background-color: ${
      theme === 'light' ? '#fff' : '#262546'
    }; padding: 5px; margin: 0; border-radius: 8px; min-width: 50px;" >
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-size: 10px; padding: 5px; margin: 0;">${getDateForCharts(
    date,
  )}</p>
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-weight: bold; padding: 5px; margin: 0;">Staked: <span style="color: ${'#5674B3'}; font-weight: bold; padding: 0; margin: 0;">${staked}</span></p>
  <p style="color: color: ${
    theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
  }; font-weight: bold; padding: 0 5px; margin: 0;">Unstaked: <span style="color: ${'#D92E35'}; font-weight: bold; padding: 0; margin: 0;">${unstaked}</span></p>
		</div>
		`

export function generateTokenPriceChartData(
  points1: Point[],
  points2: Point[],
  theme: 'light' | 'dark',
) {
  return [
    [
      'Date',
      'Staked',
      { role: 'tooltip', type: 'string', p: { html: true } },
      'Unstaked',
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    ...map(points1, point => {
      const pointDiff = find(points2, diff => diff?.day === point?.day)
      return [
        new Date(point?.day),
        pointDiff ? pointDiff.value : 0,
        generateTooltipBlock(
          point?.day,
          point?.value,
          pointDiff?.value || 0,
          theme,
        ),
        point?.value,
        generateTooltipBlock(
          point?.day,
          point?.value,
          pointDiff?.value || 0,
          theme,
        ),
      ]
    }),
  ]
}
