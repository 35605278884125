import { setRefreshToken } from './refreshToken'
import { setToken } from './token'

async function logOut() {
  localStorage.clear()
}

async function handleAuth(accessToken: string, refreshToken: string) {
  setToken(accessToken)
  setRefreshToken(refreshToken)
}

const Auth = { handleAuth, logOut }

export default Auth
