import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WEB3 } from 'config'

const chainId = WEB3.CHAIN_ID

export enum Provider {
  Injected = 'injected',
  WalletConnect = 'walletConnect',
}

const injected = new InjectedConnector({
  supportedChainIds: [chainId],
})

const walletConnect = new WalletConnectConnector({
  bridge: WEB3.WALLET_CONNECT_BRIDGE,
  rpc: { [chainId]: WEB3.RPC_URL },
  qrcode: true,
  chainId,
})

export const connectors: Record<Provider, any> = {
  injected,
  walletConnect,
}
