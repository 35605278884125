import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const PaginationWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & > nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    & > :first-child {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
`
