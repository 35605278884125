import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { API } from 'config'
import Shared from 'services/Shared'

import { createAuthLink, createErrorLink } from './links'

function createApolloClient() {
  const cache = new InMemoryCache()

  const links = [
    createErrorLink(),
    createAuthLink(),
    createHttpLink({ uri: `${API.URL}/v2/graphql` }),
  ]

  const client = new ApolloClient({
    link: ApolloLink.from(links),
    connectToDevTools: true,
    cache,
  })

  Shared.setApolloClient(client)

  return client
}

export default createApolloClient
