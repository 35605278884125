import React, { useCallback, useContext, useMemo } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Logout from '@mui/icons-material/Logout'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined'
import { Avatar, Box, Button, Divider } from '@mui/material'
import { indigo } from '@mui/material/colors'
import { ReactComponent as ArrowUpDM } from 'assets/icons/arrow_up_right_dark.svg'
import { ReactComponent as ArrowUpLM } from 'assets/icons/arrow_up_right_light.svg'
import { ReactComponent as MetaMaskIcon } from 'assets/icons/metamask_icon.svg'
import { ReactComponent as WalletConnectIcon } from 'assets/icons/wallet_connect_icon.svg'
import { AlertType } from 'components/alert/types'
import ContentBox from 'components/ContentBox'
import Subheader from 'components/Subheader'
import Text from 'components/ui/Text'
import { CONTRACTS, WEB3 } from 'config'
import { Provider } from 'constants/connector'
import { useAppContext } from 'context/AppContext'
import { ThemeContext } from 'context/themeMode'
import { SubmitButton } from 'globalStyles'
import {
  DISCONNECT_TWITTER,
  DISCONNECT_WALLET,
} from 'graphql/validators/mutation'
import { HAS_NODES } from 'graphql/validators/queries'
import { useResponsiveLayout } from 'hooks'
import useLogout from 'hooks/useLogout'
import useWeb3 from 'hooks/useWeb3'
import { getProvider } from 'services/provider'
import { redirectToTwitterAuth } from 'utils/auth'
import { truncateAddress } from 'utils/truncateAddress'

import {
  ChangeButton,
  ContentWrapper,
  InfoWrapper,
  ViewOnExplorerLink,
} from './styles'

function SettingsPage() {
  const provider = getProvider()
  const { active } = useWeb3()
  const logout = useLogout()
  const { isMobile } = useResponsiveLayout()

  const { me, setUsername, refetchMe, showAlert } = useAppContext()
  const { mode } = useContext(ThemeContext)

  // todo why has nodes true if i have no active nodes ?
  const { data, loading } = useQuery(HAS_NODES)

  const [disconnectWallet, { loading: disconnectLoading }] =
    useMutation(DISCONNECT_WALLET)
  const [disconnectTwitter, { loading: disconnectTwitterLoading }] =
    useMutation(DISCONNECT_TWITTER)

  const isDarkMode = mode === 'dark'
  const ArrowUp = isDarkMode ? ArrowUpDM : ArrowUpLM

  const handleCopy = useCallback(
    (address: string) => {
      navigator.clipboard.writeText(address)
      showAlert?.({
        isOpen: true,
        title: 'Copied!',
        color: AlertType.INFO,
      })
    },
    [showAlert],
  )

  const handleDisconnect = useCallback(async () => {
    try {
      await disconnectWallet()
      await refetchMe?.()
    } catch (error) {
      showAlert?.({
        isOpen: true,
        title: 'Something went wrong',
        color: AlertType.ERROR,
      })
    }
  }, [disconnectWallet, refetchMe, showAlert])

  // todo need to path back url, maybe create hook?
  const handleTwitterConnect = useCallback(() => {
    if (!me?.name) return setUsername?.()
    return redirectToTwitterAuth()
  }, [me, setUsername])

  const handleTwitterDisconnect = useCallback(async () => {
    try {
      await disconnectTwitter()
      refetchMe?.()
    } catch (error) {
      showAlert?.({
        isOpen: true,
        title: 'Something went wrong',
        color: AlertType.ERROR,
      })
    }
  }, [disconnectTwitter, refetchMe, showAlert])

  const canDisconnect = useMemo(
    () => !loading && active && !data?.hasNodes,
    [data, loading, active],
  )

  const connected = me?.wallet && active

  const isTwitterConnected = useMemo(() => !!me?.twitterUserId, [me])

  return (
    <>
      <Subheader py={4}>
        <Text sx={{ textAlign: 'left', mb: 1 }} title1>
          Account settings
        </Text>
      </Subheader>
      <ContentBox>
        <InfoWrapper>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minWidth="248px"
          >
            <Box>
              <Box alignItems="baseline" display="flex" flexDirection="column">
                <Text secondary sx={{ fontWeight: '500', mb: 2 }}>
                  {connected && (
                    <>
                      Connected with{' '}
                      {provider === Provider.Injected
                        ? 'MetaMask'
                        : 'WalletConnect'}
                    </>
                  )}
                </Text>
                {connected && provider === Provider.Injected && (
                  <MetaMaskIcon
                    style={{
                      marginBottom: '16px',
                      height: '36px',
                      width: '36px',
                    }}
                  />
                )}
                {connected && provider !== Provider.Injected && (
                  <WalletConnectIcon
                    style={{
                      marginBottom: '16px',
                      height: '36px',
                      width: '36px',
                    }}
                  />
                )}
                {connected && canDisconnect && (
                  <ChangeButton
                    disabled={disconnectLoading}
                    sx={{ mb: 2 }}
                    onClick={handleDisconnect}
                  >
                    Disconnect Wallet
                  </ChangeButton>
                )}
              </Box>

              {connected && (
                <>
                  <Box alignItems="center" display="flex" gap={1} mb={0.5}>
                    <Text title2>{truncateAddress(me?.wallet as string)}</Text>
                    <ContentCopyIcon
                      color="secondary"
                      style={{
                        cursor: 'pointer',
                        height: '16px',
                        width: '16px',
                      }}
                      onClick={() => handleCopy(me?.wallet as string)}
                    />
                  </Box>

                  <ViewOnExplorerLink
                    href={`${WEB3.POLYGONSCAN_URL}/address/${me?.wallet}`}
                    target="_blank"
                    underline="none"
                  >
                    View on Explorer <ArrowUp />
                  </ViewOnExplorerLink>
                </>
              )}
            </Box>
            <Button
              disabled={disconnectTwitterLoading}
              sx={{
                textTransform: 'none',
                mb: -0.25,
                ml: -0.25,
                width: 'fit-content',
              }}
              variant="text"
              onClick={logout}
            >
              <Box display="flex" gap={0.5}>
                <Logout color="error" fontSize="small" />
                <Text error>Logout</Text>
              </Box>
            </Button>
          </Box>
          <Divider
            flexItem
            orientation={isMobile ? 'horizontal' : 'vertical'}
          />
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={3}
            gap={2.5}
            overflow="hidden"
            textAlign="start"
          >
            {me?.email && (
              <Box>
                <Text body3 secondary sx={{ mb: 0.5 }}>
                  Email
                </Text>
                <ContentWrapper>
                  <Text body2>{me?.email}</Text>
                  <ContentCopyIcon
                    color="secondary"
                    style={{
                      height: 16,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleCopy(me.email)}
                  />
                </ContentWrapper>
              </Box>
            )}

            <Box>
              <Text body3 secondary sx={{ mb: 0.5 }}>
                Username
              </Text>
              <ContentWrapper sx={{ mb: 0.5 }}>
                <Text body2 ellipsis>
                  {me?.name || '__'}
                </Text>
                {me?.name && (
                  <ContentCopyIcon
                    color="secondary"
                    style={{
                      height: 16,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleCopy(me.name)}
                  />
                )}
              </ContentWrapper>
              <ContentWrapper onClick={() => setUsername?.()}>
                <ModeEditIcon
                  color="primary"
                  style={{
                    height: 16,
                    cursor: 'pointer',
                  }}
                />
                <Text body2 primary>
                  Edit
                </Text>
              </ContentWrapper>
            </Box>
            <Box>
              <Text body3 secondary sx={{ mb: 0.5 }}>
                Master Contract
              </Text>
              <ContentWrapper mb={0.5}>
                <Text body2 ellipsis>
                  {CONTRACTS.MASTER_ADDRESS}
                </Text>

                <ContentCopyIcon
                  color="secondary"
                  style={{
                    height: 16,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCopy(CONTRACTS.MASTER_ADDRESS)}
                />
              </ContentWrapper>
              <ViewOnExplorerLink
                href={`${WEB3.POLYGONSCAN_URL}/address/${CONTRACTS.MASTER_ADDRESS}`}
                target="_blank"
                underline="none"
              >
                View on Explorer <ArrowUp />
              </ViewOnExplorerLink>
            </Box>

            <Box>
              <Text body3 secondary sx={{ mb: 0.5 }}>
                Token Contract
              </Text>
              <ContentWrapper mb={0.5}>
                <Text body2 ellipsis>
                  {CONTRACTS.TOKEN_ADDRESS}
                </Text>

                <ContentCopyIcon
                  color="secondary"
                  style={{
                    height: 16,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCopy(CONTRACTS.TOKEN_ADDRESS)}
                />
              </ContentWrapper>
              <ViewOnExplorerLink
                href={`${WEB3.POLYGONSCAN_URL}/address/${CONTRACTS.TOKEN_ADDRESS}`}
                target="_blank"
                underline="none"
              >
                View on Explorer <ArrowUp />
              </ViewOnExplorerLink>
            </Box>
          </Box>
        </InfoWrapper>
        <InfoWrapper mt={2}>
          <Box
            display="flex"
            flexDirection="column"
            gap={0.5}
            minWidth="248px"
            textAlign="start"
          >
            <Text fontWeight={500} muted>
              X (Twitter) authentication
            </Text>
            {isTwitterConnected ? (
              <Button
                disabled={disconnectTwitterLoading}
                sx={{ textTransform: 'none', width: 'fit-content' }}
                variant="text"
                onClick={handleTwitterDisconnect}
              >
                <Box display="flex" gap={0.5}>
                  <CloudOffIcon color="error" fontSize="small" />
                  <Text error>Disconnect</Text>
                </Box>
              </Button>
            ) : (
              <Box
                display="flex"
                gap={0.5}
                sx={{ textTransform: 'none' }}
                onClick={handleTwitterConnect}
              >
                <NotInterestedOutlinedIcon color="secondary" fontSize="small" />
                <Text fontWeight={600} secondary>
                  Not connected
                </Text>
              </Box>
            )}
          </Box>
          <Divider
            flexItem
            orientation={isMobile ? 'horizontal' : 'vertical'}
          />
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={3}
            textAlign="start"
          >
            {isTwitterConnected ? (
              <Box display="flex" gap={2}>
                <Avatar
                  alt={me?.twitterUsername}
                  src={me?.twitterProfileImageUrl}
                  sx={{ bgcolor: indigo[500], height: '44px', width: '44px' }}
                >
                  {me?.twitterUsername?.charAt(0)}
                </Avatar>
                <Box>
                  <Text body2> {me?.twitterUsername || '__'}</Text>
                  <ViewOnExplorerLink
                    href={`//twitter.com/${me?.twitterUsername}`}
                    target="_blank"
                    underline="none"
                  >
                    Show profile <ArrowUp />
                  </ViewOnExplorerLink>
                </Box>
              </Box>
            ) : (
              <SubmitButton
                sx={{ mt: 0, height: '44px', maxWidth: '255px' }}
                onClick={handleTwitterConnect}
              >
                Connect X (Twitter) account
              </SubmitButton>
            )}
          </Box>
        </InfoWrapper>
      </ContentBox>
    </>
  )
}

export default SettingsPage
