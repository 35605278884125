import React, { useContext } from 'react'

import { Link } from '@mui/material'
import { ReactComponent as ArrowUpDM } from 'assets/icons/arrow_up_right_dark.svg'
import { ReactComponent as ArrowUpLM } from 'assets/icons/arrow_up_right_light.svg'
import { ThemeContext } from 'context/themeMode'

import { Subtitle } from '../nodeIntroduce/styles'

export default function InstructionsLink() {
  const { mode } = useContext(ThemeContext)

  const isDarkMode = mode === 'dark'
  const ArrowUp = isDarkMode ? ArrowUpDM : ArrowUpLM

  return (
    <Subtitle style={{ marginTop: 16 }}>
      <Link
        href={
          'https://news.morpheus.network/morpheus-network-masternodes-beta-is-live-406f329fc2d0'
        }
        target="_blank"
      >
        Click here for instructions
        <ArrowUp style={{ height: 12 }} />
      </Link>
    </Subtitle>
  )
}
