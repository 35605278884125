import { Box, Button } from '@mui/material'
import { styled } from '@mui/system'

export const Title = styled(Box)(({ theme }) => ({
  fontSize: '32px',
  lineHeight: '40px',
  fontWeight: '700',
  color: theme.palette.mode === 'light' ? 'rgba(0, 0, 33, 1)' : 'white',
}))

export const Subtitle = styled(Box)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? 'rgba(122, 137, 166, 1)'
      : 'rgba(105, 122, 151, 1)',
  fontSize: '16px',
  fontWeight: 'bold',
  marginTop: '10px',
  marginBottom: '20px',
  lineHeight: '24px',
}))

export const SubmitButton = styled(Button)(({ theme }) => ({
  width: '100%',
  maxWidth: '370px',
  height: '56px',
  color: 'white',
  background: 'rgba(77, 117, 184, 1)',
  borderRadius: '8px',
  fontWeight: 'bold',
  marginTop: '24px',
  lineHeight: '24px',
  fontSize: '16px',
  textTransform: 'none',
  '&.Mui-disabled': {
    background:
      theme.palette.mode === 'light' ? 'rgba(237, 240, 245, 1)' : '#0C2042',
    color:
      theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 1)' : '#1B3A6F',
  },
}))
