export enum StakingPeriod {
  MONTH_3 = 'months3',
  MONTH_6 = 'months6',
  MONTH_12 = 'months12',
}

export const StakingTermsLabel: Record<StakingTerms['key'], string> = {
  [StakingPeriod.MONTH_3]: '3 months',
  [StakingPeriod.MONTH_6]: '6 months',
  [StakingPeriod.MONTH_12]: '12 months',
}

export type StakingTerms = {
  key: StakingPeriod
  value: number
}

export type StakingTermsOption = {
  value: StakingPeriod
  label: string
}
