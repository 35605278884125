import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)(() => ({
  position: 'relative',
  width: '235px',
  flexShrink: '0',
}))

export const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  top: '0',
  width: '100%',
  gap: '16px',
  paddingBlock: '16px',
  overflowY: 'auto',
  maxHeight: '100vh',

  '::-webkit-scrollbar': {
    display: 'none',
  },
}))

export const StyledButton = styled(Box)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#7a89a6' : '#697a97',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '150%',
  cursor: 'pointer',

  '&:hover': {
    color: theme.palette.mode === 'light' ? '#000021' : '#ffffff',
  },
}))
