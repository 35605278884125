import { commonChartOptions } from 'constants/chartOptions'
import { getDateForCharts } from 'constants/date'
import { getFormattedNumber } from 'constants/number'
import { Point } from 'types/point'

import map from 'lodash/map'

export const createTokenStakedChartOptions = () => ({
  ...commonChartOptions,
  colors: ['#4D75B8'],
  chartArea: { width: '100%', height: 180 },
})

export const generateTooltipBlock = (
  point: Point,
  theme?: 'light' | 'dark',
) => {
  return `
		<div style="background-color: ${
      theme === 'light' ? '#fff' : '#262546'
    }; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-weight: bold; padding: 0 5px 5px; margin: 0;">${getDateForCharts(
    point?.day,
  )}</p>
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-weight: bold; padding: 5px; margin: 0;">${getFormattedNumber(
    point.value,
  )}</p>
		</div>
		`
}

// todo poll ?
export function generateTokenStakedChartData(
  points: Point[],
  theme?: 'light' | 'dark',
) {
  return [
    ['Date', 'MATIC', { role: 'tooltip', type: 'string', p: { html: true } }],
    ...map(points, point => {
      return [
        new Date(point?.day),
        point?.value,
        generateTooltipBlock(point, theme),
      ]
    }),
  ]
}
