import { LOW_UPTIME_LINE, WARNING_UPTIME_LINE } from 'constants/params'
import { DateTime } from 'luxon'

import find from 'lodash/find'
import forEach from 'lodash/forEach'
import map from 'lodash/map'

import { generateUptimeTooltipBlock } from './createChartOptions'

interface Point {
  key: string
  value: number
  warningValue?: null | number
  lowValue?: null | number
}

export function getKey(point1: Point, point2: Point, limit: number) {
  const x1 = DateTime.fromISO(point1?.key).toMillis()
  const x2 = DateTime.fromISO(point2?.key).toMillis()
  return DateTime.fromMillis(
    getPoint(x1, point1?.value, x2, point2?.value, limit),
  ).toISO()
}

export function getPoint(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  limit: number,
) {
  return ((limit - y1) / (y2 - y1)) * (x2 - x1) + x1
}

export function getLimitChart(data: Point[], limit: number, field: string) {
  const formattedData: Point[] = []
  forEach(data, (point: Point, index: number) => {
    if (point?.value > limit) {
      if (data[index - 1]?.value < limit) {
        const temporaryX = getKey(point, data[index - 1], limit)
        formattedData.push({
          key: temporaryX,
          value: limit,
          warningValue: limit === LOW_UPTIME_LINE ? limit : WARNING_UPTIME_LINE,
          [field]: limit,
        })
        formattedData.push({
          ...point,
          [field]: null,
        })
      } else {
        formattedData.push({
          ...point,
          [field]: null,
        })
      }
    } else if (point?.value < limit) {
      if (data[index - 1]?.value > limit) {
        const temporaryX = getKey(point, data[index - 1], limit)
        formattedData.push({
          key: temporaryX,
          value: limit,
          warningValue: limit === LOW_UPTIME_LINE ? limit : WARNING_UPTIME_LINE,
          [field]: limit,
        })
        formattedData.push({
          ...point,
          [field]: point?.value,
        })
      } else {
        formattedData.push({
          ...point,
          [field]: point?.value,
        })
      }
    } else if (
      data[index - 1]?.value < limit ||
      data[index + 1]?.value < limit
    ) {
      formattedData.push({
        ...point,
        [field]: point?.value,
      })
    } else {
      formattedData.push({
        ...point,
        [field]: null,
      })
    }
  })
  return formattedData
}

export function dateFormatParser(date: string) {
  return DateTime.fromISO(date).toFormat('HH:mm') === '00:00'
    ? DateTime.fromISO(date).toFormat('yyyy-MM-dd')
    : DateTime.fromISO(date).toFormat('yyyy-MM-dd HH:mm')
}

export function generateUptimeChartData(
  points: Point[],
  theme: 'light' | 'dark',
) {
  if (find(points, (point: Point) => point?.value < 50)) {
    const dataWithWarningChat = getLimitChart(
      points,
      WARNING_UPTIME_LINE,
      'warningValue',
    )
    const dataWithLowChat = getLimitChart(
      dataWithWarningChat,
      LOW_UPTIME_LINE,
      'lowValue',
    )
    return [
      [
        'Date',
        '%',
        { role: 'tooltip', type: 'string', p: { html: true } },
        '%',
        { role: 'tooltip', type: 'string', p: { html: true } },
        '%',
        { role: 'tooltip', type: 'string', p: { html: true } },
      ],
      ...map(dataWithLowChat, point => [
        point?.key,
        point?.value,
        generateUptimeTooltipBlock(point, theme),
        point?.warningValue,
        generateUptimeTooltipBlock(point, theme),
        point?.lowValue,
        generateUptimeTooltipBlock(point, theme),
      ]),
    ]
  }
  if (find(points, (point: Point) => point?.value < 90)) {
    const dataWithWarningChat = getLimitChart(
      points,
      WARNING_UPTIME_LINE,
      'warningValue',
    )
    return [
      [
        'Date',
        '%',
        { role: 'tooltip', type: 'string', p: { html: true } },
        '%',
        { role: 'tooltip', type: 'string', p: { html: true } },
      ],
      ...map(dataWithWarningChat, point => [
        point?.key,
        point?.value,
        generateUptimeTooltipBlock(point, theme),
        point?.warningValue,
        generateUptimeTooltipBlock(point, theme),
      ]),
    ]
  }

  return [
    ['Date', '%', { role: 'tooltip', type: 'string', p: { html: true } }],
    ...map(points, point => [
      point?.key,
      point?.value,
      generateUptimeTooltipBlock(point, theme),
    ]),
  ]
}
