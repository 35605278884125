import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${({ theme }) => theme.palette.colors.bg.default};
`

export const Overflow = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.colors.bg.overflow}1a;
  z-index: 0;
  backdrop-filter: blur(10px);
  transition: opacity 250ms;
`

export const UserNameHolder = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.colors.bg.dropdown};
`

export const MenuHolder = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 16px;
  z-index: 1;
  color: ${({ theme }) => theme.palette.colors.font.default}; ;
`
