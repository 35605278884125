import React from 'react'

import { Box, Button } from '@mui/material'
import SubmitButton from 'components/ui/SubmitButton'
import Text from 'components/ui/Text'

type Props = {
  onClose?: () => void
  onNextStep?: (step: number) => void
}

function InstallStep({ onClose, onNextStep }: Props) {
  return (
    <>
      <Text body0 sx={{ mb: 1 }}>
        Get the full experience in the Morpheus app
      </Text>
      <Text body4 secondary>
        Install the app to your home screen
      </Text>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <SubmitButton
          sx={{ my: 4, maxWidth: '360px' }}
          onClick={() => onNextStep?.(2)}
        >
          Install
        </SubmitButton>
        <Button
          sx={{
            textTransform: 'none',
            width: 'fit-content',
            mt: 0,
            p: 0,
          }}
          variant="text"
          onClick={onClose}
        >
          <Text primary sx={{ fontSize: '16px' }} title3>
            Continue in browser
          </Text>
        </Button>
      </Box>
    </>
  )
}

export default InstallStep
