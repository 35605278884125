import { DateTime } from 'luxon'

export const phaseDaysLeft = (endDate: string) => {
  if (!endDate) return 0
  if (DateTime.fromISO(endDate) > DateTime.now()) {
    return Math.round(
      DateTime.fromISO(endDate).diff(DateTime.now(), 'days').days,
    )
  }
  return 0
}
