import { gql } from '@apollo/client'

export const QUEST_ACTIVITY_FIELDS = gql`
  fragment QuestActivityFields on QuestActivity {
    phaseId
    points
    questId
    validatorId
  }
`

export const QUEST_FIELDS = gql`
  fragment QuestFields on Quest {
    id
    title
    content
    createdAt
    updatedAt
    publishDate
    expirationDate
    phaseId
    imageUrl
    twitterId
    questActivity {
      ...QuestActivityFields
    }
  }
  ${QUEST_ACTIVITY_FIELDS}
`
