import * as React from 'react'
import { useCallback, useContext } from 'react'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import AntSwitch from 'components/AntSwitch'
import { ThemeContext } from 'context/themeMode'
import { useNavigation } from 'hooks'

import { MenuContent } from './styles'

export default function AccountMenu() {
  const { mode } = useContext(ThemeContext)
  const { navItems } = useNavigation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNavItemClick = useCallback(
    (action?: () => void) => action?.(),
    [],
  )

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          aria-controls={open ? 'account-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          size="small"
          sx={{ p: 0 }}
          onClick={handleClick}
        >
          {open ? (
            <ExpandLessIcon fontSize="small" />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
      <MenuContent
        PaperProps={{
          elevation: 0,
          sx: {
            color: 'primary',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            borderRadius: '8px',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClick={handleClose}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => handleNavItemClick(navItems.Leaderboard.action)}
        >
          <ListItemIcon>{navItems.Leaderboard.icon}</ListItemIcon>
          {navItems.Leaderboard.caption}
        </MenuItem>

        <MenuItem
          onClick={() => handleNavItemClick(navItems.Instruction.action)}
        >
          <ListItemIcon>{navItems.Instruction.icon}</ListItemIcon>
          {navItems.Instruction.caption}
        </MenuItem>

        <MenuItem
          onClick={() => handleNavItemClick(navItems.LightPaper.action)}
        >
          <ListItemIcon>{navItems.LightPaper.icon}</ListItemIcon>
          {navItems.LightPaper.caption}
        </MenuItem>

        <MenuItem onClick={() => handleNavItemClick(navItems.Settings.action)}>
          <ListItemIcon>{navItems.Settings.icon}</ListItemIcon>
          {navItems.Settings.caption}
        </MenuItem>

        <MenuItem onClick={() => handleNavItemClick(navItems.ColorMode.action)}>
          <ListItemIcon>{navItems.ColorMode.icon}</ListItemIcon>
          {navItems.ColorMode.caption}

          <AntSwitch checked={mode === 'dark'} sx={{ ml: 2 }} />
        </MenuItem>

        <Divider />

        <MenuItem onClick={() => handleNavItemClick(navItems.Logout.action)}>
          <ListItemIcon>{navItems.Logout.icon}</ListItemIcon>
          {navItems.Logout.caption}
        </MenuItem>
      </MenuContent>
    </>
  )
}
