import React, { useCallback, useMemo } from 'react'

import Avatar from 'avataaars'

import { AvatarOption, avatarOptions } from './avatarOptions'

type AvatarConfig = {
  [key in keyof AvatarOption]: string
}

type Props = {
  name?: string
}

function RandomAvatar({ name }: Props) {
  const generateAvatarConfig = useCallback((username: string): AvatarConfig => {
    const properties: Partial<AvatarConfig> = {}
    const propertiesKeys = Object.keys(avatarOptions) as Array<
      keyof AvatarOption
    >
    const usernameLength = username.length

    propertiesKeys.forEach((prop, index) => {
      const charIndex = index % usernameLength
      const charCode = username.charCodeAt(charIndex) - 97
      const propOptions = avatarOptions[prop]
      const propIndex = Math.abs(charCode % propOptions.length)
      properties[prop] = propOptions[propIndex]
    })

    return properties as AvatarConfig
  }, [])

  const avatarConfig = useMemo(
    () => generateAvatarConfig(name || 'Validator'),
    [generateAvatarConfig, name],
  )

  return (
    <Avatar
      avatarStyle="circle"
      style={{ width: '30px', height: '30px' }}
      {...avatarConfig}
    />
  )
}

export default RandomAvatar
