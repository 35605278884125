import { gql } from '@apollo/client'

import { QUEST_ACTIVITY_FIELDS } from './fragments'

export const VERIFY_QUEST = gql`
  mutation verifyQuest($questId: String!) {
    verifyQuest(questId: $questId) {
      ...QuestActivityFields
    }
  }
  ${QUEST_ACTIVITY_FIELDS}
`
