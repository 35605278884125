import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Content = styled(Box)<{
  isExpanded: boolean
}>`
  color: white;
  overflow: hidden;
  position: relative;
  display: inline;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  overflow-wrap: break-word;
`

export const ReadMoreButton = styled('span')`
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
`
