import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)`
  max-width: 600px;
  padding: 0 40px;
  background-color: ${({ theme }) => theme.palette.colors.bg.template};
  color: ${({ theme }) => theme.palette.colors.font.template};
`

export const StakedAmount = styled(Typography)`
  color: ${({ theme }) => theme.palette.buttons.backgroundColor.success};
`
