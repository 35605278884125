import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Wrapper = styled(Box)`
  display: grid;
  grid-gap: 1px;
  margin: 20px 0 0;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    border-radius: 8px;
    overflow: hidden;
  }
`
export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.colors.bg.header};

  &:first-of-type {
    border-radius: 8px 0 0 8px;
  }

  &:last-of-type {
    border-radius: 0 8px 8px 0;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    &:first-of-type {
      border-radius: 0;
    }

    &:last-of-type {
      border-radius: 0;
    }
  }
`
