import React, { useContext, useMemo } from 'react'

import { Box } from '@mui/material'
import MorpheusFormIconDM from 'assets/icons/morpheus_form_icon_dark.svg'
import MorpheusFormIconLM from 'assets/icons/morpheus_form_icon_light.svg'
import { useAppContext } from 'context/AppContext'
import { ThemeContext } from 'context/themeMode'
import { Routes } from 'router/routes'

import { RootLink, Text } from './styles'

function NotFoundPage() {
  const mode = useContext(ThemeContext)
  const { me } = useAppContext()

  const isDarkMode = mode.mode === 'dark'
  const MorpheusFormIcon = isDarkMode ? MorpheusFormIconDM : MorpheusFormIconLM

  const linkPath = useMemo(() => {
    if (!me) {
      return Routes.SIGN_UP
    }
    return Routes.ROOT
  }, [me])

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <img alt="MNW" src={MorpheusFormIcon} width={100} />
      <Text sx={{ fontSize: '72px', paddingTop: '16px', fontWeight: '800' }}>
        404
      </Text>
      <Text sx={{ fontSize: '18px' }}>Sorry, Page Not Found</Text>
      <RootLink to={linkPath}>Return to home page</RootLink>
    </Box>
  )
}

export default NotFoundPage
