import React, { ReactNode } from 'react'

import { SxProps } from '@mui/material'
import Modal from '@mui/material/Modal'
import { ReactComponent as CloseIconDM } from 'assets/icons/modal_close_icon_dark.svg'

import { ModalCloseButton, ModalTitle, ModalWrapper } from './styles'

type Props = {
  children: ReactNode
  open?: boolean
  title?: string
  handleClose?: () => void
  sx?: SxProps
}

function CustomModal({
  open = false,
  handleClose,
  children,
  title,
  sx,
}: Props) {
  return (
    <Modal
      aria-describedby="modal-modal-description"
      aria-labelledby="modal-modal-title"
      open={open}
      onClose={handleClose}
    >
      <ModalWrapper sx={sx}>
        {title && <ModalTitle>{title}</ModalTitle>}

        <ModalCloseButton onClick={handleClose}>
          <CloseIconDM />
        </ModalCloseButton>
        {children}
      </ModalWrapper>
    </Modal>
  )
}

export default CustomModal
