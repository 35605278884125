import React, { useContext, useState } from 'react'

import { Popover, Typography } from '@mui/material'
import { ReactComponent as PopupIconDM } from 'assets/icons/popup_icon_dark.svg'
import { ReactComponent as PopupIconLM } from 'assets/icons/popup_icon_light.svg'
import { ThemeContext } from 'context/themeMode'

import { TitlePopupWrapper } from './styles'

interface Props {
  title: React.ReactNode
  popupWidth?: string
  style?: {
    [key: string]: string
  }
}

const Popup: React.FC<Props> = ({ title, style, popupWidth }: Props) => {
  const mode = useContext(ThemeContext)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isDarkMode = mode.mode === 'dark'
  const PopupIcon = isDarkMode ? PopupIconDM : PopupIconLM

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handlePopoverClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl)

  return (
    <div>
      <Typography
        aria-haspopup="true"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        style={{ margin: '10px 0 0 0', cursor: 'pointer', ...style }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <PopupIcon />
      </Typography>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableRestoreFocus
        id="mouse-over-popover"
        open={open}
        sx={{
          pointerEvents: 'none',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <TitlePopupWrapper sx={{ maxWidth: popupWidth || '270px' }}>
          {title}
        </TitlePopupWrapper>
      </Popover>
    </div>
  )
}

export default Popup
