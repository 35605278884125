import { gql } from '@apollo/client'

export const LOGIN_CONFIRM = gql`
  mutation loginConfirmValidator($hash: String!) {
    loginConfirmValidator(hash: $hash) {
      id
      access
      isApproved
      refresh
    }
  }
`

export const LOGIN_CODE_CONFIRM = gql`
  mutation loginConfirmCodeValidator($email: String!, $code: String!) {
    loginConfirmCodeValidator(email: $email, code: $code) {
      id
      access
      isApproved
      refresh
    }
  }
`

// todo should be removed?
export const LOGIN_VALIDATOR = gql`
  mutation LoginValidator($email: String!) {
    loginValidator(email: $email)
  }
`

export const LOGIN_CODE_VALIDATOR = gql`
  mutation LoginCodeValidator($email: String!) {
    loginCodeValidator(email: $email)
  }
`
export const SIGN_UP_VALIDATOR = gql`
  mutation SignUpValidator($email: String!, $confirmNewsletter: Boolean!) {
    signUpValidator(email: $email, confirmNewsletter: $confirmNewsletter)
  }
`

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshTokenData: RefreshToken!) {
    refreshToken(refreshTokenData: $refreshTokenData) {
      access
      refresh
    }
  }
`

export const CONFIRM_TERMS = gql`
  mutation ConfirmTerms($confirmNewsletter: Boolean!) {
    confirmTerms(confirmNewsletter: $confirmNewsletter)
  }
`
