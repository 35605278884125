import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const SelectUserNameWrapper = styled(Box)`
  display: flex;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
  }
`
