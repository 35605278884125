import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const ArrowDownIconWrapper = styled(Box)`
  position: absolute;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid ${({ theme }) => theme.palette.colors.border.default};
  background: ${({ theme }) => theme.palette.colors.bg.default};
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`
