import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import {
  AnyEventName,
  EventPayload,
  SnsError,
  // eslint-disable-next-line import/no-unresolved
} from '@sumsub/websdk/types/types'
import SumsubWebSdk from '@sumsub/websdk-react'
import { VerificationIntroduce } from 'components/verificationIntroduce'
import { GET_ACCESS_TOKEN } from 'graphql/verification/queries'

import customCssStr from './customCssStr'

function Verification() {
  const { data } = useQuery(GET_ACCESS_TOKEN)

  const [isVerification, setIsVerification] = useState<boolean>(false)

  const onVerificationHandler = () =>
    setIsVerification((prevState: boolean) => !prevState)

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      pt={4}
      sx={{ maxWidth: '370px' }}
    >
      {!isVerification && (
        <VerificationIntroduce onClick={onVerificationHandler} />
      )}
      {isVerification && (
        <SumsubWebSdk
          accessToken={data?.getAccessToken as string}
          config={{
            i18n: {
              document: {
                subTitles: {
                  IDENTITY: 'Upload a document that proves your identity',
                },
              },
            },
            uiConf: {
              customCssStr,
            },
          }}
          expirationHandler={(): Promise<string> => {
            throw new Error('Function not implemented.')
          }}
          // eslint-disable-next-line no-console
          onError={(data: SnsError) => console.log('onError', data)}
          onMessage={(type: AnyEventName, payload: EventPayload<any>) => {
            // eslint-disable-next-line no-console
            console.log('onMessage', type, payload)
          }}
        />
      )}
    </Box>
  )
}

export default Verification
