import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { AlertTitle, Box, Snackbar } from '@mui/material'
import { ReactComponent as Logo } from 'assets/icons/login_logo-dark.svg'
import { Alert } from 'components/alert'
import { AlertMessage, AlertType } from 'components/alert/types'
import StyledInput from 'components/ui/StyledInput'
import SubmitButton from 'components/ui/SubmitButton'
import Text from 'components/ui/Text'
import { ALERT_DELAY } from 'constants/params'
import { useFormik } from 'formik'
import { LowerTitle } from 'globalStyles'
import { LOGIN_CODE_CONFIRM } from 'graphql/auth/mutation'
import Auth from 'services/Auth'
import { getErrorMessage } from 'utils/error'
import * as yup from 'yup'

enum FIELDS {
  CODE = 'code',
}
interface InitialValues {
  [FIELDS.CODE]: string
}

function LoginCode() {
  const { state } = useLocation() as { state: { email?: string } }

  const [loginCodeConfirm, { loading }] = useMutation(LOGIN_CODE_CONFIRM)
  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })

  const email = state?.email

  const handleSubmitCode = async (values: InitialValues) => {
    try {
      const { data } = await loginCodeConfirm({
        variables: { email, [FIELDS.CODE]: values[FIELDS.CODE] },
      })

      if (!data?.loginConfirmCodeValidator) throw new Error()

      const { access: accessToken, refresh: refreshToken } =
        data.loginConfirmCodeValidator

      Auth.handleAuth(accessToken, refreshToken)

      window.location.replace('/')
    } catch (error) {
      setAlert({
        isOpen: true,
        title: getErrorMessage(error),
        color: AlertType.ERROR,
      })
    }
  }

  const { errors, values, handleChange, isValid, handleSubmit } = useFormik({
    initialValues: { [FIELDS.CODE]: '' },
    onSubmit: handleSubmitCode,
    validationSchema: yup.object({
      [FIELDS.CODE]: yup
        .string()
        .length(7, 'Code must be exactly 7 characters')
        .required('Code is a required field'),
    }),
  })

  return (
    <>
      <Box sx={{ maxWidth: '370px' }}>
        <Logo />
        <Text title1>Welcome</Text>
        <Text body3 secondary sx={{ mt: 0.5 }}>
          Please enter code from email sent to &quot;{email}&quot;
        </Text>
        <form onSubmit={handleSubmit}>
          <StyledInput
            name={FIELDS.CODE}
            placeholder="Confirmation code"
            value={values[FIELDS.CODE]}
            onChange={handleChange}
          />
          <LowerTitle
            sx={{
              mt: '8px',
              mb: 0,
              color: 'red',
              height: '24px',
            }}
          >
            {errors.code}
          </LowerTitle>
          <SubmitButton disabled={loading || !isValid} type="submit">
            Confirm
          </SubmitButton>
        </form>
      </Box>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert?.isOpen}
        onClose={() => setAlert(prevState => ({ ...prevState, isOpen: false }))}
      >
        <Alert
          severity={alert?.color}
          sx={{ width: '100%' }}
          onClose={() =>
            setAlert(prevState => ({ ...prevState, isOpen: false }))
          }
        >
          <AlertTitle sx={{ textAlign: 'left' }}>{alert.title}</AlertTitle>
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default LoginCode
