import { gql } from '@apollo/client'

import { VALIDATIONS_PER_DAY_BY_NODE_ID_GRAPH_DATA } from './fragments'

export const VALIDATIONS_PER_DAY_BY_NODE_ID = gql`
  query validationsPerDayByNodeIdGraphData($nodeId: String!) {
    validationsPerDayByNodeIdGraphData(nodeId: $nodeId) {
      ...ValidationsPerDayByNodeIdGraphDataFields
    }
  }
  ${VALIDATIONS_PER_DAY_BY_NODE_ID_GRAPH_DATA}
`
