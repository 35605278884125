import React, { useCallback } from 'react'

import { Button, useTheme } from '@mui/material'
import CustomModal from 'components/modal/customModal'
import Text from 'components/ui/Text'
import { SubmitButton } from 'globalStyles'

interface Props {
  isOpen?: boolean
  onClose?: (ok?: boolean) => void
}

function ResetNodeLockModal({ isOpen, onClose }: Props) {
  const materialTheme = useTheme()

  const handleClose = useCallback((ok: boolean) => onClose?.(ok), [onClose])

  return (
    <CustomModal
      handleClose={() => handleClose(false)}
      open={isOpen}
      sx={{
        padding: '72px',
        maxWidth: '570px',
        width: '90%',
        maxHeight: '90%',
      }}
    >
      <Text sx={{ textAlign: 'center', mt: 1 }} title1>
        Reset node lock
      </Text>

      <Text
        body2
        sx={{
          textAlign: 'center',
          color: materialTheme?.palette.text.primary,
          fontWeight: '300',
        }}
      >
        Are you sure you want to do the Force Logout?
      </Text>

      <Text
        sx={{
          fontSize: '16px',
          mb: '24px',
          textAlign: 'center',
          color: materialTheme?.palette.text.primary,
          fontWeight: '300',
        }}
      >
        Nodes that weren&apos;t explicitly logged out previously - would be
        logged out.
      </Text>

      <SubmitButton
        sx={{ mb: '24px', mt: 0, width: '160px' }}
        onClick={() => handleClose(true)}
      >
        Reset
      </SubmitButton>

      <Button
        sx={{
          color: materialTheme?.palette.text.primary,
          textTransform: 'capitalize',
        }}
        onClick={() => handleClose(false)}
      >
        Cancel
      </Button>
    </CustomModal>
  )
}

export default ResetNodeLockModal
