import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const MainContainer = styled('section')`
  height: 100dvh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.colors.bg.default};
`

export const WaveStyled = styled('div')`
  position: fixed;
  top: 0;
  right: 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    top: -209px;
    right: -7px;
  }
`

export const ContentBox = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  z-index: 5;
`
