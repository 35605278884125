import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { AuthorizedContext } from 'context/authorizedContext'
import { Routes } from 'router/routes'
import Auth from 'services/Auth'
import { removeProvider } from 'services/provider'

import useWeb3 from './useWeb3'

export default function useLogout() {
  const navigate = useNavigate()

  const { deactivate } = useWeb3()
  const { setAuthorized } = useContext(AuthorizedContext)

  return useCallback(() => {
    setAuthorized(false)
    Auth.logOut()
    removeProvider()
    deactivate()
    navigate(Routes.SIGN_IN)
  }, [])
}
