import { ApolloLink } from '@apollo/client'
import { getToken } from 'services/token'
import { getUserWallet } from 'services/userWallet'

interface UserWallet {
  account: string
  chainId: number
}

export default function createAuthLink() {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const token = getToken()
      const user = getUserWallet() as UserWallet

      const account = user === null ? '' : user?.account

      return {
        headers: {
          ...headers,
          wallet: account || '',
          Authorization: token ? `Bearer ${token}` : null,
        },
      }
    })

    return forward(operation)
  })
}
