import React, { useCallback, useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { IconButton, SwipeableDrawer } from '@mui/material'
import { Box } from '@mui/system'
import { morpheusLogo } from 'assets/logos'
import { useDevice, useEventBusSubscribe, useStandalone } from 'hooks'
import EventBus from 'services/eventBus'

import InstallStep from './installStep'
import InstructionsStep from './instructionsStep'
import { Container } from './styles'

const ADD_TO_HOME_SCREEN_DISMISSED = 'addToHomeScreenDismissed'
export default function HomeScreenGuide() {
  const isStandalone = useStandalone()
  const { isAndroid, isMobileSafari } = useDevice()

  const [isOpen, setIsOpen] = useState(false)
  const [step, setStep] = useState(1)

  const addToHomeScreenDismissed =
    localStorage.getItem(ADD_TO_HOME_SCREEN_DISMISSED) === 'true'

  const handleHomeScreenGuide = useCallback(() => {
    setIsOpen(true)
  }, [])

  useEventBusSubscribe(
    EventBus.actions.modals.showHomeScreenGuide,
    handleHomeScreenGuide,
  )

  useEffect(() => {
    if (
      !addToHomeScreenDismissed &&
      !isStandalone &&
      (isMobileSafari || isAndroid)
    ) {
      setIsOpen(true)
    }
  }, [addToHomeScreenDismissed, isAndroid, isMobileSafari, isStandalone])

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setStep(1)
    localStorage.setItem(ADD_TO_HOME_SCREEN_DISMISSED, 'true')
  }, [])

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          width: '100%',
          background: 'transparent',
          borderRadius: '20px 20px 0 0',
        },
      }}
      anchor="bottom"
      open={isOpen}
      onClose={handleClose}
      onOpen={() => setIsOpen(true)}
    >
      <Container position="relative" px={4} py={5}>
        <IconButton
          sx={{ position: 'absolute', top: 24, right: 24, padding: 0 }}
          onClick={handleClose}
        >
          <CloseIcon color="secondary" />
        </IconButton>
        <Box mb={3}>
          <img alt={'morpheus logo'} src={morpheusLogo} width={80} />
        </Box>

        {step === 1 && (
          <InstallStep onClose={handleClose} onNextStep={setStep} />
        )}
        {step === 2 && <InstructionsStep onClose={handleClose} />}
      </Container>
    </SwipeableDrawer>
  )
}
