export enum ExternalLinks {
  MorpheusNetworkRoot = 'https://morpheus.network',
  TrustSupplyRoot = 'https://trust.supply',
  MorpheusNetworkTermsOfUse = 'https://morpheus.network/terms-of-use/',
  MorpheusNetworkPrivacyPolicy = 'https://morpheus.network/privacy-policy/',
  IcdrCanada = 'https://www.icdr.org/icdrcanada',
}

export enum Emails {
  InfoMorpheusNetwork = 'info@morpheus.network',
}
