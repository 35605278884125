import { useMemo } from 'react'
import {
  browserName,
  engineName,
  isAndroid,
  isChrome,
  isDesktop,
  isFirefox,
  isIOS,
  isOpera,
  isSafari,
} from 'react-device-detect'

export default function useDevice() {
  const isDesktopChrome = isDesktop && isChrome
  const isDesktopFirefox = isDesktop && isFirefox
  const isDesktopOpera = isDesktop && isOpera
  const isDesktopSafari = isDesktop && isSafari
  const isMobileSafari = isIOS && isSafari
  const isDesktopBrave = isDesktop && browserName === 'Brave'
  const isWalletInAppBrowser =
    engineName === 'WebKit' && browserName === 'WebKit'

  const isOther =
    !isAndroid &&
    !isDesktopChrome &&
    !isDesktopFirefox &&
    !isDesktopOpera &&
    !isDesktopSafari &&
    !isDesktopSafari &&
    !isDesktopBrave &&
    !isIOS

  return useMemo(
    () => ({
      isAndroid,
      isDesktopChrome,
      isDesktopFirefox,
      isDesktopOpera,
      isDesktopSafari,
      isIOS,
      isMobileSafari,
      isOther,
      isWalletInAppBrowser,
      isDesktopBrave,
    }),
    [
      isDesktopChrome,
      isDesktopBrave,
      isDesktopFirefox,
      isDesktopOpera,
      isDesktopSafari,
      isMobileSafari,
      isOther,
      isWalletInAppBrowser,
    ],
  )
}
