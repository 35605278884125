import { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { LOGIN_CONFIRM } from 'graphql/auth/mutation'
import { LoginConfirm } from 'graphql/auth/types'
import Auth from 'services/Auth'
import { getHash } from 'services/hash'

export const useAuth = (hashFromState?: string) => {
  const [idState, setIdState] = useState<string>('')
  const [isApprovedState, setIsApprovedState] = useState<boolean | null>(null)
  const [loginConfirmValidator, { data }] = useMutation<
    { loginConfirmValidator: LoginConfirm },
    { hash: string }
  >(LOGIN_CONFIRM)

  useEffect(() => {
    const hashFromLocalStorage = getHash()
    const hash = hashFromState || hashFromLocalStorage
    if (hash !== null) {
      loginConfirmValidator({ variables: { hash } })
    }
  }, [])

  useEffect(() => {
    if (data) {
      const {
        id,
        isApproved,
        access: accessToken,
        refresh: refreshToken,
      } = data.loginConfirmValidator
      Auth.handleAuth(accessToken, refreshToken)

      // State save
      setIdState(id)
      setIsApprovedState(isApproved)
    }
  }, [data])

  return { idState, isApprovedState, data }
}
