import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Button, CardContent, Pagination, Snackbar } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactComponent as NotHaveNodesDM } from 'assets/icons/dont_have_nodes_dark.svg'
import { ReactComponent as NotHaveNodesLM } from 'assets/icons/dont_have_nodes_light.svg'
import { Alert } from 'components/alert'
import ContentBox from 'components/ContentBox'
import Subheader from 'components/Subheader'
import { useAppContext } from 'context/AppContext'
import { ThemeContext } from 'context/themeMode'
import { CardSubtitle, CardTitle, NodeCard, Title } from 'globalStyles'
import { MY_NODES } from 'graphql/nodes/queries'
import { Node } from 'graphql/nodes/types'
import { getDate } from 'utils/date'
import { getNodesStatus } from 'utils/getNodesStatus'
import { truncateAddress } from 'utils/truncateAddress'

import { AddressContent, NodesWrapper, PaginationWrapper } from './styles'

// todo rewrite!!!!
const AllNodesPage = () => {
  const mode = useContext(ThemeContext)
  const { me } = useAppContext()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageCount, setPageCount] = useState<number>(1)
  const [nodes, setNodes] = useState<Array<Node>>([])
  const [isNodesLoading, setIsNodesLoading] = useState<boolean>(false)
  const [textAlert, setTextAlert] = useState<string>('')
  const [isOpenAlert, setIsOpenAlert] = useState<boolean>(false)

  const address: string = me?.wallet ? me.wallet : ''

  const isDarkMode = mode.mode === 'dark'
  const NotHaveNodes = isDarkMode ? NotHaveNodesDM : NotHaveNodesLM

  const params = {
    page: 1,
    take: 6,
    order: 'DESC',
  }

  const getNodesQuery = useQuery(MY_NODES, {
    variables: params,
    skip: !address,
  })

  const onRefreshQueries = async () => {
    await getNodesQuery.refetch({ ...params, page: currentPage })
  }

  const onCloseAlertHandler = () => setIsOpenAlert(false)

  const onCopyAddressHandler = (address: string) => {
    navigator.clipboard.writeText(address)
    setTextAlert('The address was copied.')
    setIsOpenAlert(true)
  }

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value)
    getNodesQuery.refetch({ ...params, page: value })
  }

  useEffect(() => {
    setIsNodesLoading((prevState: boolean) => !prevState)
    const { data } = getNodesQuery
    if (address.length > 1 && data) {
      setNodes(data.myNodes.data)
      setPageCount(data.myNodes?.meta.pageCount)
    }

    setIsNodesLoading((prevState: boolean) => !prevState)
  }, [getNodesQuery?.data])

  return (
    <>
      <Subheader py={4}>
        <Title style={{ textAlign: 'left' }}>
          Node List
          <Button onClick={onRefreshQueries}>
            <RefreshIcon />
          </Button>
        </Title>
      </Subheader>

      {isNodesLoading ? (
        <ContentBox sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress color="primary" />
        </ContentBox>
      ) : (
        <>
          {nodes?.length ? (
            <ContentBox sx={{ pt: 2 }}>
              <NodesWrapper>
                {nodes.map(({ id, status, createdAt, address }: Node) => (
                  <Link
                    key={id}
                    style={{
                      textDecoration: 'none',
                    }}
                    to={`/detail-node/${address}`}
                  >
                    <NodeCard sx={{ maxWidth: 230 }}>
                      <CardContent>
                        <CardSubtitle>ID</CardSubtitle>
                        <CardTitle pb={2} pt={1}>
                          {id}
                        </CardTitle>
                        <CardSubtitle>Address</CardSubtitle>
                        <CardTitle pb={2} pt={1}>
                          <AddressContent onClick={e => e.preventDefault()}>
                            {truncateAddress(address)}{' '}
                            <ContentCopyIcon
                              style={{
                                cursor: 'pointer',
                                color: mode.mode === 'dark' ? '#fff' : '#000',
                                margin: '0 10px 0 10px',
                              }}
                              onClick={() => onCopyAddressHandler(address)}
                            />
                          </AddressContent>
                        </CardTitle>
                        <CardSubtitle>Timestamp</CardSubtitle>
                        <CardTitle pb={2} pt={1}>
                          {getDate(createdAt)}
                        </CardTitle>
                        <CardSubtitle>Status</CardSubtitle>
                        <CardTitle
                          pt={1}
                          style={{ color: getNodesStatus(status) }}
                        >
                          {status.charAt(0).toUpperCase() + status.slice(1)}
                        </CardTitle>
                      </CardContent>
                    </NodeCard>
                  </Link>
                ))}
              </NodesWrapper>

              {nodes.length > 0 && pageCount > 1 && (
                <PaginationWrapper>
                  <Pagination
                    count={pageCount}
                    page={currentPage}
                    shape="rounded"
                    onChange={handleChange}
                  />
                </PaginationWrapper>
              )}
            </ContentBox>
          ) : (
            <ContentBox sx={{ alignItems: 'center', justifyContent: 'center' }}>
              <NotHaveNodes />
              <Title>You don&apos;t have any nodes yet</Title>
            </ContentBox>
          )}
        </>
      )}

      {textAlert.length ? (
        <Snackbar open={isOpenAlert}>
          <Alert
            severity="info"
            sx={{ width: '100%' }}
            onClose={onCloseAlertHandler}
          >
            {textAlert}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}
    </>
  )
}

export default AllNodesPage
