import { ApolloCache } from '@apollo/client'

import { QUEST_FIELDS } from './fragments'
import { Quest } from './types'

export function updatePublishedQuestQuery(
  cache: ApolloCache<any>,
  data: any,
  quest: Quest,
) {
  if (!data?.verifyQuest) return

  cache.updateFragment(
    {
      id: `Quest:${quest.id}`,
      fragment: QUEST_FIELDS,
      fragmentName: 'QuestFields',
    },
    (fragmentData: any) => ({
      ...fragmentData,
      questActivity: { ...data?.verifyQuest },
    }),
  )
}
