import { Box } from '@mui/material'
import { styled } from '@mui/system'

// todo update by theme with node info popup
export const PopoverContainer = styled(Box)`
  background: ${({ theme }) =>
    theme.palette.mode === 'dark' ? '#000021' : 'rgba(126, 124, 156, 0.1)'};
  color: ${({ theme }) =>
    theme.palette.mode === 'dark' ? '#697A97' : '#7A89A6'};
  border-color: ${({ theme }) =>
    `8px solid ${
      theme.palette.mode === 'dark' ? '#000021' : 'rgba(126, 124, 156, 0.1)'
    }`};
`
