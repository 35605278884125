import React, { useContext, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import { Box, Snackbar } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactComponent as SeeAllLinkIconDM } from 'assets/icons/see_more_link_icon_dark.svg'
import { ReactComponent as SeeAllLinkIconLM } from 'assets/icons/see_more_link_icon_light.svg'
import { Alert } from 'components/alert'
import { useAppContext } from 'context/AppContext'
import { ThemeContext } from 'context/themeMode'
import { MY_NODES } from 'graphql/nodes/queries'
import { Routes } from 'router/routes'

import map from 'lodash/map'

import NodeRow from './NodeRow'
import {
  HeaderButtonsWrapper,
  HeaderSeeAllLink,
  HeaderTitle,
  NodeTableHeader,
  NodeTableWrapper,
} from './styles'

export interface Params {
  page: number
  take: number
}

const params: Params = {
  page: 1,
  take: 5,
}

function NodesTable() {
  const mode = useContext(ThemeContext)

  const { me } = useAppContext()

  const [textAlert, setTextAlert] = useState<string>('')
  const [isOpenAlert, setIsOpenAlert] = useState<boolean>(false)

  const isDarkMode = mode.mode === 'dark'
  const SeeAllLinkIcon = isDarkMode ? SeeAllLinkIconDM : SeeAllLinkIconLM

  const address: string = useMemo(() => (me?.wallet ? me.wallet : ''), [me])

  const { data, loading } = useQuery(MY_NODES, {
    variables: params,
    skip: !address,
  })

  const onCloseAlertHandler = () => setIsOpenAlert(false)

  const handleCopyAddress = (address: string) => {
    navigator.clipboard.writeText(address)
    setTextAlert('The address was copied.')
    setIsOpenAlert(true)
  }

  const nodes = useMemo(() => data?.myNodes?.data || [], [data])

  if (loading) {
    return <CircularProgress size={20} sx={{ margin: '75px auto' }} />
  }

  if (!nodes?.length) return null

  return (
    <>
      <Box sx={{ overflowX: 'auto' }}>
        <HeaderButtonsWrapper mb={3}>
          <HeaderTitle>My Nodes</HeaderTitle>
          <HeaderSeeAllLink to={Routes.ALL_NODES_PAGE}>
            See all <SeeAllLinkIcon />
          </HeaderSeeAllLink>
        </HeaderButtonsWrapper>
        <NodeTableWrapper>
          <NodeTableHeader gap={1}>
            <Box>Status</Box>
            <Box>Node</Box>
            <Box sx={{ textAlign: 'center' }}>Staked nodes</Box>
            <Box sx={{ textAlign: 'center' }}>Rewards</Box>
            <Box sx={{ textAlign: 'center' }}>Action</Box>
          </NodeTableHeader>
          {map(nodes, node => (
            <NodeRow
              key={node.id}
              node={node}
              onCopyAddress={handleCopyAddress}
            />
          ))}
        </NodeTableWrapper>
      </Box>
      {!!textAlert.length && (
        <Snackbar open={isOpenAlert}>
          <Alert
            severity="info"
            sx={{ width: '100%' }}
            onClose={onCloseAlertHandler}
          >
            {textAlert}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default NodesTable
