import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const ChartWrapper = styled(Box)`
  flex-grow: 1;
  .MuiPieArc-root {
    stroke: none;
  }
`

export const ChartPieTooltip = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0;
  background-color: ${({ theme }) => theme.palette.colors.bg.dropdown};
  border-radius: 8px;
  min-width: 50px;
  p {
    margin: 0;
    padding: 4px;
    font-size: 10px;
  }
`
