import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'
import { AlertType } from 'components/alert/types'
import { Loader } from 'components/loader'
import { API } from 'config'
import { useAppContext } from 'context/AppContext'
import { Title } from 'globalStyles'
import useMounted from 'hooks/useMounted'
import { Routes } from 'router/routes'
import { getToken } from 'services/token'
import { getErrorMessage } from 'utils/error'

import { RootLink } from './styles'

function TwitterCallbackPage() {
  const location = useLocation()
  const mounted = useMounted()

  const { showAlert, refetchMe, me } = useAppContext()

  const navigate = useNavigate()
  const urlParams = new URLSearchParams(location.search)

  const code: string = urlParams.get('code') as string

  const [error, setError] = useState<any>(null)

  const check = useCallback(async () => {
    try {
      const token = getToken()
      if (code && token) {
        const response = await fetch(
          `${API.URL}/auth/twitter/callback?code=${code}`,
          { method: 'GET', headers: { Authorization: `Bearer ${token}` } },
        )

        if (response.status === 200) {
          await refetchMe?.()
          navigate(Routes.ROOT)
          showAlert?.({
            isOpen: true,
            title: 'Twitter successfully connected',
            color: AlertType.SUCCESS,
          })
        } else {
          const result = await response.json()
          throw new Error(result?.message || 'Twitter connect issues')
        }
      }
    } catch (err) {
      setError(err)
    }
  }, [code, navigate, refetchMe, showAlert])

  const linkPath = useMemo(() => {
    if (!me) {
      return Routes.SIGN_UP
    }
    return Routes.ROOT
  }, [me])

  useEffect(() => {
    if (mounted) {
      check().then()
    }
    return undefined
  }, [check, mounted])

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={4}
      justifyContent="center"
      sx={{
        margin: '0 auto',
      }}
    >
      {code ? (
        <>
          {error ? (
            <Title>{getErrorMessage(error)}</Title>
          ) : (
            <Box alignItems="center" display="flex" justifyContent="center">
              <Title>Loading</Title>
              <Loader
                style={{ marginLeft: '10px', height: '25px', width: '25px' }}
              />
            </Box>
          )}
        </>
      ) : (
        <Title>No payload provided</Title>
      )}
      <RootLink to={linkPath}>Return to home page</RootLink>
    </Box>
  )
}

export default TwitterCallbackPage
