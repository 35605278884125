import { useEffect } from 'react'

import EventBus from 'services/eventBus'

export default function useEventBusSubscribe(
  actionName: string,
  actionHandler: (...args: any[]) => any,
) {
  return useEffect(() => {
    const subscription = EventBus.on(actionName, actionHandler)

    return subscription.clear
  }, [actionName, actionHandler])
}
