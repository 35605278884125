const STORAGE_KEY = 'morpheus:user-wallet-info'

export function getUserWallet() {
  const info = JSON.parse(localStorage.getItem(STORAGE_KEY) as string)

  return info as any
}

export function setUserWallet(account?: string, chainId?: number) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify({ account, chainId }))
}

export function removeUserWallet() {
  localStorage.removeItem(STORAGE_KEY)
}
