import { Observable } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { REFRESH_TOKEN } from 'graphql/auth/mutation'
import { createBrowserHistory } from 'history'
import { getRefreshToken, setRefreshToken } from 'services/refreshToken'
import Shared from 'services/Shared'
import { getToken, setToken } from 'services/token'
import { NetworkErrors } from 'utils/networkErrors'

import forEach from 'lodash/forEach'
import get from 'lodash/get'

let isFetchingToken = false
let subscribers: any[] = []

function subscribeTokenRefresh(cb: any) {
  subscribers.push(cb)
}

function onTokenRefreshed(err: any) {
  subscribers.map(cb => cb(err))
}

export default function createErrorLink() {
  const history = createBrowserHistory()

  function logoutAction() {
    localStorage.clear()
    history.go(0)
  }

  return onError(({ graphQLErrors, networkError, operation, forward }) => {
    // eslint-disable-next-line no-console
    if (networkError) console.error(`[Network error]: ${networkError}`)

    if (graphQLErrors) {
      forEach(graphQLErrors, ({ message }) => {
        // eslint-disable-next-line no-console
        console.log(`[GraphQL error]: Message: ${message}`)
      })
    }

    const isRefreshTokenError =
      get(graphQLErrors, [0, 'message']) === NetworkErrors.JWT_ERROR &&
      operation.operationName === 'RefreshToken'

    if (isRefreshTokenError) {
      logoutAction()
      return
    }

    const isUnauthorizedError =
      get(networkError, 'statusCode') === 401 ||
      get(graphQLErrors, [0, 'message']) === NetworkErrors.INVALID_TOKEN ||
      get(graphQLErrors, [0, 'message']) === NetworkErrors.JWT_ERROR

    if (isUnauthorizedError) {
      const refreshToken = getRefreshToken()

      if (refreshToken) {
        // @ts-ignore
        // eslint-disable-next-line consistent-return
        return new Observable(async observer => {
          try {
            const retryRequest = () => {
              operation.setContext(({ headers = {} }) => {
                const accessToken = getToken()

                return {
                  headers: {
                    ...headers,
                    Authorization: accessToken ? `Bearer ${accessToken}` : null,
                  },
                }
              })

              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              }

              return forward(operation).subscribe(subscriber)
            }

            if (!isFetchingToken) {
              isFetchingToken = true

              try {
                const client = Shared.getApolloClient()

                const { data } = await client.mutate({
                  mutation: REFRESH_TOKEN,
                  variables: {
                    refreshTokenData: { token: `Bearer ${getRefreshToken()}` },
                  },
                })

                const result = data?.refreshToken

                if (result?.access) {
                  setToken(result.access)
                  setRefreshToken(result.refresh)
                } else {
                  throw new Error('Refresh token expired')
                }

                isFetchingToken = false
                onTokenRefreshed(null)
                subscribers = []

                return retryRequest()
              } catch {
                onTokenRefreshed(new Error('Unable to refresh access token'))

                subscribers = []
                isFetchingToken = false

                logoutAction()
              }
            }

            return new Promise(resolve => {
              // eslint-disable-next-line consistent-return
              subscribeTokenRefresh((errRefreshing: any) => {
                if (!errRefreshing) return resolve(retryRequest())
              })
            })
          } catch (e) {
            observer.error(e)
          }
        })
      }

      logoutAction()

      // eslint-disable-next-line consistent-return
      return forward(operation)
    }
  })
}
